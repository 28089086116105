import type { CustomObject } from "@/context/pubnub";
import { useMeetings } from "@/hooks/useMeetings";
import { formatTimetoken } from "@/lib/chatUtils";
import { getUniqueId } from "@/lib/utils";
import type { Chat, Message, MixedTextTypedElement, User } from "@pubnub/chat";
import { Avatar, Flex, Text } from "@talent-garden/react-components";
import { useQueryClient } from "@tanstack/react-query";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MeetingItem } from "../meetingItem";
import type { FileItemProps } from "./fileItem/fileItem";
import { FileItem } from "./fileItem/fileItem";
import styles from "./messageItem.module.scss";

interface MessageItemProps {
  chatPubnub: Chat;
  item: Message;
  channelStatus: CustomObject["status"];
  receiver: User | undefined;
  sender: User | undefined;
}

export const MessageItem: FC<MessageItemProps> = ({
  chatPubnub,
  item,
  channelStatus,
  sender,
  receiver,
}) => {
  const { t } = useTranslation("messages");
  const queryClient = useQueryClient();

  const isSender =
    chatPubnub?.currentUser.id === item?.userId ||
    (item?.meta?.type === "meetings" &&
      chatPubnub?.currentUser.id === item?.meta?.sender_id);

  const timeToDisplay = formatTimetoken(item.timetoken, "time");
  const [files, setFiles] = useState<FileItemProps["file"][] | []>([]);
  const { meetings } = useMeetings();

  useEffect(() => {
    (async () => {
      const itemFiles = item?.files;

      if (!itemFiles || !itemFiles?.length || !chatPubnub) {
        return;
      }

      try {
        const items = await Promise.all(
          itemFiles.map(async (file) => {
            const downloadedFile = await chatPubnub.sdk.downloadFile({
              channel: item?.channelId,
              id: file.id,
              name: file.name,
            });

            return {
              ...file,
              url: URL.createObjectURL(await downloadedFile.toFile()),
            };
          }),
        );

        setFiles(items);
      } catch (error) {
        console.error("Errore durante il download dei file:", error);
      }
    })();
  }, [chatPubnub, item]);

  const renderMessagePart = useCallback(
    (messagePart: MixedTextTypedElement) => {
      if (messagePart.type === "text") {
        return messagePart.content.text;
      }
      if (messagePart.type === "plainLink") {
        return (
          <a href={messagePart.content.link} target="_blank">
            {messagePart.content.link}
          </a>
        );
      }
      if (messagePart.type === "textLink") {
        return (
          <a href={messagePart.content.link} target="_blank">
            {messagePart.content.text}
          </a>
        );
      }
      if (messagePart.type === "mention") {
        return (
          <a href={`https://pubnub.com/${messagePart.content.id}`}>
            {messagePart.content.name}
          </a>
        );
      }

      return "";
    },
    [],
  );

  const meeting = useMemo(() => {
    if (item?.meta?.type !== "meetings") {
      return null;
    }
    return meetings?.find((m) => m?._id === item?.meta?.meeting_id) || null;
  }, [meetings, item]);

  const shouldRenderMessage = useMemo(() => {
    if (item?.meta?.type === "meetings") {
      // render message block only if meeting exists
      if (!meeting) {
        return false;
      }
    }
    return item?.meta?.type !== "requests_accepted";
  }, [item?.meta?.type, meeting]);

  useEffect(() => {
    if (isSender) {
      return;
    }
    if (item?.meta?.type !== "meetings") {
      return;
    }
    queryClient.invalidateQueries(["meetings"], { exact: true });
  }, [item]);

  return (
    <Flex
      as="li"
      columnGap={16}
      rowGap={16}
      direction="column"
      className={styles.container}
    >
      {/* REQUEST FEEDBACK / SENT/RECEIVED */}
      {item?.meta?.type === "requests" ? (
        isSender ? (
          <Flex
            direction="row"
            verticalAlign="center"
            columnGap={8}
            className={styles.genericMessage}
          >
            <Text variant="xs" className={styles.time}>
              {timeToDisplay}
            </Text>
            <Text
              textAlign="start"
              variant="s"
              key={item.timetoken + getUniqueId()}
            >
              {t("contact-request-has-been-sent")}
            </Text>
          </Flex>
        ) : (
          <Flex
            direction="row"
            verticalAlign="center"
            columnGap={8}
            className={styles.genericMessage}
          >
            <Text variant="xs" className={styles.time}>
              {timeToDisplay}
            </Text>
            <Text
              textAlign="start"
              variant="s"
              key={item.timetoken + getUniqueId()}
            >
              {t("contact-request-received")}
            </Text>
          </Flex>
        )
      ) : null}

      {/* REQUEST FEEDBACK / ACCEPTED */}
      {item?.meta?.type === "requests_accepted" ? (
        !isSender ? (
          <Flex
            direction="row"
            verticalAlign="center"
            columnGap={8}
            className={styles.genericMessage}
          >
            <Text variant="xs" className={styles.time}>
              {timeToDisplay}
            </Text>
            <Text
              textAlign="start"
              variant="s"
              key={item.timetoken + getUniqueId()}
            >
              {t("chat-request-approved")}
            </Text>
          </Flex>
        ) : (
          <Flex
            direction="row"
            verticalAlign="center"
            columnGap={8}
            className={styles.genericMessage}
          >
            <Text variant="xs" className={styles.time}>
              {timeToDisplay}
            </Text>
            <Text
              textAlign="start"
              variant="s"
              key={item.timetoken + getUniqueId()}
            >
              {t("chat-request-accepted")}
            </Text>
          </Flex>
        )
      ) : null}

      {/* MESSAGE BLOCK */}
      {shouldRenderMessage ? (
        <Flex
          columnGap={16}
          rowGap={16}
          direction="row"
          verticalAlign="center"
          horizontalAlign="end"
          className={styles.textBubbleTimeContainer}
          data-reverse={!isSender}
          data-status={channelStatus}
        >
          {/* TIME */}
          <Text variant="xs" className={styles.time}>
            {timeToDisplay}
          </Text>

          {/* TEXT BUBBLE */}
          <Flex columnGap={16} rowGap={16} className={styles.message}>
            {/* MEETING */}
            {item?.meta?.type === "meetings" && meeting ? (
              <MeetingItem meeting={meeting} />
            ) : null}

            {/* TEXT */}
            {item?.meta?.type !== "meetings" &&
            item?.meta?.type !== "requests_accepted" ? (
              <Flex
                className={styles.textBubble}
                key={item.timetoken}
                data-sender={chatPubnub?.currentUser.id === item.userId}
                direction="column"
                rowGap={12}
              >
                {files?.length > 0
                  ? files.map((file) => (
                      <FileItem key={file.id} file={file} isSender={isSender} />
                    ))
                  : null}
                {item?.text?.trim()
                  ? item
                      ?.getMessageElements()
                      .map((messagePart: MixedTextTypedElement) => (
                        <Text
                          textAlign="start"
                          variant="m"
                          key={item.timetoken + getUniqueId()}
                        >
                          {renderMessagePart(messagePart)}
                        </Text>
                      ))
                  : null}
              </Flex>
            ) : null}
          </Flex>

          {/* AVATAR */}
          {item?.meta?.type !== "requests_accepted" ? (
            <Avatar
              src={isSender ? sender?.profileUrl : receiver?.profileUrl}
              size="extra_small"
              className={styles.avatar}
            />
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  );
};
