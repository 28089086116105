import { useCallback, useContext, type FC } from "react";

import { UserContext } from "@/context/user";
import {
  Flex,
  Heading,
  Separator,
  SkeletonLoader,
  Switch,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { useFeatureFlagEnabled } from "posthog-js/react";
import styles from "../accountTab.module.scss";

const Notifications: FC = () => {
  const { t } = useTranslation("settings");
  const notificationSettingsEnabled = useFeatureFlagEnabled(
    "notification-settings",
  );
  const { editUserCommunications, user } = useContext(UserContext);

  const handleNotificationChange = useCallback(
    async (
      notificationType: "requests" | "messages" | "meetings",
      value: boolean,
    ) => {
      if (!user) return;
      console.log(
        "%cnotifications.tsx line:24 notificationsType, value",
        "color: pink",
        notificationType,
        value,
      );
      try {
        await editUserCommunications({
          email: {
            ...user.communications.email,
            [notificationType]: value,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [user],
  );

  if (!notificationSettingsEnabled) {
    return null;
  }

  return (
    <Flex rowGap={12} columnGap={12}>
      <Heading variant="s" weight="bold">
        {t("notifications")}
      </Heading>
      <Text variant="m">{t("notifications-description")}</Text>
      <Flex
        direction="column"
        rowGap={20}
        columnGap={20}
        className={styles.item}
      >
        {/* REQUESTS */}
        <Flex direction="row" verticalAlign="center" columnGap={32}>
          <Flex
            direction="column"
            rowGap={12}
            className={styles.notificationsLeftCol}
          >
            <Text variant="m" weight="medium" className={styles.title}>
              {t("contact-request")}
            </Text>
            <Text variant="m">{t("contact-request-description")}</Text>
          </Flex>
          {user ? (
            <Switch
              defaultChecked={user?.communications?.email?.requests}
              dimension="small"
              label={
                <Text variant="m" weight="medium">
                  {t("email")}
                </Text>
              }
              name="requests"
              onCheckedChange={(newVal: boolean) =>
                handleNotificationChange("requests", newVal)
              }
            />
          ) : (
            <SkeletonLoader height={24} width={90} radius={20} />
          )}
        </Flex>
        <Separator />
        {/* MESSAGES */}
        <Flex direction="row" verticalAlign="center" columnGap={32}>
          <Flex
            direction="column"
            className={styles.notificationsLeftCol}
            rowGap={12}
          >
            <Text variant="m" weight="medium" className={styles.title}>
              {t("chat-messages")}
            </Text>
            <Text variant="m">{t("chat-messages-description")}</Text>
          </Flex>
          {user ? (
            <Switch
              defaultChecked={user?.communications?.email?.messages}
              dimension="small"
              label={
                <Text variant="m" weight="medium">
                  {t("email")}
                </Text>
              }
              name="messages"
              onCheckedChange={(newVal: boolean) =>
                handleNotificationChange("messages", newVal)
              }
            />
          ) : (
            <SkeletonLoader height={24} width={90} radius={20} />
          )}
        </Flex>
        <Separator />
        {/* MEETINGS */}
        <Flex direction="row" verticalAlign="center" columnGap={32}>
          <Flex
            direction="column"
            className={styles.notificationsLeftCol}
            rowGap={12}
          >
            <Text variant="m" weight="medium" className={styles.title}>
              {t("meeting-request")}
            </Text>
            <Text variant="m">{t("meeting-request-description")}</Text>
          </Flex>
          {user ? (
            <Switch
              defaultChecked={user?.communications?.email?.meetings}
              dimension="small"
              label={
                <Text variant="m" weight="medium">
                  {t("email")}
                </Text>
              }
              name="meetings"
              onCheckedChange={(newVal: boolean) =>
                handleNotificationChange("meetings", newVal)
              }
            />
          ) : (
            <SkeletonLoader height={24} width={90} radius={20} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Notifications;
