import { Image } from "@/components/common/image";
import { SidebarLink } from "@/components/sidebar";
import { SidebarContext } from "@/context/sidebar";
import {
  Badge,
  Button,
  Flex,
  Separator,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext, type FC } from "react";
import { version } from "../../package.json";

import styles from "./sidebar.module.scss";

export const Sidebar: FC = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const currentRoute = router.pathname;
  const { sidebarOpen, setSidebarOpen } = useContext(SidebarContext);

  return (
    <Flex
      as="nav"
      direction="column"
      horizontalAlign="space-between"
      className={styles.sidebar}
      rowGap={24}
      data-open={sidebarOpen}
    >
      <Flex
        direction="row"
        horizontalAlign="space-between"
        verticalAlign="center"
        className={styles.info}
      >
        <Image
          src="/images/logo_cobranded_sidebar.svg"
          alt="Talent Garden and hyper island logo"
          width={141}
          height={25}
          className={styles.logo}
          horizontalAlign="start"
          verticalAlign="start"
        />
        <Button
          className={styles.btn}
          kind="link-neutral"
          icon={sidebarOpen ? "menu_open" : "menu"}
          dimension="large"
          onClick={(): void => setSidebarOpen(!sidebarOpen)}
        />
      </Flex>

      <Flex as="ul" direction="column" rowGap={12} className={styles.menu}>
        <SidebarLink
          href="/homepage"
          active={currentRoute === "/homepage"}
          icon="other_houses"
          iconVariant="outlined"
          text={t("dashboard") as string}
          sidebarOpen={sidebarOpen}
        />
        <Separator />
        <SidebarLink
          href="/community"
          active={currentRoute === "/community"}
          icon="language"
          iconVariant="outlined"
          text={t("curated_network") as string}
          sidebarOpen={sidebarOpen}
        />
        <SidebarLink
          href="/learn"
          active={currentRoute.includes(`/learn`)}
          icon="school"
          iconVariant="outlined"
          text={t("learn")}
          sidebarOpen={sidebarOpen}
        />
        <SidebarLink
          href="/events"
          active={currentRoute.includes("events")}
          icon="confirmation_number"
          iconVariant="outlined"
          text={t("events") as string}
          sidebarOpen={sidebarOpen}
        />
        <SidebarLink
          href="/jobs"
          active={currentRoute.includes("jobs")}
          icon="work_outline"
          iconVariant="outlined"
          text={t("jobs")}
          tooltipText={
            <Text variant="s" weight="bold">
              {t("coming-soon-exclamation")}
            </Text>
          }
          disabled
          tooltipKind="dark"
          sidebarOpen={sidebarOpen}
        />
        <SidebarLink
          href="/resources"
          active={currentRoute.includes("resources")}
          icon="design_services"
          iconVariant="outlined"
          text={t("toolbox") as string}
          sidebarOpen={sidebarOpen}
        />
        <SidebarLink
          href="/deals"
          active={currentRoute.includes("deals")}
          icon="local_offer"
          iconVariant="outlined"
          text={t("deals") as string}
          sidebarOpen={sidebarOpen}
        />
      </Flex>

      <Flex
        as="ul"
        direction="column"
        rowGap={12}
        verticalAlign="end"
        className={styles.menu}
        horizontalAlign="start"
      >
        <SidebarLink
          external
          href="mailto:support.sphere@talentgarden.com"
          icon="contact_support"
          iconVariant="outlined"
          text={t("ask-for-support") as string}
          sidebarOpen={sidebarOpen}
        />
        <SidebarLink
          href="/settings"
          active={currentRoute === "/settings"}
          icon="settings"
          iconVariant="outlined"
          text={t("settings") as string}
          sidebarOpen={sidebarOpen}
        />
        <Badge dimension="small" kind="tertiary" className={styles.badge}>
          Beta <span className={styles.version}>{version}</span>
        </Badge>
      </Flex>
    </Flex>
  );
};
