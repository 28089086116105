import {
  Button,
  Dialog,
  Flex,
  SkeletonLoader,
  Text,
  TextField,
} from "@talent-garden/react-components";
import clsx from "clsx";
import type { FC } from "react";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "next-i18next";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { DialogFooter, DialogHeader } from "@/components/common";
import { UserContext } from "@/context/user";
import { useSession } from "@/hooks/useSession";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import accountStyles from "../accountTab.module.scss";

export const ChangeEmail: FC<{ className?: string }> = ({ className }) => {
  const { user, editUser } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation(["inputs", "settings"]);
  const { isMobile } = useScreenWidth();

  const { handleSignOut } = useSession();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("valid-email")
      .required("missing-email")
      .typeError("invalid-email")
      .test("check-email", "same-email", (val: string) => val !== user?.email),
  });

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    if (modalOpen) {
      return;
    }
    reset({
      email: "",
    });
    clearErrors();
  }, [modalOpen]);

  const [email] = watch(["email"]);

  const onSubmit: SubmitHandler<any> = async (data: { email: string }) => {
    try {
      await editUser(data);
    } catch (err) {
      console.error(err);
    } finally {
      setModalOpen(false);
      handleSignOut({ callbackUrl: "/" });
    }
  };

  const onError: SubmitErrorHandler<any> = (err, e) => {
    console.error("Change Email Errors: ", err, e);
  };

  const isLoading = !user?.email;

  return (
    <Flex className={clsx(className)} direction="column">
      <Flex
        horizontalAlign="space-between"
        direction="row"
        className={accountStyles.titleBtnWrapper}
      >
        <Text variant="m" weight="medium" className={accountStyles.title}>
          {t("settings:email-address")}
        </Text>
        <Dialog
          activator={
            <Button
              kind="link-neutral"
              capitalize={false}
              iconRight
              icon="edit"
              disabled
              className={accountStyles.changeBtn}
            >
              {t("settings:change")}
            </Button>
          }
          className={accountStyles.dialog}
          onOpenChange={(open: boolean) => setModalOpen(open)}
          title={
            <DialogHeader
              title={t("settings:change-email-title")}
              icon="mail"
              subtitle={t("settings:change-email-subtitle") as string}
            />
          }
          open={modalOpen}
          withSeparator
        >
          <Flex className={accountStyles.content}>
            <TextField
              label={t("settings:new-email-address") as string}
              className={accountStyles.input}
              required
              errorMessage={
                errors && errors?.email
                  ? (t(`inputs:${errors?.email.message}`) as string)
                  : undefined
              }
              {...register("email")}
            />

            <DialogFooter
              submitText={t("settings:change-email")}
              onSubmit={() => {
                handleSubmit(onSubmit, onError)();
              }}
              onCancel={() => {
                setModalOpen(false);
              }}
              cancelText={t("settings:cancel")}
              submitDisabled={!email || email === user?.email}
            />
          </Flex>
        </Dialog>
      </Flex>

      {!isLoading ? (
        <Text variant="m" weight="medium" className={accountStyles.subtitle}>
          {user?.email}
        </Text>
      ) : (
        <SkeletonLoader
          height={30}
          width={isMobile ? "100%" : 400}
          radius={8}
        />
      )}
    </Flex>
  );
};
