import type { TextFieldProps } from "@talent-garden/react-components";
import { TextField } from "@talent-garden/react-components";

import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "next-i18next";
import styles from "./inputs.module.scss";

export interface BillingTextFieldProps extends TextFieldProps {
  name: string;
  uppercase?: boolean;
  fullWidth?: boolean;
  smallWidth?: boolean;
  customInputArea?: string;
}

export const BillingTextField: FC<BillingTextFieldProps> = ({
  label,
  placeholder,
  name,
  onBlur,
  uppercase,
  fullWidth,
  smallWidth,
  ...props
}) => {
  const { t } = useTranslation("inputs");
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <TextField
      className={styles.input}
      data-full-width={fullWidth}
      data-small-width={smallWidth}
      label={label}
      placeholder={placeholder}
      errorMessage={
        !!errors && !!(errors?.customer as any)?.[name]
          ? (t(
              `inputs:${(errors?.customer as any)?.[name]?.message}`,
            ) as string)
          : undefined
      }
      {...register(`customer.${name}`)}
      onBlur={onBlur}
      data-uppercase={uppercase}
      role="presentation"
      autocomplete="off"
      {...props}
    />
  );
};
