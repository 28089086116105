import { Button, Flex } from "@talent-garden/react-components";
import type { FC, ReactNode } from "react";

import style from "./dialogFooter.module.scss";

const DialogFooter: FC<{
  onCancel: () => void;
  onSubmit: () => void;
  submitText: string | ReactNode;
  cancelText: string;
  cancelDisabled?: boolean;
  submitDisabled?: boolean;
}> = ({
  onCancel,
  onSubmit,
  submitText,
  cancelText,
  cancelDisabled = false,
  submitDisabled = false,
}) => {
  return (
    <Flex
      direction="row"
      rowGap={12}
      columnGap={12}
      verticalAlign="center"
      horizontalAlign="stretch"
      grow
      className={style.container}
    >
      <Button
        dimension="large"
        disabled={cancelDisabled}
        onClick={onCancel}
        kind="secondary-neutral"
        capitalize={false}
      >
        {cancelText}
      </Button>
      <Button
        disabled={submitDisabled}
        dimension="large"
        kind="primary"
        onClick={onSubmit}
        capitalize={false}
      >
        {submitText}
      </Button>
    </Flex>
  );
};

export default DialogFooter;
