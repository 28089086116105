import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import Head from "next/head";
import { useRouter } from "next/router";
import type { FC } from "react";

export const CustomHead: FC = () => {
  const router = useRouter();
  return (
    <>
      <Head>
        {/* Global site tag (gtag.js) - Google Analytics  */}
        {/* <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          /> */}
        <script src="https://open.spotify.com/embed/iframe-api/v1" async />
      </Head>
      <Analytics />
      <SpeedInsights route={router.pathname as string} />
    </>
  );
};
