/*  eslint-disable jsx-a11y/media-has-caption */
import { Flex } from "@talent-garden/react-components";
import clsx from "clsx";
import Hls from "hls.js";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import style from "./videoPlayer.module.scss";

const VideoPlayer: FC<{
  url: string;
  cover?: string;
  className?: string;
  progressEventCallback?: (progress: number) => void;
}> = ({ url, cover, className, progressEventCallback, ...props }) => {
  const player = useRef<any>();

  const [progressPercentageCheckpoint, setProgressPercentageCheckpoint] =
    useState<number>(0);

  useEffect(() => {
    try {
      const video = player.current;
      const extension = url.split(".").pop();
      if (extension === "mp4") {
        video.src = url;
        video.addEventListener("loadedmetadata", () => {
          video.play();
        });
      } else if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = url;
        video.addEventListener("loadedmetadata", () => {
          video.play();
        });
      }
    } catch (err) {
      console.log("Error:", err);
    }
  }, [url]);

  // send event to posthog when user reaches 25%, 50%, 75% and 100% of the video
  const handleProgress = (e: any) => {
    if (Number.isNaN(e.target.duration)) {
      // duration is NotaNumber at Beginning.
      return;
    }

    const progressPercentage = Math.ceil(
      (e.target.currentTime * 100) / e.target.duration,
    );
    const captureProgressPercentages = [25, 50, 75, 100];

    if (
      captureProgressPercentages.includes(progressPercentage) &&
      progressPercentage !== progressPercentageCheckpoint
    ) {
      progressEventCallback?.(progressPercentage);
      setProgressPercentageCheckpoint(progressPercentage);
    }
  };

  useEffect(() => {
    if (!url) {
      return;
    }
    try {
      player?.current?.load();
      player?.current?.play();
    } catch (err) {
      console.log("Error:", err);
    }
  }, [url]);

  return (
    <Flex className={clsx(style.container, className)} {...props}>
      <video
        controls
        className={style.video}
        controlsList="nodownload"
        onProgress={handleProgress}
        ref={player}
        preload="metadata"
        autoPlay
        // muted
      >
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export default VideoPlayer;
