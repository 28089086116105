import { TNormalizedInvoice, normalizeInvoice } from '@/lib/normalizer';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { TInvoice } from '../types';
import { useActualWorkspace } from './useActualWorkspace';

export const useInvoices = () => {
    const { workspaceId } = useActualWorkspace();

    const { data: invoices, isLoading: isLoadingInvoices, error: invoicesError } = useQuery(['invoices', workspaceId], async () => {
        return axios.get('/api/payments/invoices-list/',
            {
                params: { workspaceId }
            })
    }, { enabled: Boolean(workspaceId) })

    const isLoading = !invoices?.data || isLoadingInvoices

    return {
        isLoading,
        error: invoicesError,
        invoices: invoices?.data?.data?.map((invoice: TInvoice) => normalizeInvoice(invoice)) as TNormalizedInvoice[],
    }
}
