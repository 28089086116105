import {
  Alert,
  Button,
  Dialog,
  Flex,
  PhoneField,
  SkeletonLoader,
  Text,
} from "@talent-garden/react-components";
import clsx from "clsx";
import type { FC } from "react";
import { useCallback, useContext, useEffect, useState } from "react";

import { UserContext } from "@/context/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "next-i18next";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { DialogFooter, DialogHeader } from "@/components/common";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import accountStyles from "../accountTab.module.scss";
import styles from "./changePhone.module.scss";

export const ChangePhone: FC<{ className?: string }> = ({ className }) => {
  const { user, editUser } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation(["inputs", "settings"]);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const { isMobile } = useScreenWidth();

  const onOpenChange = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );
  const onErrorOpenChange = useCallback(
    (open: boolean) => setErrorAlertOpen(open),
    [],
  );

  const schema = yup.object().shape({
    phone_number: yup
      .string()
      .required("required-phone")
      .test(
        "min-len",
        "invalid-phone",
        (val: string) => (val || "")?.toString()?.length > 5,
      )
      .test(
        "max-len",
        "invalid-phone",
        (val: string) => (val || "")?.toString()?.length <= 15,
      )
      .test("onlyNumber", "invalid-phone", (value: string) =>
        /^\d*$/.test(value),
      )
      .test(
        "check-phone",
        "same-phone",
        (val: string) => val?.toString() !== user?.phone?.number?.toString(),
      ),
    phone_prefix: yup.string().required("required-field"),
  });

  const initialState = {
    phone_number: user?.phone?.number ?? "",
    phone_prefix: user?.phone?.prefix || "+39",
  };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const {
    watch,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    register,
  } = formMethods;

  useEffect(() => {
    if (modalOpen) {
      return;
    }
    clearErrors();
  }, [clearErrors, modalOpen]);

  const [phoneNumber] = watch(["phone_number"]);

  const onSubmit: SubmitHandler<any> = async (data: {
    phone_number: string;
    phone_prefix: string;
  }) => {
    try {
      await editUser({
        phone: {
          number: data?.phone_number,
          prefix: data?.phone_prefix,
        },
      });
      setSuccessAlertOpen(true);
    } catch (err) {
      console.error(err);
      setErrorAlertOpen(true);
    } finally {
      setModalOpen(false);
    }
  };

  const onError: SubmitErrorHandler<any> = (err, e) => {
    console.error("Errors: ", err, e);
  };

  const isLoading = !user;

  return (
    <div>
      <Flex className={clsx(styles.changePhone, className)} direction="column">
        <Flex
          horizontalAlign="space-between"
          direction="row"
          className={accountStyles.titleBtnWrapper}
        >
          <Text variant="m" weight="medium" className={accountStyles.title}>
            {t("settings:telephone-number")}
          </Text>
          <Dialog
            activator={
              <Button
                kind="link-neutral"
                capitalize={false}
                iconRight
                icon="edit"
                className={accountStyles.changeBtn}
              >
                {t("settings:change")}
              </Button>
            }
            onOpenChange={(open: boolean) => setModalOpen(open)}
            className={accountStyles.dialog}
            title={
              <DialogHeader
                title={t("settings:change-phone-title")}
                icon="phone"
                subtitle={t("settings:change-phone-subtitle") as string}
              />
            }
            open={modalOpen}
            withSeparator
          >
            <Flex className={accountStyles.content}>
              <PhoneField
                defaultPrefixValue={initialState?.phone_prefix}
                defaultValue={initialState?.phone_number}
                label={t("inputs:phone-number")}
                placeholder={t("inputs:phone-number-placeholder") as string}
                {...register("phone_number")}
                onPrefixChange={(prefix: string) => {
                  setValue("phone_prefix", prefix);
                }}
                errorMessage={
                  errors?.phone_number
                    ? (t(`inputs:${errors?.phone_number?.message}`) as string)
                    : undefined
                }
                className={`${accountStyles.input} ${accountStyles.marginBottom}`}
              />
              <DialogFooter
                submitText={t("settings:change-phone")}
                onSubmit={() => {
                  handleSubmit(onSubmit, onError)();
                }}
                onCancel={() => {
                  setModalOpen(false);
                }}
                cancelText={t("settings:cancel")}
                submitDisabled={
                  !phoneNumber || phoneNumber === user?.phone?.number
                }
              />
            </Flex>
          </Dialog>
        </Flex>
        {!isLoading ? (
          <Text variant="m" weight="medium" className={accountStyles.subtitle}>
            {user?.phone
              ? `${user?.phone?.prefix} ${user?.phone?.number}`
              : "Phone number not set"}
          </Text>
        ) : (
          <SkeletonLoader
            height={30}
            width={isMobile ? "100%" : 400}
            radius={8}
          />
        )}
      </Flex>
      <Alert
        title={t("settings:alert-phone-success-title")}
        description={t("settings:alert-phone-success-subtitle")}
        isOpen={successAlertOpen}
        handleOpenChange={onOpenChange}
        icon="check_circle"
        withDismiss
        type="success"
        duration={4000}
        iconVariant="outlined"
      />
      {/* Error */}
      <Alert
        title={t("settings:alert-phone-error-title")}
        description={t("settings:alert-phone-error-subtitle")}
        isOpen={errorAlertOpen}
        handleOpenChange={onErrorOpenChange}
        icon="error"
        withDismiss
        type="error"
        duration={4000}
        iconVariant="outlined"
      />
    </div>
  );
};
