import { Flex, GoogleAutocomplete } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { useRef, type FC } from "react";
// import { CopyInformationFromContact } from './copyInformationFromContact'
import useOnScreen from "@/hooks/useOnScreen";
import { useFormContext } from "react-hook-form";
import { BillingTextField } from "../inputs/textField";

import styles from "./forms.module.scss";

export const IndividualForm: FC = () => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    setError,
  } = useFormContext();

  const { t } = useTranslation("inputs");
  const [country] = watch(["customer.country"]);
  const [formattedAddress] = watch(["customer.formattedAddress"]);
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  return (
    <Flex
      className={styles.inputsContainer}
      data-open={isVisible}
      ref={ref}
      rowGap={8}
      columnGap={8}
    >
      <Flex direction="column" className={styles.formChild}>
        <BillingTextField
          label={t("edit-first_name") as string}
          placeholder={t("insert-first_name") as string}
          name="firstName"
        />
        <BillingTextField
          label={t("edit-last_name") as string}
          placeholder={t("insert-last_name") as string}
          name="lastName"
        />
      </Flex>
      <Flex direction="column" className={styles.formChild}>
        {/* Country or region */}
        <GoogleAutocomplete
          defaultValue={formattedAddress}
          className={styles.fullwidth}
          getLocation={(location) => {
            if ((errors?.customer as any)?.formattedAddress) {
              clearErrors("customer.formattedAddress");
              clearErrors("customer.country");
              clearErrors("customer.address");
            }

            const streetNumber = location?.address_components?.find(
              (addr: any) => addr.types.includes("street_number"), // TODO: @matte tipizzare
            )?.long_name;

            if (!streetNumber) {
              setError("customer.formattedAddress", {
                type: "manual",
                message: "missing-street-number",
              });
            }
            setValue("customer.formattedAddress", location?.formatted_address);
            setValue("customer.address", location?.address_components);

            // eslint-disable-next-line no-shadow
            const country = location?.address_components?.find(
              (
                addr: any, // TODO: @matte tipizzare
              ) => addr.types.includes("country"),
            )?.short_name;

            if (country) {
              setValue("customer.country", country);
            }
          }}
          label={t("edit-address") as string}
          placeholder={t("insert-address") as string}
          googlePlacesOptions={{
            types: ["address"],
          }}
          // defaultValue={address}
          errorMessage={
            errors && (errors?.customer as any)?.formattedAddress // TODO: @matte tipizzare
              ? (t(
                  `${(errors?.customer as any)?.formattedAddress?.message}`, // TODO: @matte tipizzare
                ) as string)
              : undefined
          }
        />
      </Flex>
      {country === "IT" ? (
        <Flex direction="column" className={styles.formChild}>
          <BillingTextField
            label={t("edit-fiscal_code") as string}
            placeholder={t("insert-fiscal_code") as string}
            name="fiscalCode"
            uppercase
            fullWidth
          />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default IndividualForm;
