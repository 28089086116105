import {
  Alert,
  Button,
  Dialog,
  Flex,
  PasswordField,
  Text,
} from "@talent-garden/react-components";
import clsx from "clsx";
import type { FC } from "react";
import { useCallback, useContext, useEffect, useState } from "react";

import { UserContext } from "@/context/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "next-i18next";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { DialogFooter, DialogHeader, OTPInput } from "@/components/common";
import accountStyles from "../accountTab.module.scss";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("password-required")
    .min(8, "password-char-long")
    .matches(/[0-9]/, "password-must-contain-number")
    .matches(/[A-Z]/, "password-must-contain-uppercase")
    .matches(/[a-z]/, "password-must-contain-lowercase")
    .matches(/[$-/:-?{-~!"^_@#`[\]]/, "password-must-contain-special"),
  confirmPwd: yup
    .string()
    .required("password-required")
    .oneOf([yup.ref("password")], "password-not-match"),
  otpCode: yup.string(),
});
export type ChangePasswordSchema = yup.InferType<typeof schema>;

export const ChangePassword: FC<{ className?: string }> = ({ className }) => {
  const { editUserPassword, sendOtpForPassword } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [step, setStep] = useState<1 | 2>(1);
  const { t } = useTranslation(["inputs", "settings"]);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onOpenChange = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );
  const onErrorOpenChange = useCallback(
    (open: boolean) => setErrorAlertOpen(open),
    [],
  );

  const formMethods = useForm<ChangePasswordSchema>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
  } = formMethods;

  useEffect(() => {
    if (modalOpen) {
      return;
    }
    reset({
      password: "",
      confirmPwd: "",
      otpCode: "",
    });
    clearErrors();
    setStep(1);
  }, [modalOpen]);

  // /users/_id/change_password POST with no body
  // /users/_id/confirm_password PATCH with {code, proposed_password}
  const onSubmitOtp = async () => {
    try {
      await sendOtpForPassword();
      setStep(2);
    } catch (err) {
      setErrorMessage(err as string);
      setErrorAlertOpen(true);
      console.error(err);
    }
  };

  const onSubmit: SubmitHandler<ChangePasswordSchema> = async (data: any) => {
    try {
      await editUserPassword({
        proposed_password: data.password,
        code: data?.otpCode,
      });
      setSuccessAlertOpen(true);
    } catch (err) {
      console.error(err);
      setErrorAlertOpen(true);
    } finally {
      setModalOpen(false);
      reset({
        password: "",
        confirmPwd: "",
        otpCode: "",
      });
      clearErrors();
      setStep(1);
    }
  };

  const onError: SubmitErrorHandler<ChangePasswordSchema> = (err, e) => {
    console.error("Errors: ", err, e);
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        <Flex
          horizontalAlign="space-between"
          direction="row"
          verticalAlign="start"
          className={clsx(className)}
          rowGap={24}
          columnGap={24}
        >
          <Flex rowGap={12} columnGap={12}>
            <Text variant="m" weight="medium" className={accountStyles.title}>
              {t("settings:password")}
            </Text>
            <Text
              variant="m"
              weight="medium"
              className={accountStyles.subtitle}
            >
              ••••••••
            </Text>
          </Flex>
          {/* DIALOG TO CHANGE PASSWORD */}
          <Dialog
            activator={
              <Button
                kind="link-neutral"
                capitalize={false}
                iconRight
                icon="edit"
                className={accountStyles.changeBtn}
              >
                {t("settings:change")}
              </Button>
            }
            className={accountStyles.dialog}
            onOpenChange={(open: boolean) => setModalOpen(open)}
            title={
              <DialogHeader
                title={t("settings:update-password")}
                icon="password"
                subtitle={t("settings:update-password-subtitle") as string}
              />
            }
            open={modalOpen}
            withSeparator
          >
            <Flex className={accountStyles.content} rowGap={8} columnGap={8}>
              {step === 1 ? (
                <>
                  <PasswordField
                    label="New Password*"
                    className={accountStyles.input}
                    required
                    errorMessage={
                      errors && errors?.password
                        ? (t(`inputs:${errors?.password.message}`) as string)
                        : undefined
                    }
                    {...register("password")}
                  />
                  <PasswordField
                    label="Repeat Password*"
                    required
                    className={accountStyles.input}
                    data-margin-top="false"
                    data-margin-bottom="true"
                    errorMessage={
                      errors && errors?.confirmPwd
                        ? (t(`inputs:${errors?.confirmPwd.message}`) as string)
                        : undefined
                    }
                    {...register("confirmPwd")}
                  />
                </>
              ) : (
                <Flex direction="column" rowGap={16} columnGap={16}>
                  <Text variant="s">
                    We have sent you an OTP code to the email address linked to
                    your account. Please use this code to complete the password
                    change process.
                  </Text>
                  <OTPInput
                    autoFocus
                    length={6}
                    inputClassName="otpInput"
                    onChangeOTP={(otp: string) => setValue("otpCode", otp)}
                  />
                  <Flex
                    direction="row"
                    rowGap={2}
                    columnGap={2}
                    verticalAlign="center"
                  >
                    <Text variant="s">Didn&apos;t get a code?</Text>
                    <Button
                      dimension="small"
                      kind="link-neutral"
                      capitalize={false}
                      onClick={() => onSubmitOtp()}
                    >
                      Click to resend.
                    </Button>
                  </Flex>
                </Flex>
              )}
              <DialogFooter
                submitText={t("settings:save-changes")}
                onSubmit={() => {
                  if (step !== 1) {
                    handleSubmit(onSubmit, onError)();
                  } else {
                    onSubmitOtp();
                  }
                }}
                onCancel={() => {
                  setModalOpen(false);
                }}
                cancelText={t("settings:cancel")}
                // submitDisabled={!email || email === user?.email}
              />
            </Flex>
          </Dialog>
        </Flex>
        {/* Success */}
        <Alert
          title={t("settings:alert-password-success-title")}
          description={t("settings:alert-password-success-subtitle")}
          isOpen={successAlertOpen}
          handleOpenChange={onOpenChange}
          icon="check_circle"
          withDismiss
          type="success"
          duration={4000}
          iconVariant="outlined"
        />
        {/* Error */}
        <Alert
          title={t("settings:alert-password-error-title")}
          description={
            errorMessage || t("settings:alert-password-error-subtitle")
          }
          isOpen={errorAlertOpen}
          handleOpenChange={onErrorOpenChange}
          icon="error"
          withDismiss
          type="error"
          duration={4000}
          iconVariant="outlined"
        />
      </FormProvider>
    </div>
  );
};
