import axios from 'axios'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useWorkspace } from './useWorkspace'

export const useSubscription = () => {
    const queryClient = useQueryClient();
    const { query } = useRouter();
    const { workspace } = useWorkspace();

    const { data: subscription, isLoading: isLoadingSubscription, error: subscriptionError } = useQuery(['subscription', workspace?._id], async () => {
        return axios.get('/api/subscription/',
            {
                params: { workspaceId: workspace?._id }
            })
    }, { enabled: Boolean(workspace?._id) })

    const isLoading = !subscription?.data || isLoadingSubscription

    const editSubscription = useCallback(async (data: any) => {
        if (!workspace?._id && !workspace?._id) {
            return;
        }
        try {
            await axios.put(`/api/subscription?workspaceId=${query?.id}`, data)
            queryClient.invalidateQueries(['subscription', workspace?._id], { exact: true });
        } catch (error) {
            throw new Error('Error editing subscription');
        }
    }, [workspace?._id, workspace?._id])

    return {
        isLoading,
        error: subscriptionError,
        subscription: subscription?.data,
        editSubscription
    }
}
