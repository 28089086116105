import { Button, Flex } from "@talent-garden/react-components";

import type { FC } from "react";
import type { Table } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import style from "./pagination.module.scss";

const Pages: FC<{ table: Table<any> }> = ({ table }) => {
  const totalPages = table.getPageCount();
  const pageSelected = table.getState().pagination.pageIndex;

  const pageLinksToShow = 2;

  const createLink = (i: number) => {
    return (
      <Button
        kind="secondary-neutral"
        dimension="small"
        onClick={() => table.setPageIndex(i)}
        data-selected={i === pageSelected}
      >
        {i + 1}
      </Button>
    );
  };

  const getPageLinks = () => {
    let pageLinks = [];

    if (totalPages <= 2 * pageLinksToShow + 1) {
      pageLinks = Array.from({ length: totalPages }, (_, i) => createLink(i));
    } else {
      // Otherwise, show a subset of page links with ellipsis
      const startPage = Math.max(0, pageSelected - pageLinksToShow);
      const endPage = Math.min(totalPages - 1, pageSelected + pageLinksToShow);

      pageLinks = Array.from({ length: endPage - startPage + 1 }, (_, i) =>
        createLink(startPage + i),
      );

      if (startPage > 0) {
        // Add ellipsis at the beginning if not on the first page
        pageLinks.unshift(createLink(0));
        if (startPage > 1) {
          pageLinks.unshift(
            <div key="ellipsis-start" className="page">
              ...
            </div>,
          );
        }
      }

      if (endPage < totalPages - 1) {
        // Add ellipsis at the end if not on the last page
        if (endPage < totalPages - 2) {
          pageLinks.push(
            <div key="ellipsis-end" className="page">
              ...
            </div>,
          );
        }
        pageLinks.push(createLink(totalPages - 1));
      }
    }

    return pageLinks;
  };

  return (
    <Flex direction="row" className={style.pages}>
      {getPageLinks()}
    </Flex>
  );
};

const Pagination: FC<{ table: Table<any> }> = ({ table }) => {
  const { t } = useTranslation("common");
  return (
    <Flex
      direction="row"
      className={style.pagination}
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <Button
        capitalize={false}
        dimension="small"
        icon="arrow_back"
        kind="link-neutral"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {t("previous")}
      </Button>

      <Pages table={table} />

      <Button
        capitalize={false}
        dimension="small"
        icon="arrow_forward"
        iconRight
        kind="link-neutral"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {t("next")}
      </Button>
    </Flex>
  );
};

export default Pagination;
