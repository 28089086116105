import { BillingFormDialog } from "@/components/buy/billingFormDialog";
import { EmptyState } from "@/components/common/emptyState";
import { useBillingProfile } from "@/hooks/useBillingProfile";
import {
  Button,
  Flex,
  Heading,
  SkeletonLoader,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { type FC } from "react";
import styles from "./billingProfile.module.css";

export const BillingProfile: FC<{ goToLearn: (() => void) | undefined }> = ({
  goToLearn,
}) => {
  const { t } = useTranslation(["settings", "inputs", "payments"]);
  const { billingProfile, isLoading } = useBillingProfile();

  // TODO mettere check corretto invece di Object.keys(billingProfile)?.length
  return (
    <Flex>
      <Heading variant="s" weight="bold">
        {t("payments:your-billing-profile")}
      </Heading>
      {isLoading ? (
        <SkeletonLoader
          height={320}
          width="100%"
          radius={12}
          className={styles.container}
        />
      ) : null}
      {!isLoading ? (
        billingProfile?.is_completed ? (
          <Flex className={styles.container}>
            <Text variant="m">{`${billingProfile?.first_name} ${billingProfile?.last_name}`}</Text>
            <Text variant="m">{`${billingProfile?.address}`}</Text>
            <Text variant="m">{`${billingProfile?.postal_code}, ${billingProfile?.city}, ${billingProfile?.country}`}</Text>
            {!billingProfile?.is_individual ? (
              <>
                <Text variant="m" className={styles.marginTop}>
                  {billingProfile?.company_name}
                </Text>
                <Text variant="m">{billingProfile?.vat}</Text>
                <Text variant="m">{billingProfile?.tax_code}</Text>
                <Text variant="m">{billingProfile?.sdi_code}</Text>
              </>
            ) : null}
            {billingProfile?.fiscal_code ? (
              <Text variant="m">{`${t("buy:summary-fiscal-code", {
                cf: billingProfile?.fiscal_code,
              })}`}</Text>
            ) : null}
            <Text variant="m" className={styles.marginTop}>
              {billingProfile?.billing_email}
            </Text>
            <Text variant="m">{`${billingProfile?.phone_prefix} ${billingProfile?.phone_number} `}</Text>
            <BillingFormDialog
              activator={
                <Button
                  className={styles.editButton}
                  icon="edit"
                  iconRight
                  fontWeight="semibold"
                  capitalize={false}
                  kind="link-neutral"
                >
                  {t("change")}
                </Button>
              }
            />
          </Flex>
        ) : (
          <EmptyState
            action={goToLearn}
            title={t("payments:billing-profile-empty-state-title")}
            description={t("payments:billing-profile-empty-state-subtitle")}
            icon="add_business"
            className={styles.emptyState}
            buttonComponent={
              <BillingFormDialog
                activator={
                  <Button
                    className={styles.editButton}
                    icon="edit"
                    iconRight
                    fontWeight="semibold"
                    capitalize={false}
                    kind="link-neutral"
                  >
                    Add details
                  </Button>
                }
              />
            }
          />
        )
      ) : null}
    </Flex>
  );
};
