import { UserContext } from "@/context/user";
import { customerIoIdentify, customerIoTrack } from "@/lib/customerIoUtils";
import type React from "react";
import { useContext, useEffect } from "react";

const CustomerIOTracker: React.FC = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    // This check is of critical importance because the customer.io SDK will make your
    // app crash if you try to initialize it twice.
    if ((window as any)?._cio && Array.isArray((window as any)?._cio)) {
      return;
    }

    const _cio = [] as any[];
    (window as any)._cio = _cio;

    const a: (f: string) => (...args: any[]) => void = function (f) {
      return function (...args: any[]) {
        _cio?.push([f]?.concat(args));
      };
    };

    const b: string[] = [
      "load",
      "identify",
      "sidentify",
      "track",
      "page",
      "on",
      "off",
    ];
    // eslint-disable-next-line no-plusplus
    for (let c = 0; c < b?.length; c++) {
      _cio[b[c] as any] = a(b[c]);
    }

    const t = document.createElement("script");
    const s = document.getElementsByTagName("script")?.[0];
    t.async = true;
    t.id = "cio-tracker";
    t.setAttribute(
      "data-site-id",
      process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID!,
    );
    t.setAttribute("data-use-array-params", "true");
    t.setAttribute("data-use-in-app", "true");
    t.src = "https://assets.customer.io/assets/track-eu.js";
    s?.parentNode?.insertBefore(t, s);
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    customerIoIdentify({ id: user?._id });
  }, [user]);

  // useEffect(())

  return null;
};

export default CustomerIOTracker;
