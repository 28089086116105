import { toggle } from "@/lib/utils";
import {
  Button,
  ClampText,
  Flex,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import type { MaterialIconNames } from "@talent-garden/react-components/icons/mui-types";
import type { ChangeEvent, FC, SyntheticEvent } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./uploadFileModalContent.module.scss";

interface UploadFileModalContentProps {
  onSendFiles: (event: SyntheticEvent, files?: File[]) => void;
  onClose: () => void;
}

export const FILE_TYPE_ICONS_MAP: { [key: string]: MaterialIconNames } = {
  // AUDIO
  "audio/x-wav": "audiotrack",
  "audio/x-aiff": "audiotrack",
  "audio/mpeg": "audiotrack",
  "audio/mp4": "audiotrack",
  "audio/mp3": "audiotrack",
  "application/ogg": "audiotrack",
  // VIDEO
  "video/mpeg": "videocam",
  "video/mp4": "videocam",
  "video/quicktime": "videocam",
  "video/x-msvideo": "videocam",
  "application/smil": "videocam",
  // IMAGE
  "image/jpeg": "image",
  "image/jpg": "image",
  "image/png": "image",
  "image/tiff": "image",
  "image/gif": "image",
  "image/svg+xml": "image",
  // DOCUMENT
  "application/pdf": "insert_drive_file",
  "text/html": "insert_drive_file",
  "text/plain": "insert_drive_file",
  "text/rtf": "insert_drive_file",
  "text/xml": "insert_drive_file",
  "application/msword": "insert_drive_file",
};

const MAX_PUBNUB_FILE_SIZE = 5000000; // 5MB

export const UploadFileModalContent: FC<UploadFileModalContentProps> = ({
  onSendFiles,
  onClose,
}) => {
  const { t } = useTranslation(["messages", "common"]);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [showSizeError, setShowSizeError] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    if (event.target.files[0].size > MAX_PUBNUB_FILE_SIZE) {
      setShowSizeError(true);
      return;
    }
    setShowSizeError(false);

    const newUploadedFiles = [...uploadedFiles, ...event.target.files];
    setUploadedFiles(newUploadedFiles);
  };

  const handleSendFiles = (event: SyntheticEvent) => {
    event.preventDefault();
    onSendFiles(event, uploadedFiles);
    onClose();
  };

  return (
    <Flex direction="column" rowGap={24}>
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleFileInputChange}
        hidden
      />
      <Flex direction="column" rowGap={8}>
        <Text variant="s">Choose a file</Text>
        <Flex
          horizontalPadding={8}
          verticalPadding={8}
          onClick={() => fileInputRef?.current?.click()}
          direction="row"
          verticalAlign="center"
          className={styles.uploadInput}
          horizontalAlign="space-between"
          data-error={showSizeError}
        >
          <Flex direction="row" verticalAlign="center" columnGap={8}>
            <MaterialIcon name="file_upload" mode="gray" variant="outlined" />
            <Text variant="m">{t("messages:chat-upload-choose-a-file")}</Text>
          </Flex>
          {showSizeError ? (
            <MaterialIcon className={styles.errIcon} name="error_outline" />
          ) : null}
        </Flex>
        {showSizeError ? (
          <Text variant="s" className={styles.errorText}>
            {t("messages:chat-upload-file-size-err")}
          </Text>
        ) : null}
      </Flex>

      <Flex direction="column" rowGap={16}>
        <Text variant="m" weight="bold">
          {t("messages:chat-upload-files-uploaded")}
        </Text>

        {uploadedFiles?.length > 0 ? (
          uploadedFiles.map((file) => (
            <Flex
              key={file.name}
              direction="row"
              horizontalAlign="space-between"
              verticalAlign="center"
              horizontalPadding={8}
              verticalPadding={8}
              grow
              className={styles.uploadedFile}
            >
              <Flex direction="row" verticalAlign="center" columnGap={8}>
                <MaterialIcon
                  name={FILE_TYPE_ICONS_MAP[file.type] ?? "attach_file"}
                  mode="gray"
                  variant="outlined"
                  size={24}
                />
                <ClampText rows={1}>
                  <Text variant="m">{file.name}</Text>
                </ClampText>
              </Flex>
              <MaterialIcon
                name="close"
                mode="primary"
                size={24}
                onClick={() =>
                  setUploadedFiles(
                    toggle(
                      uploadedFiles,
                      file,
                      (uploadedFile: File) => uploadedFile.name,
                    ),
                  )
                }
              />
            </Flex>
          ))
        ) : (
          <Text variant="m">{t("messages:chat-upload-no-files")}</Text>
        )}
      </Flex>
      <Flex
        direction="row"
        columnGap={16}
        verticalAlign="center"
        horizontalAlign="stretch"
        grow
      >
        <Button
          dimension="large"
          kind="secondary-neutral"
          onClick={onClose}
          capitalize={false}
        >
          {t("common:cancel")}
        </Button>
        <Button
          dimension="large"
          kind="primary"
          onClick={handleSendFiles}
          capitalize={false}
          disabled={uploadedFiles?.length === 0}
        >
          {t("messages:chat-upload-send-files")}
        </Button>
      </Flex>
    </Flex>
  );
};
