import type { TMeeting } from "@/types/types";
import {
  Badge,
  Button,
  ClampText,
  Flex,
  Heading,
  MaterialIcon,
  Separator,
  SkeletonLoader,
  Text,
} from "@talent-garden/react-components";
import { format } from "date-fns";

import { useMeetings } from "@/hooks/useMeetings";
import { createGoogleCalendarLink } from "@/lib/utils";
import { useMemo } from "react";
import { useAnalytics } from "@/hooks/useAnalytics";
import { useTranslation } from "react-i18next";
import style from "./meetingItem.module.scss";

const MeetingItem = ({ meeting }: { meeting: TMeeting | null }) => {
  const { t } = useTranslation("messages");
  const { editMeeting, deleteMeeting } = useMeetings();
  const { captureInteraction } = useAnalytics();

  const renderButtons = useMemo(() => {
    if (!meeting) {
      return null;
    }

    const { status, deleted } = meeting;

    if (deleted) {
      return (
        <Badge icon="delete" dimension="small" kind="danger" outline>
          {t("meeting-deleted")}
        </Badge>
      );
    }

    switch (status) {
      case "accepted":
        return (
          <Flex
            rowGap={4}
            columnGap={4}
            direction="row"
            verticalAlign="center"
            horizontalAlign="center"
          >
            <Button
              dimension="small"
              icon="date_range"
              kind="secondary-neutral"
              as="a"
              href={createGoogleCalendarLink({
                eventName: meeting.name,
                start: new Date(meeting.from)?.toISOString(),
                end: new Date(meeting.to)?.toISOString(),
                location: "Online",
                details: `<a href="${meeting.url}">Meeting url</a>`,
              })}
              target="_blank"
            />
            <Button
              kind="secondary"
              as="a"
              fontWeight="semibold"
              href={meeting?.url as string}
              target="_blank"
              dimension="small"
              onClick={() => {
                captureInteraction({
                  event_category: "community",
                  event_label: "start_meeting",
                  section: "messages",
                  resource: meeting.sender_user_id,
                  meeting_id: meeting._id,
                });
              }}
            >
              {t("join-meeting")}
            </Button>
          </Flex>
        );
      case "pending":
        if (meeting?.is_sender) {
          return (
            <Badge
              className={style.pendingBtn}
              icon="access_time_filled_sharp"
              dimension="small"
              kind="tertiary"
              outline
            >
              {t("meeting-pending")}
            </Badge>
          );
        }
        return (
          <Flex rowGap={4} columnGap={4}>
            <Button
              kind="primary"
              capitalize={false}
              dimension="small"
              fontWeight="semibold"
              onClick={() => {
                editMeeting(meeting?._id, { status: "accepted" });
                captureInteraction({
                  event_category: "community",
                  event_label: "accept_meeting_request",
                  section: "messages",
                  resource: meeting.sender_user_id,
                  meeting_id: meeting._id,
                });
              }}
            >
              {t("accept-meeting")}
            </Button>
            <Button
              className={style.refuseBtn}
              dimension="small"
              kind="link"
              capitalize={false}
              fontWeight="semibold"
              onClick={() => {
                editMeeting(meeting?._id, { status: "refused" });
                captureInteraction({
                  event_category: "community",
                  event_label: "decline_meeting_request",
                  section: "messages",
                  resource: meeting.sender_user_id,
                  meeting_id: meeting._id,
                });
              }}
            >
              {t("refuse-meeting")}
            </Button>
          </Flex>
        );
      case "refused":
        return (
          <Badge
            dimension="small"
            kind="neutral"
            outline
            className={style.refusedBtn}
            icon="do_not_disturb"
          >
            {t("meeting-refused")}
          </Badge>
        );
      default:
        return <div />;
    }
  }, [meeting]);

  if (!meeting) {
    return <SkeletonLoader height={150} width={500} radius={20} />;
  }

  return (
    <Flex
      className={style.card}
      verticalAlign="center"
      horizontalAlign="center"
      rowGap={16}
      columnGap={64}
      data-is-sender={meeting.is_sender}
      data-is-refused={meeting.status === "refused" && !meeting.is_sender}
      data-deleted={meeting.deleted}
    >
      <Flex
        className={style.content}
        verticalAlign="start"
        horizontalAlign="center"
        rowGap={16}
        columnGap={16}
      >
        <Flex direction="column" rowGap={8} horizontalAlign="center">
          <Flex
            direction="row"
            verticalAlign="center"
            horizontalAlign="center"
            rowGap={16}
            columnGap={16}
          >
            <Flex
              className={style.cal}
              rowGap={2}
              columnGap={2}
              verticalAlign="center"
              horizontalAlign="center"
            >
              <Text variant="xs" className={style.month} weight="semibold">
                {meeting?.from ? format(new Date(meeting?.from), "MMM") : null}
              </Text>
              {meeting?.from ? (
                <Heading variant="s" weight="bold" as="span">
                  {format(new Date(meeting?.from), "dd")}
                </Heading>
              ) : null}
            </Flex>
            <Flex
              rowGap={4}
              columnGap={4}
              verticalPadding={8}
              className={style.info}
            >
              <Flex
                direction="column"
                rowGap={4}
                columnGap={8}
                className={style.details}
              >
                <Flex
                  direction="row"
                  verticalAlign="center"
                  rowGap={4}
                  columnGap={4}
                >
                  <Text variant="s" weight="medium">
                    {t("video-meeting")}
                  </Text>
                  <MaterialIcon
                    variant="outlined"
                    name="video_chat"
                    mode="primary"
                    size={18}
                  />
                </Flex>
                <Flex
                  direction="row"
                  className={style.meetingName}
                  verticalAlign="center"
                >
                  <ClampText rows={1}>
                    <Text variant="s" weight="medium">
                      {meeting?.name || "Undefined"}
                    </Text>
                  </ClampText>
                </Flex>
              </Flex>
              <Text variant="s" weight="medium" className={style.dateTime}>
                {`${format(new Date(meeting.from), "dd MMMM yyyy")} @ ${format(
                  new Date(meeting.from),
                  "HH:mm",
                )}-${format(new Date(meeting.to), "HH:mm")}`}
              </Text>
            </Flex>
          </Flex>

          <Text
            variant="s"
            weight="medium"
            className={style.dateTimeFullwidth}
            textAlign="center"
          >
            {`${format(new Date(meeting.from), "dd MMMM yyyy")} @ ${format(
              new Date(meeting.from),
              "HH:mm",
            )}-${format(new Date(meeting.to), "HH:mm")}`}
          </Text>
        </Flex>

        <Flex className={style.buttons}>{renderButtons}</Flex>
      </Flex>
      {meeting?.is_sender &&
      meeting?.status === "pending" &&
      !meeting?.deleted ? (
        <>
          <Separator />
          <Button
            contentAlign="end"
            dimension="small"
            kind="link"
            onClick={() => deleteMeeting(meeting?._id)}
            className={style.deleteMeeting}
          >
            {t("delete-meeting")}
          </Button>
        </>
      ) : null}
    </Flex>
  );
};

export default MeetingItem;
