import Link from "next/link";
import type { FC, ReactNode } from "react";
import React from "react";
import styles from "./customLink.module.scss";

export const CustomLink: FC<{
  external?: boolean;
  href: string;
  children?: ReactNode;
  className?: any; // TODO: @matte tipizzare
  style?: any; // TODO: @matte tipizzare
  active?: boolean;
  onClick?: () => void;
}> = ({
  external = false,
  href,
  children,
  className,
  style,
  active = true,
  onClick,
}) => {
  if (href === "#") {
    /* eslint-disable-next-line react/jsx-no-useless-fragment */
    return <>{children}</>;
  }

  return external ? (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.link} ${className}`}
      style={style}
      data-active={active}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      passHref
      href={`${href}`}
      className={`${styles.link} ${className}`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
