import { Flex, Heading } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { WorkspaceName } from "./workspaceName";
import styles from "./workspaceTab.module.css";

const WorkspaceTab = () => {
  const { t } = useTranslation("settings");

  return (
    <Flex rowGap={16} columnGap={16} className={styles.container}>
      <Heading variant="s" weight="bold">
        {t("workspace")}
      </Heading>
      <Flex className={styles.item}>
        <WorkspaceName />
      </Flex>
      {/* <TeamMembers /> */}
    </Flex>
  );
};

export default WorkspaceTab;
