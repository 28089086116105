import { useBillingProfile } from '@/hooks/useBillingProfile';
import { useSession } from '@/hooks/useSession';
import { useWorkspace } from '@/hooks/useWorkspace';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import type { FC, ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { UserContext } from './user';
import type { IMicroContent } from '../types';

type TPageViewProps = {
  event_name?: string,
  event_category: string,
  event_label: string,
  resource?: string,
  section?: string,
  invoice?: string,
  flow?: "regular" | "purchase",
  is_purchased?: boolean, // product purchased
  content_type?: IMicroContent['type'],
  content_language?: IMicroContent['language'],
  content_duration?: IMicroContent['duration'],
  content_categories?: string, // microcontent categories name comma separated
  playlist_categories?: string, // playlist categories name comma separated
  is_playlist_premium?: boolean, // !!playlist_price
  is_content_premium?: boolean, // content.is_locked
  content_tags?: string, // microcontent tags name comma separated
  playlist_title?: string,
  creator_id?: string, // content creator id or playlist creator ids comma separated
  creator_full_name?: string, // content creator full name (first_name_last_name) or playlist creator full names comma separated
  content_title?: string,
}

type TCaptureProps = {
  event_name?: string,
  event_category: string,
  event_label: string,
  resource?: string,
  section?: 'registration' | 'community' | 'events' | 'deals' | 'resources' | 'my_profile' | 'profile' | 'chat' | string,
  subsection?: string,
  flow?: "regular" | "purchase"
  activating?: boolean,
  filter_type?: 'search' | 'looking_for' | 'skills' | 'job' | 'city' | 'language' | 'activity' | 'explore_Analytics' | 'location' | "explore_events" | "categories" | "group_size" | "time_frame" | "start_date" | "end_date" | string,
  rank?: number
  percentage?: number,
  invoice?: string,
  amount?: number,
  currency?: string,
  payment_source?: string,
  meeting_id?: string,
  message_request_type?: string | null,
  is_purchased?: boolean,
  content_type?: IMicroContent['type'],
  content_language?: IMicroContent['language'],
  content_duration?: IMicroContent['duration'],
  content_categories?: string, // microcontent categories name comma separated
  playlist_categories?: string, // playlist categories name comma separated
  is_playlist_premium?: boolean, // !!playlist_price
  is_content_premium?: boolean, // content.is_locked
  content_tags?: string, // microcontent tags name comma separated
  playlist_title?: string,
  creator_id?: string, // content creator id or playlist creator ids comma separated
  creator_full_name?: string, // content creator full name (first_name_last_name) or playlist creator full names comma separated
  content_title?: string,
  resource_name?: string
  discounted_product?: string
}

export type AnalyticsContextType = {
  captureInteraction: (props: TCaptureProps) => void
  setPageView: (props: TPageViewProps) => void
};

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsContext = createContext<AnalyticsContextType>(
  {} as AnalyticsContextType,
);

const INFO_LOG = (text: string, color: string, params: any) => {
  if (process.env.ENVIRONMENT === 'production') {
    return;
  }
  return console.log(text, color, params)
}

export const AnalyticsContextProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const { user, ownerProfile, isLoadingUserInfo, isLoadingOwnerUserProfile } = useContext(UserContext);
  const { locale } = useRouter();
  const { workspace, isLoading: isLoadingWorkspace } = useWorkspace();
  const { billingProfile, isLoading: isLoadingBillingProfile } = useBillingProfile();

  useEffect(() => {
    if (!user?._id || !user?.email) {
      return;
    }

    const setIdentity = () => {
      posthog?.identify(user?._id, {
        email: user?.email
      });
      INFO_LOG('%c DEBUGGING POSTHOG SET IDENTITY', 'color: red', {
        user_id: user?._id,
        email: user?.email
      });
    }

    setIdentity();
  }, [user?._id, user?.email])

  useEffect(() => {
    // if is loading required info either user or workspace are missing
    if (isLoadingUserInfo || isLoadingOwnerUserProfile || isLoadingWorkspace || isLoadingBillingProfile || !user || !workspace) {
      return;
    }

    const userObject = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone_number: user?.phone?.number && user?.phone?.prefix ? `${user?.phone?.prefix}${user?.phone?.number}` : "",
      city: ownerProfile?.city,
      job_type: ownerProfile?.job_type,
      field_of_interest: ownerProfile?.field_of_interest,
      field_of_study: ownerProfile?.school_subject,
      school: ownerProfile?.school_name,
      company: ownerProfile?.company,
      job_role: ownerProfile?.job_role,
      // company_size: ownerProfile?.company_size,
      needs: ownerProfile?.needs?.map(k => k?.name),
      skills: ownerProfile?.skills?.map(k => k?.name),
      availabilities: ownerProfile?.availabilities?.map(k => k?.name),
      interests: ownerProfile?.interests?.map(k => k?.name),
      profile_image_url: ownerProfile?.profile_image_url,
      profile_image: !!ownerProfile?.profile_image_url,
      bio: !!ownerProfile?.bio,
      languages: ownerProfile?.languages,
      motto: !!ownerProfile?.short_motto,
      strengths: !!ownerProfile?.questions?.main_strengths,
      spare_time: !!ownerProfile?.questions?.spare_time,
      socials: ownerProfile?.socials?.map(k => k?.url),
      ideal_work_settings: !!ownerProfile?.work?.ideal_work_setting,
      ideal_work_vibes: !!ownerProfile?.work?.working_vibe,
      work_experiences: !!ownerProfile?.career_path?.work?.length,
      education: !!ownerProfile?.career_path?.education?.length,
      community_unlocked: ownerProfile?.is_profile_completed,
      profile_completed: ownerProfile?.is_profile_completed,
      email: user?.email,
      phone: user?.phone?.number && user?.phone?.prefix ? `${user?.phone?.prefix}${user?.phone?.number}` : "",
      created_at: user?.created_at.split(".")[0],
      customer_id: workspace?.billing_profile?.stripe_customer_id,
      workspace_id: workspace?._id,
      last_purchase_at: workspace?.purchases?.sort((a, b) => Date.parse(b?.created_at) - Date.parse(a?.created_at))?.[0]?.created_at.split(".")[0],
      n_purchases: workspace?.purchases?.length ?? 0,
      subscription_type: "free",
      billing_phone: billingProfile?.phone_number && billingProfile?.phone_prefix ? `${billingProfile?.phone_prefix}${billingProfile?.phone_number}` : "",
      billing_email: billingProfile?.billing_email,
      app_locale: locale,
      mongo_db_user_id: user?._id,
    }

    INFO_LOG('%c DEBUGGING POSTHOG $SET USER PROPS', 'color: green', { ...userObject });

    posthog.capture(
      '$set',
      {
        $set: userObject
      }
    )
  }, [
    locale,
    isLoadingUserInfo,
    isLoadingOwnerUserProfile,
    isLoadingWorkspace,
    isLoadingBillingProfile,
    user,
    ownerProfile,
    workspace,
    billingProfile
  ])

  const setPageView = useCallback((props: TPageViewProps) => {
    if (!locale) {
      return;
    }

    const {
      event_category,
      event_label,
      resource,
      is_purchased,
      invoice,
      flow,

      content_type,
      content_language,
      content_duration,
      content_categories,
      playlist_categories,
      is_playlist_premium,
      is_content_premium,
      content_tags,
      playlist_title,
      creator_id,
      creator_full_name,
      content_title
    } = props;

    INFO_LOG('%c DEBUGGING POSTHOG PAGE VIEW', 'color: orange', {
      app_locale: locale,
      event_category,
      event_label,
      resource,
      is_purchased,
      invoice,
      flow,
      content_type,
      content_language,
      content_duration,
      content_categories,
      playlist_categories,
      is_playlist_premium,
      is_content_premium,
      content_tags,
      playlist_title,
      creator_id,
      creator_full_name,
      content_title
    });

    posthog.capture('$pageview', {
      app_locale: locale,
      event_category,
      event_label,
      resource,
      is_purchased,
      invoice,
      flow,
      content_type,
      content_language,
      content_duration,
      content_categories,
      playlist_categories,
      is_playlist_premium,
      is_content_premium,
      content_tags,
      playlist_title,
      creator_id,
      creator_full_name,
      content_title
    });

  }, [locale]);

  const captureInteraction = useCallback((props: TCaptureProps) => {
    const {
      event_category,
      event_label,
      resource,
      section,
      subsection,
      activating,
      filter_type,
      rank,
      is_purchased,
      percentage,
      invoice,
      amount,
      currency,
      payment_source,
      meeting_id,
      message_request_type,
      flow,
      content_type,
      content_language,
      content_duration,
      content_categories,
      playlist_categories,
      is_playlist_premium,
      is_content_premium,
      content_tags,
      playlist_title,
      creator_id,
      creator_full_name,
      content_title,
      resource_name,
      discounted_product
    } = props;

    INFO_LOG('%c DEBUGGING POSTHOG INTERACTION: ', 'color: lime', {
      app_locale: locale,
      event_category,
      event_label,
      resource, section,
      subsection,
      activating,
      filter_type,
      rank,
      is_purchased,
      percentage,
      invoice,
      amount,
      currency,
      payment_source,
      meeting_id,
      message_request_type,
      flow,
      event_email: user?.email,
      content_type,
      content_language,
      content_duration,
      content_categories,
      playlist_categories,
      is_playlist_premium,
      is_content_premium,
      content_tags,
      playlist_title,
      creator_id,
      creator_full_name,
      content_title,
      resource_name,
      discounted_product
    });

    posthog?.capture("interaction", {
      app_locale: locale,
      event_category,
      event_label,
      resource,
      section,
      subsection,
      activating,
      filter_type,
      rank,
      is_purchased,
      percentage,
      invoice,
      amount,
      currency,
      payment_source,
      meeting_id,
      message_request_type,
      flow,
      event_email: user?.email,
      content_type,
      content_language,
      content_duration,
      content_categories,
      playlist_categories,
      is_playlist_premium,
      is_content_premium,
      content_tags,
      playlist_title,
      creator_id,
      creator_full_name,
      content_title,
      resource_name,
      discounted_product
    })
  }, [locale, user]);

  const AnalyticsContextValue = useMemo(() => {
    return {
      captureInteraction,
      setPageView
    };
  }, [
    captureInteraction,
    setPageView
  ]);

  return (
    <AnalyticsContext.Provider value={AnalyticsContextValue}>
      {children}
    </AnalyticsContext.Provider >
  );
};
