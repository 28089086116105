import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';
import { TMeeting } from '../types';

export const useMeetings = () => {
    const queryClient = useQueryClient();

    const { data: meetings, isLoading: isLoadingMeetings, error: errormeetings } = useQuery(['meetings'], async () => {
        return axios.get('/api/meetings/')
    })

    const isLoading = !meetings?.data || isLoadingMeetings;

    const createMeeting = useCallback(async (data: any) => {
        try {
            const postResponse = await axios.post("/api/meetings/", data)
            queryClient.invalidateQueries(['meetings'], { exact: true });
            return postResponse.data?.data as {
                acknowledged
                : boolean;
                insertedId: string
            };
        } catch (error) {
            throw new Error('Error setting meeting');
        }
    }, [])

    const editMeeting = useCallback(async (meetingId: string, data: any) => {
        try {
            await axios.patch(`/api/meetings/?meetingId=${meetingId}`, data)
            queryClient.invalidateQueries(['meetings'], { exact: true });
        } catch (error: any) {
            throw new Error('Error editing meeting', error);
        }
    }, [])

    const deleteMeeting = useCallback(async (meetingId: string,) => {
        try {
            await axios.delete(`/api/meetings/?meetingId=${meetingId}`)
            queryClient.invalidateQueries(['meetings'], { exact: true });
        } catch (error) {
            console.log('Error: ', error);
            throw new Error('Error deleting meeting',);
        }
    }, [])

    return {
        isLoading,
        error: errormeetings,
        meetings: (meetings?.data?.data as TMeeting[])?.map(m => ({ ...m, url: m?.url ? `${m.url}?subject=${m.name}` : null })),
        createMeeting,
        editMeeting,
        deleteMeeting
    }
}
