import type { SimpleUser } from "@/context/pubnub";
import {
  Badge,
  Button,
  Flex,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./acceptBox.module.scss";

interface AcceptBoxProps {
  joinChannel: () => void;
  refuseChannel: () => void;
  host: SimpleUser;
  availability?: string;
}

export const AcceptBox: FC<AcceptBoxProps> = ({
  joinChannel,
  refuseChannel,
  host,
  availability,
}) => {
  const { t } = useTranslation("messages");

  return (
    <Flex
      direction="column"
      className={styles.container}
      verticalAlign="center"
      horizontalAlign="space-between"
      horizontalPadding={16}
      verticalPadding={16}
      rowGap={16}
      columnGap={16}
    >
      <Flex horizontalAlign="start" direction="row" columnGap={8} rowGap={8}>
        <MaterialIcon size={24} name="add_reaction" />
        <Flex
          direction="column"
          verticalAlign="center"
          columnGap={8}
          rowGap={12}
          horizontalAlign="start"
        >
          <Text variant="s" weight="bold">
            {t("messages:chat-accept-box-message", {
              name: host?.channelName,
            })}
          </Text>
          {availability ? (
            <Badge dimension="medium" kind="tertiary" outline>
              {availability}
            </Badge>
          ) : null}
        </Flex>
      </Flex>
      <Flex
        className={styles.btnsContainer}
        direction="column"
        columnGap={16}
        rowGap={16}
        verticalAlign="center"
        horizontalAlign="center"
      >
        <Button
          capitalize={false}
          dimension="small"
          kind="secondary-neutral"
          type="button"
          fontWeight="semibold"
          onClick={() => {
            refuseChannel();
          }}
        >
          {t("messages:chat-accept-box-cancel")}
        </Button>
        <Button
          capitalize={false}
          dimension="small"
          kind="primary"
          type="button"
          fontWeight="semibold"
          onClick={joinChannel}
        >
          {t("messages:chat-accept-box-confirm")}
        </Button>
      </Flex>
    </Flex>
  );
};
