import { useSubscription } from "@/hooks/useSubscription";
import { useWorkspace } from "@/hooks/useWorkspace";
import type { TSubscription, TWorkspace, Tier } from "@/types/types";
import { Flex, Heading, SkeletonLoader } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { useMemo, type FC } from "react";
import { FreeMembership } from "./freeMembership";
import { PaidMembership } from "./paidMembership";
import styles from "./planTab.module.css";

const Plan: FC<{
  // totalAmount: number;
  // membership: any;
  // items: any[];
  // isFree: boolean;
  subscription: TSubscription;
  workspace: TWorkspace;
}> = ({ subscription, workspace }) => {
  const { membership, items, totalAmount } = useMemo(() => {
    const subscriptionItems = subscription?.items?.map((item: any) => {
      let tier = item?.tiers?.[0];

      if (item?.tiers?.length) {
        const allUpToPlans = item?.tiers?.map(
          (currentTier: Tier) => currentTier?.up_to,
        );
        const tiersMax = Math.max(...allUpToPlans);

        if (item?.quantity > tiersMax) {
          tier = item?.tiers?.find(
            (currentTier: Tier) => currentTier?.up_to === null,
          );
        } else {
          tier = item?.tiers?.find(
            (e: Tier) => (e?.up_to || 0) >= item?.quantity,
          );
        }
      }

      return {
        ...item,
        tier,
        amount: !tier
          ? (item?.amount || 0) * (item?.quantity || 1)
          : (tier?.unit_amount_decimal || 0) * (item?.quantity || 1),
      };
    });

    // eslint-disable-next-line no-shadow
    const totalAmount = subscriptionItems?.reduce(
      (accumulator: number, item: any) =>
        accumulator + (item?.amount || 0) / 100 || 0,
      0,
    );

    return {
      membership: subscriptionItems?.find(
        (item: any) => item?.type === "membership",
      ),
      totalAmount,
      items: subscriptionItems,
    };
  }, [subscription]);

  //           current_period_end
  // :
  // "2023-11-11T13:17:31.000Z"
  // current_period_start
  // :
  // "2023-10-11T13:17:31.000Z"

  return subscription?.is_free ? (
    <FreeMembership membership={membership} />
  ) : (
    <PaidMembership
      membership={membership}
      totalAmount={totalAmount}
      items={items}
      workspace={workspace}
      subscription={subscription}
    />
  );
};

const PlanTab: FC = () => {
  const { t } = useTranslation("settings");

  // TODO change API call to next-invoice
  const { subscription, isLoading } = useSubscription();
  const { workspace, isLoading: isLoadingWorkspace } = useWorkspace();

  return (
    <Flex rowGap={16} columnGap={16} className={styles.tab}>
      <Heading variant="s" weight="bold">
        {t("your-current-plan")}
      </Heading>
      {!isLoading && !isLoadingWorkspace ? (
        <Plan subscription={subscription} workspace={workspace} />
      ) : (
        <SkeletonLoader height={400} width="100%" radius={20} />
      )}
    </Flex>
  );
};

export default PlanTab;
