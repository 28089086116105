import {
  Alert,
  Button,
  Dialog,
  Flex,
  Separator,
  Text,
  TextField,
} from "@talent-garden/react-components";
import clsx from "clsx";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";

import { DialogFooter, DialogHeader } from "@/components/common";
import { useWorkspace } from "@/hooks/useWorkspace";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "next-i18next";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import accountStyles from "../../accountTab/accountTab.module.scss";
import { WorkspacePicture } from "../workspacePicture";

export const WorkspaceName: FC<{ className?: string }> = ({ className }) => {
  const { workspace, editWorkspace } = useWorkspace();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation(["inputs", "setting"]);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const onOpenChange = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );
  const onErrorOpenChange = useCallback(
    (open: boolean) => setErrorAlertOpen(open),
    [],
  );

  const schema = yup.object().shape({
    name: yup.string().required("missing-workspace-name"),
  });

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = formMethods;

  useEffect(() => {
    if (modalOpen) {
      return;
    }
    reset({
      name: "",
    });
    clearErrors();
  }, [modalOpen]);

  const [name] = watch(["name"]);

  const onSubmit: SubmitHandler<any> = async (data: { name: string }) => {
    try {
      await editWorkspace(data);
      setSuccessAlertOpen(true);
    } catch (err) {
      console.error(err);
      setErrorAlertOpen(true);
    } finally {
      setModalOpen(false);
    }
  };

  const onError: SubmitErrorHandler<any> = (err, e) => {
    console.error("Errors: ", err, e);
  };

  return (
    <div>
      <Flex className={clsx(className)} rowGap={24} columnGap={24}>
        <Flex
          horizontalAlign="space-between"
          direction="row"
          verticalAlign="start"
        >
          <Flex rowGap={12} columnGap={12}>
            <Text variant="m" weight="medium" className={accountStyles.title}>
              {t("settings:workspace-name")}
            </Text>
            <Text
              variant="m"
              weight="medium"
              className={accountStyles.subtitle}
            >
              {workspace?.name || t("inputs:not-set")}
            </Text>
          </Flex>
          <Dialog
            activator={
              <Button
                kind="link-neutral"
                capitalize={false}
                iconRight
                icon="edit"
              >
                {t("settings:change")}
              </Button>
            }
            className={accountStyles.dialog}
            onOpenChange={(open: boolean) => setModalOpen(open)}
            withSeparator
            title={
              <DialogHeader
                title={t("settings:change-workspace-title")}
                icon="workspaces"
                subtitle={t("settings:change-workspace-subtitle") as string}
              />
            }
            open={modalOpen}
          >
            <Flex className={accountStyles.content}>
              <TextField
                label={t("inputs:new-workspace-name") as string}
                placeholder={
                  t("inputs:new-workspace-name-placeholder") as string
                }
                className={accountStyles.input}
                required
                errorMessage={
                  errors && errors?.name
                    ? (t(`inputs:${errors?.name.message}`) as string)
                    : undefined
                }
                {...register("name")}
              />
              <DialogFooter
                submitText={t("settings:change-workspace")}
                onSubmit={() => {
                  handleSubmit(onSubmit, onError)();
                }}
                onCancel={() => {
                  setModalOpen(false);
                }}
                cancelText={t("settings:cancel")}
                submitDisabled={!name || name === workspace?.name}
              />
            </Flex>
          </Dialog>
        </Flex>
        <Separator />
        <WorkspacePicture
          workspace={workspace}
          accountStyles={accountStyles}
          editWorkspace={editWorkspace}
        />
      </Flex>
      <Alert
        title={t("settings:alert-workspace-success-title")}
        description={t("settings:alert-workspace-success-subtitle")}
        isOpen={successAlertOpen}
        handleOpenChange={onOpenChange}
        icon="check_circle"
        withDismiss
        type="success"
        duration={4000}
        iconVariant="outlined"
      />
      {/* Error */}
      <Alert
        title={t("settings:alert-workspace-error-title")}
        description={t("settings:alert-workspace-error-subtitle")}
        isOpen={errorAlertOpen}
        handleOpenChange={onErrorOpenChange}
        icon="error"
        withDismiss
        type="error"
        duration={4000}
        iconVariant="outlined"
      />
    </div>
  );
};
