import { DialogFooter, Spinner } from "@/components/common";
import { PaymentCardForm } from "@/components/payments";
import { usePaymentMethods } from "@/hooks/usePaymentMethods";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type { StripeCardNumberElement } from "@stripe/stripe-js";
import {
  Alert,
  Dialog,
  Flex,
  Heading,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC, ReactElement } from "react";
import { useCallback, useEffect, useState } from "react";
import styles from "../editCardDetails.module.scss";

const AddPaymentMethod: FC<{
  onOpenChangeExternally?: (open: boolean) => void;
  openExternally?: boolean;
  activator?: ReactElement;
  onAddPaymentMethodCallback?: (paymentId: string) => void;
}> = ({
  onOpenChangeExternally,
  openExternally,
  activator,
  onAddPaymentMethodCallback,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation(["payments"]);
  const { addPaymentMethod } = usePaymentMethods();
  const stripe = useStripe();
  const elements = useElements();

  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onAlertOpenChange = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );

  const onAlertErrorOpenChange = useCallback(
    (open: boolean) => setErrorAlertOpen(open),
    [],
  );

  const onError = useCallback((error: any) => {
    console.log("Error", error);
  }, []);

  const onAddCardSuccess = useCallback(async (paymentId: string) => {
    try {
      setSubmitting(true);

      await addPaymentMethod(paymentId);
      if (onAddPaymentMethodCallback) {
        onAddPaymentMethodCallback(paymentId);
      }
      setSuccessAlertOpen(true);
    } catch (err: any) {
      // missing error toast
      setErrorAlertOpen(true);
    } finally {
      setModalOpen(false);
      setSubmitting(false);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // setErrors(null);
    setSubmitting(true);

    const cardNumber = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumber as StripeCardNumberElement,
    });

    if (error) {
      console.log("Error:", error);
      onError?.(error);
      setSubmitting(false);
    } else {
      await onAddCardSuccess(paymentMethod?.id);
    }
  }, [stripe, elements]);

  const onOpenChange = useCallback(
    (value: boolean) => {
      setModalOpen(value);
      onOpenChangeExternally?.(value);
    },
    [onOpenChangeExternally],
  );

  useEffect(() => {
    if (openExternally === undefined || openExternally === null) {
      return;
    }
    setModalOpen(openExternally);
  }, [openExternally]);

  return (
    <div>
      <Dialog
        activator={activator}
        className={styles.dialog}
        onOpenChange={onOpenChange}
        title={
          <Flex className={styles.iconTitle}>
            <MaterialIcon name="add_card" variant="outlined" size={24} />
          </Flex>
        }
        open={modalOpen}
        withSeparator={false}
        data-dimension="regular"
      >
        <Flex className={styles.content}>
          <Heading variant="s" as="h3" weight="bold">
            {t("add-new-payment-method-dialog-title")}
          </Heading>
          <Text variant="m" className={styles.text}>
            {t("add-new-payment-method-dialog-card")}
          </Text>
          <PaymentCardForm extraStyle={styles.cardForm} />
          <DialogFooter
            submitText={submitting ? <Spinner /> : t("payments:add-card")}
            onSubmit={handleSubmit}
            onCancel={() => {
              onOpenChange(false);
            }}
            cancelText={t("settings:cancel")}
            submitDisabled={submitting}
          />
        </Flex>
      </Dialog>
      <Alert
        title={t("payment-method-added-title")}
        description={t("payment-method-added-subtitle")}
        isOpen={successAlertOpen}
        handleOpenChange={onAlertOpenChange}
        icon="check_circle"
        withDismiss
        type="success"
        duration={4000}
        iconVariant="outlined"
      />
      {/* Error */}
      <Alert
        title={t("payment-method-added-error-title")}
        description={t("payment-method-added-error-subtitle")}
        isOpen={errorAlertOpen}
        handleOpenChange={onAlertErrorOpenChange}
        icon="error"
        withDismiss
        type="error"
        duration={4000}
        iconVariant="outlined"
      />
    </div>
  );
};

export default AddPaymentMethod;
