import { Flex, Heading, PhoneField } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { BillingTextField } from "../inputs/textField";

import styles from "./forms.module.scss";

const CustomerForm: FC<{
  defaultValues: { phonePrefix?: string; phoneNumber?: string };
}> = ({ defaultValues }) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation(["buy", "inputs"]);

  return (
    <Flex className={styles.inputsContainer} columnGap={16} rowGap={16}>
      <Heading variant="s" weight="bold">
        {t("buy:billing-contacts-title")}
      </Heading>
      <Flex
        columnGap={8}
        rowGap={8}
        direction="column"
        className={styles.formChild}
      >
        <BillingTextField
          label={t("inputs:edit-email") as string}
          placeholder={t("inputs:insert-billing-email") as string}
          name="billingEmail"
          type="email"
          icon="mail_outline"
          iconMode="gray"
        />
        <PhoneField
          defaultPrefixValue={defaultValues?.phonePrefix || "+39"}
          defaultValue={defaultValues?.phoneNumber}
          label={t("inputs:billing-phone-number")}
          placeholder={t("inputs:billing-phone-number-placeholder") as string}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setValue("customer.phoneNumber", ev?.target?.value as string, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
          }}
          onPrefixChange={(prefix) => {
            setValue("customer.phonePrefix", prefix, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
          }}
          errorMessage={
            errors &&
            ((errors?.customer as any)?.phoneNumber ||
              (errors?.customer as any)?.phonePrefix)
              ? (t(
                  `inputs:${(
                    (errors?.customer as any)?.phoneNumber ||
                    (errors?.customer as any)?.phonePrefix
                  )?.message}`,
                ) as string)
              : undefined
          }
          className={styles.input}
        />
      </Flex>
    </Flex>
  );
};

export default CustomerForm;
