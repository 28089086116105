import { CARD_ELEMENT_OPTIONS } from "@/lib/utils";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { Flex } from "@talent-garden/react-components";
import type { FC } from "react";
import clsx from "clsx";

import styles from "./payments.module.css";

export const PaymentCardForm: FC<{
  extraStyle?: string;
}> = ({ extraStyle }) => {
  return (
    <Flex
      rowGap={16}
      columnGap={16}
      className={clsx(extraStyle, "ph-no-capture")}
    >
      <CardNumberElement
        className={styles.border}
        options={{
          showIcon: true,
          iconStyle: "solid",
          ...CARD_ELEMENT_OPTIONS,
        }}
      />
      <Flex direction="row" className={styles.row} rowGap={12} columnGap={12}>
        <CardExpiryElement
          className={styles.border}
          options={{ ...CARD_ELEMENT_OPTIONS }}
        />
        <CardCvcElement
          className={styles.border}
          options={{ ...CARD_ELEMENT_OPTIONS }}
        />
      </Flex>
      {/* <Button
        capitalize={false}
        icon="arrow_forward"
        fontWeight="semibold"
        iconRight
        onClick={handleSubmit}
      >
        {t("add-card")}
      </Button> */}
    </Flex>
  );
};
