import { usePaymentMethods } from "@/hooks/usePaymentMethods";
import type { TPaymentCard } from "@/types/types";
import {
  Button,
  Dialog,
  Flex,
  Heading,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import { useCallback, useState } from "react";

const SetDefaultCardDialog: FC<{
  style: any;
  card: TPaymentCard;
  isEditEnabled?: boolean;
  closeDropdown: () => void;
  showSuccessAlert: () => void;
  showErrorAlert: () => void;
}> = ({
  style,
  card,
  isEditEnabled,
  closeDropdown,
  showSuccessAlert,
  showErrorAlert,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation("payments");
  const { editPaymentMethod } = usePaymentMethods();

  const submitRequest = useCallback(
    async (data: any) => {
      try {
        await editPaymentMethod(card?.id, data);
        showSuccessAlert();
      } catch (err: any) {
        // missing error toast
        showErrorAlert();
      } finally {
        setModalOpen(false);
        setTimeout(() => {
          closeDropdown();
        }, 50);
      }
    },
    [card?.id],
  );

  return (
    <Dialog
      activator={
        <Button
          capitalize={false}
          dimension="small"
          icon="done_all"
          kind="link-neutral"
          fontWeight="medium"
          disabled={card?.default || !isEditEnabled}
        >
          {t("set-as-default")}
        </Button>
      }
      className={style.dialog}
      onOpenChange={(open: boolean) => setModalOpen(open)}
      title={
        <Flex className={style.iconTitle}>
          <MaterialIcon name="done_all" variant="outlined" size={24} />
        </Flex>
      }
      open={modalOpen}
      withSeparator={false}
      data-dimension="small"
      overlayZIndex={10}
    >
      <Flex className={style.content}>
        <Heading variant="s" as="h3" weight="bold">
          {t("set-default-card-title")}
        </Heading>
        <Text variant="m" className={style.text}>
          {t("set-default-card-description", { card: card?.formattedText })}
        </Text>
        <Button
          onClick={() => submitRequest({ set_as_default: true })}
          className={style.button}
        >
          {t("set-default-card-cta")}
        </Button>
      </Flex>
    </Dialog>
  );
};

export default SetDefaultCardDialog;
