/* eslint-disable react/no-unstable-nested-components */
import { Image, Pagination } from "@/components/common";
import { EmptyState } from "@/components/common/emptyState";
import { useInvoices } from "@/hooks/useInvoices";
import { getFormatDateByLocale, getLocale } from "@/lib/lang";
import {
  Badge,
  Button,
  Flex,
  Heading,
  SkeletonLoader,
  Text,
} from "@talent-garden/react-components";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMemo, type FC } from "react";

import { useScreenWidth } from "@/hooks/useScreenWidth";
import type { TNormalizedInvoice } from "@/lib/normalizer";
import styles from "./paymentsList.module.scss";

const Table: FC<{ data: TNormalizedInvoice[]; columns: any }> = ({
  data = [],
  columns = [],
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  });

  return (
    <Flex className={styles.container}>
      <table className={styles.table} cellSpacing="0" cellPadding="0">
        <thead className={styles.thead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination table={table} />
    </Flex>
  );
};

export const PaymentsList: FC = () => {
  const { locale } = useRouter();
  const { t } = useTranslation("payments");
  const { invoices, isLoading } = useInvoices();
  const { isMobile } = useScreenWidth();

  const getBadgeFromStatus = (status: string) => {
    if (status === "paid" || status === "succeeded") {
      return (
        <Badge dimension="small" kind="success" icon="check_circle_outline">
          {t(`status.${status}`) as string}
        </Badge>
      );
    }
    if (status === "processing") {
      return (
        <Badge
          dimension="small"
          kind="tertiary"
          icon="pending"
          iconVariant="outlined"
        >
          {t(`status.${status}`) as string}
        </Badge>
      );
    }
    return (
      <Badge dimension="small" kind="danger" icon="error_outline">
        {t(`status.${status}`) as string}
      </Badge>
    );
  };

  const columnHelper = createColumnHelper<TNormalizedInvoice>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("billing_date", {
        header: () => (
          <Text variant="xs" weight="medium">
            {t("payment-table-billing-date")}
          </Text>
        ),
        cell: (info) => (
          <Text variant="s" weight="medium">
            {format(new Date(info.getValue()), getFormatDateByLocale(locale!), {
              locale: getLocale(locale!),
            })}
          </Text>
        ),
      }),
      columnHelper.accessor("description", {
        header: () => (
          <Text variant="xs" weight="medium">
            {t("payment-table-description")}
          </Text>
        ),
        cell: (info) => (
          <Text variant="s" weight="medium">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor("amount", {
        header: () => (
          <Text variant="xs" weight="medium">
            {t("payment-table-amount")}
          </Text>
        ),
        cell: (info) => (
          <Text variant="s" weight="medium">{`€ ${(info.getValue() / 100)
            .toFixed(2)
            .replace(".", ",")}`}</Text>
        ),
      }),
      columnHelper.accessor("payment_method", {
        header: () => (
          <Text variant="xs" weight="medium">
            {t("payment-table-payment-method")}
          </Text>
        ),
        cell: (info) => {
          const data = info.getValue();

          return (
            <Flex
              direction="row"
              rowGap={4}
              columnGap={4}
              verticalAlign="center"
            >
              <Text variant="s" weight="medium">{`${data.formattedText}`}</Text>
            </Flex>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: () => (
          <Text variant="xs" weight="medium">
            {t("payment-table-status")}
          </Text>
        ),
        cell: (info) => {
          const status: TNormalizedInvoice["status"] = info.getValue();
          return getBadgeFromStatus(status);
        },
      }),
      columnHelper.accessor("invoice_pdf", {
        header: () => (
          <Text variant="xs" weight="medium">
            {t("payment-table-invoice")}
          </Text>
        ),
        cell: (info) => {
          const link = info.getValue();

          return (
            <Button
              as="a"
              kind="link-neutral"
              capitalize={false}
              iconRight
              icon={link ? "download" : undefined}
              href={link}
              target="_blank"
              disabled={!link}
              dimension="small"
            >
              {t("payment-table-invoice-download")}
            </Button>
          );
        },
      }),
    ],
    [],
  );

  const memoInvoices = useMemo(() => invoices, [invoices]);

  return (
    <Flex>
      <Heading variant="s" weight="bold">
        {t("payment-table-title")}
      </Heading>
      {isLoading ? (
        <SkeletonLoader
          height={320}
          width="100%"
          radius={12}
          className={styles.container}
        />
      ) : invoices?.length ? (
        isMobile ? (
          <Flex
            direction="column"
            rowGap={12}
            className={styles.cardsContainer}
          >
            {invoices.map((invoice) => {
              return (
                <Flex
                  key={invoice.billing_date.toString()}
                  direction="column"
                  className={styles.cardWrapper}
                >
                  <Text
                    variant="xs"
                    weight="medium"
                    className={styles.cardHeader}
                  >
                    {t("payment-table-title")}
                  </Text>
                  <Flex rowGap={4}>
                    <Text variant="s" weight="medium">
                      {format(
                        new Date(invoice.billing_date),
                        getFormatDateByLocale(locale!),
                        {
                          locale: getLocale(locale!),
                        },
                      )}
                    </Text>
                    <Text variant="s" weight="medium">
                      {invoice.description}
                    </Text>
                    <Flex direction="row" columnGap={4} verticalAlign="center">
                      <Text
                        variant="s"
                        weight="medium"
                      >{`${invoice?.payment_method?.formattedText}`}</Text>
                    </Flex>
                    <Text variant="s" weight="medium">
                      {`€ ${(invoice.amount / 100)
                        .toFixed(2)
                        .replace(".", ",")}`}
                    </Text>
                    <Flex
                      direction="row"
                      horizontalAlign="space-between"
                      rowGap={4}
                    >
                      {getBadgeFromStatus(invoice.status)}
                      <Button
                        as="a"
                        kind="link-neutral"
                        capitalize={false}
                        iconRight
                        icon={invoice.invoice_pdf ? "download" : undefined}
                        href={invoice.invoice_pdf}
                        target="_blank"
                        disabled={!invoice.invoice_pdf}
                        dimension="small"
                      >
                        {t("payment-table-invoice-download")}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <Table data={memoInvoices} columns={columns} />
        )
      ) : (
        <EmptyState
          title={t("payments-empty-state-title")}
          description={t("payments-empty-state-subtitle")}
          icon="paid"
          className={styles.emptyState}
        />
      )}
    </Flex>
  );
};
