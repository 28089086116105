import type { TPaymentCard } from "@/types/types";
import { Flex, Text } from "@talent-garden/react-components";
import type { FC } from "react";

import { Image } from "../image";
import styles from "./cardDetails.module.css";

const CardDetails: FC<{ paymentCard: TPaymentCard }> = ({ paymentCard }) => {
  return (
    <Flex
      direction="row"
      rowGap={12}
      columnGap={12}
      className={styles.card}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <Flex
        className={styles.logo}
        verticalAlign="center"
        horizontalAlign="center"
      >
        <Image
          src={paymentCard?.imageSrc}
          width={16}
          height={16}
          alt={paymentCard?.formattedText}
        />
      </Flex>
      <Flex rowGap={4} columnGap={4}>
        <Text variant="m" weight="medium" className={styles.name}>
          {paymentCard?.formattedText}
        </Text>
        {!!paymentCard?.expMonth && !!paymentCard?.expYear ? (
          <Text variant="m" weight="regular" className={styles.expiration}>
            {`Expiration: ${paymentCard?.expMonth}/${paymentCard?.expYear}`}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default CardDetails;
