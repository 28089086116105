import { Image } from "@/components/common";
import { goBack } from "@/lib/utils";
import {
  Button,
  Flex,
  Heading,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import Link from "next/link";
import { useRouter } from "next/router";
import type { FC } from "react";
import styles from "../planTab.module.css";

const FreeMembership: FC<{ membership: any }> = ({ membership }) => {
  const { asPath } = useRouter();

  return (
    <Flex
      rowGap={24}
      columnGap={24}
      direction="column"
      className={styles.container}
    >
      <Flex
        verticalAlign="space-between"
        rowGap={24}
        columnGap={24}
        className={styles.freePlan}
      >
        <Flex rowGap={4} columnGap={4}>
          <Text variant="m" weight="bold">
            Plan
          </Text>
          <Heading variant="demi" weight="bold">
            {membership?.name}
          </Heading>
        </Flex>
        <Flex rowGap={4} columnGap={4}>
          <Text variant="m" weight="bold">
            Description
          </Text>
          <Text variant="m" weight="regular">
            Our basic membership to be part of the Talent Garden family and gain
            access to community and common spaces in our campuses.
          </Text>
        </Flex>
        <Flex rowGap={8} columnGap={8}>
          <Flex
            rowGap={8}
            columnGap={8}
            direction="row"
            verticalAlign="center"
            className={styles.item}
          >
            <MaterialIcon name="done" size={24} />
            <Text variant="m" weight="semibold">
              {" "}
              Explore community
            </Text>
          </Flex>
          <Flex
            rowGap={8}
            columnGap={8}
            direction="row"
            verticalAlign="center"
            className={styles.item}
          >
            <MaterialIcon name="done" size={24} />
            <Text variant="m" weight="semibold">
              Access to public events only
            </Text>
          </Flex>
          <Flex
            rowGap={8}
            columnGap={8}
            direction="row"
            verticalAlign="center"
            className={styles.item}
          >
            <MaterialIcon name="done" size={24} />
            <Text variant="m" weight="semibold">
              Discounts for master courses
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        className={styles.newPlan}
        rowGap={24}
        columnGap={24}
        verticalAlign="space-between"
        horizontalAlign="start"
      >
        <Flex rowGap={4} columnGap={4} horizontalAlign="start">
          <Image
            src="/images/free-plan.svg"
            alt="Free plan image"
            width={115}
            height={92}
            className={styles.image}
          />
          <Text variant="m" weight="bold">
            UPGRADE TO
          </Text>
          <Heading variant="demi" weight="bold">
            Community
          </Heading>
        </Flex>
        <Text variant="s" weight="regular">
          Membership for real Taggers. Expand your network of connections, meet
          innovative professionals, companies and startups and participate in
          exclusive events in our community.
        </Text>
        <Flex rowGap={8} columnGap={8}>
          <Heading variant="xs" weight="medium">
            Starts from
          </Heading>
          <Flex direction="row" verticalAlign="end" rowGap={8} columnGap={8}>
            <Heading variant="demi" weight="bold">
              € 29,00
            </Heading>
            <Text variant="l" className={styles.priceLabel}>
              user/month
            </Text>
          </Flex>
          <Link
            href={`${goBack(asPath, 1)}/plan/upgrade`}
            className={styles.link}
          >
            <Button kind="primary" dimension="large" fullWidth>
              Upgrade plan
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FreeMembership;
