import * as yup from "yup";

export const customerSchema = yup
  .object()
  .shape({
    billingEmail: yup.string().email("valid-email").required("missing-email"),
    phoneNumber: yup
      .string()
      .required("required-phone")
      .typeError("invalid-phone")
      .test(
        "len",
        "invalid-phone",
        (val) => (val || "")?.toString()?.length > 5,
      ),
    phonePrefix: yup.string().required("required-prefix"),
  })
  .required();

export type CustomerSchema = yup.InferType<typeof customerSchema>;
