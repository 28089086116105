import type {
  ChatMember,
  CustomObject,
  PubnubContextType,
} from "@/context/pubnub";
import { PubnubContext } from "@/context/pubnub";
import { Alert } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import { useAnalytics } from "@/hooks/useAnalytics";
import { AcceptBox } from "../acceptBox";
import { RefusedBox } from "../refusedBox";
import { SendMessageBox } from "../sendMessageBox";

interface ChatFooterComponentProps {
  channelStatus: CustomObject["status"];
  users: {
    sender: ChatMember["user"] | undefined;
    receiver: ChatMember["user"] | undefined;
  };
}

export const ChatFooterComponent: FC<ChatFooterComponentProps> = ({
  users,
  channelStatus,
}) => {
  const { t } = useTranslation("messages");
  const {
    chatPubnub,
    historyMessagesPubnub,
    channelSelected,
    sendMessageToChannel,
    joinChannel,
    refuseChannel,
    currentUser,
    getHost,
    isHost,
  } = useContext(PubnubContext) as PubnubContextType;
  const { captureInteraction } = useAnalytics();

  const [alertOpen, setAlertOpen] = useState(false);

  const host = useMemo(
    () => channelSelected && getHost(channelSelected).host,
    [channelSelected, getHost],
  );

  const join = useCallback(
    async (subsection: "after_refuse" | "direct") => {
      if (!channelSelected?.id) {
        return;
      }
      try {
        await joinChannel(channelSelected?.id);
        captureInteraction({
          event_category: "community",
          event_label: "accept_contact_request",
          resource: users.sender?.id,
          section: "messages",
          subsection,
        });
        setAlertOpen(true);
      } catch (err) {
        console.log("Error:", err);
      }
    },
    [joinChannel, channelSelected?.id],
  );

  if (
    !channelSelected ||
    !chatPubnub ||
    !channelStatus ||
    !currentUser ||
    !host
  ) {
    return null;
  }

  const currentUserIsHost = isHost(channelSelected);

  const renderContent = () => {
    switch (channelStatus) {
      case "pending":
        return currentUserIsHost ? (
          <SendMessageBox
            sendMessageToChannel={sendMessageToChannel}
            users={users}
            channelStatus={channelStatus}
            messagesLength={historyMessagesPubnub?.messages?.length || 0}
            isHost={currentUserIsHost}
          />
        ) : (
          <AcceptBox
            joinChannel={() => join("direct")}
            refuseChannel={() => {
              refuseChannel(channelSelected?.id);
              captureInteraction({
                event_category: "community",
                event_label: "decline_contact_request",
                resource: users.sender?.id,
                section: "messages",
              });
            }}
            host={host}
            availability={String(channelSelected.custom?.availability)}
          />
        );
      case "refused":
        return currentUserIsHost ? (
          <SendMessageBox
            sendMessageToChannel={sendMessageToChannel}
            users={users}
            channelStatus={channelStatus}
            messagesLength={historyMessagesPubnub?.messages?.length || 0}
            isHost={currentUserIsHost}
          />
        ) : (
          <RefusedBox joinChannel={() => join("after_refuse")} />
        );
      case "accepted":
        return (
          <SendMessageBox
            sendMessageToChannel={sendMessageToChannel}
            users={users}
            messagesLength={historyMessagesPubnub?.messages?.length || 0}
            isHost={currentUserIsHost}
            channelStatus={channelStatus}
          />
        );
      default:
        return <>status {channelStatus} not matching any supported case</>;
    }
  };

  return (
    <>
      {renderContent()}
      {/* Invitation accepted alert */}
      <Alert
        title={t("invitation-accepted-alert-title")}
        description={t("invitation-accepted-alert-description")}
        handleOpenChange={() => setAlertOpen(!alertOpen)}
        icon="check_circle"
        position="top"
        type="success"
        withDismiss
        duration={3000}
        isOpen={alertOpen}
      />
    </>
  );
};
