import * as yup from "yup";

export const individualSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("required-first"),
    lastName: yup.string().required("required-last"),
    country: yup.string().required("missing-country"),
    formattedAddress: yup.string().required("missing-address"),
    fiscalCode: yup.string().when("country", {
      is: (country: string) => country === "IT",
      then: (
        schema, // <---- notice here return function
      ) =>
        schema
          .required("missing-fiscal_code")
          .matches(
            /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$/,
            "invalid-fiscal_code",
          ),
    }),
    // email: yup.string().email("valid-email").required("missing-email"),
    // phoneNumber: yup
    //   .string()
    //   .required("required-phone")
    //   .typeError("invalid-phone")
    //   .test(
    //     "len",
    //     "invalid-phone",
    //     (val) => (val || "")?.toString()?.length > 5,
    //   ),
    // phonePrefix: yup.string().required(),
  })
  .required();

export type IndividualSchema = yup.InferType<typeof individualSchema>;
