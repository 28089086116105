import {
  Button,
  Flex,
  Heading,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";

import type { FC, ReactNode } from "react";
import type { MaterialIconNames } from "@talent-garden/react-components/icons/mui-types";
import clsx from "clsx";
import style from "./emptyState.module.scss";

type EmptyStateProps = {
  title: string;
  description: string;
  icon: MaterialIconNames;
  action?: () => void;
  buttonText?: string;
  buttonIcon?: MaterialIconNames;
  className?: string;
  buttonComponent?: ReactNode;
};

const EmptyState: FC<EmptyStateProps> = ({
  action,
  title,
  description,
  icon,
  buttonText,
  buttonIcon,
  className,
  buttonComponent,
}) => {
  return (
    <Flex
      direction="column"
      className={clsx(style.emptyState, className)}
      verticalAlign="center"
      horizontalAlign="start"
      rowGap={12}
    >
      <Flex
        direction="column"
        rowGap={24}
        columnGap={12}
        verticalAlign="center"
        className={style.info}
      >
        <Flex
          className={style.dot}
          verticalAlign="center"
          horizontalAlign="center"
        >
          <MaterialIcon name={icon} size={24} variant="outlined" />
        </Flex>
        <Flex>
          <Heading variant="s" weight="bold">
            {title}
          </Heading>
          <Text variant="m" className={style.description}>
            {description}
          </Text>
        </Flex>
      </Flex>
      {!buttonComponent && buttonText && action ? (
        <Button
          kind="link"
          iconRight
          icon={buttonIcon}
          capitalize={false}
          dimension="medium"
          onClick={action}
          iconVariant="outlined"
        >
          {buttonText}
        </Button>
      ) : null}
      {buttonComponent || null}
    </Flex>
  );
};

export default EmptyState;
