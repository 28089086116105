import { WorkspaceContext } from '@/context/workspace';
import { useContext } from "react";

export const useActualWorkspace = () => {
    const { workspaceId } = useContext(WorkspaceContext);

    return {
        workspaceId
    };
};
