import { useDimensionsHandler } from '@/hooks/useDimensionsHandler';
import { useScreenWidth } from '@/hooks/useScreenWidth';
import type { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { createContext, useEffect, useMemo, useState } from "react";


export type SidebarContextType = {
  sidebarOpen: boolean,
  setSidebarOpen: Dispatch<SetStateAction<boolean>>,
  isLoadingSidebar: boolean
};

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarContext = createContext<SidebarContextType>(
  {} as SidebarContextType,
);

export const SidebarContextProvider: FC<SidebarProviderProps> = ({ children }) => {
  const { isTablet } = useDimensionsHandler();
  /* @matte questo è il motivo per cui si vedeva il default aperto iniziale anche se è mobile
   const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
   // idealmente dovrebbe essere come qui sotto, ma lancerebbe l'errore dell'hydration. per quello lo settavo nello useEffect ma c'è un attimo di delay.
   const [sidebarOpen, setSidebarOpen] = useState<boolean>(isMobile ? false : true);
   // settando il default a false evitiamo la x all'inizio e il leggero glitch della sidebar cheh si apre a default a desktop sembra accettabile
   */
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);


  const [isLoadingSidebar, setIsLoadingSidebar] = useState<boolean>(true);

  // need this useEffect to prevent hydration problems
  useEffect(() => {
    setSidebarOpen(isTablet ? false : true)
    setIsLoadingSidebar(false)
  }, [isTablet])


  const sidebarContextValue: SidebarContextType = useMemo(() => {
    return ({
      sidebarOpen,
      isLoadingSidebar,
      setSidebarOpen,
    });
  }, [
    isLoadingSidebar,
    sidebarOpen,
    setSidebarOpen
  ]);

  return (
    <SidebarContext.Provider value={sidebarContextValue}>
      {children}
    </SidebarContext.Provider>
  );
};
