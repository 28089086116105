interface CustomerIoIdentify {
  email?: string;
  id?: string | null;
}

export const customerIoIdentify = ({ id = null }: CustomerIoIdentify) => {
  const _cio = (window as any)?._cio || [];
  (window as any)._cio = _cio;

  if (!id) {
    return;
  }

  _cio?.identify({ id });
};

export const customerIoTrack = (eventData: { name: string; data: any }) => {
  const _cio = (window as any)?._cio || [];
  (window as any)._cio = _cio;

  _cio?.track(eventData.name, eventData.data);
};
