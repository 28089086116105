import type { Channel } from "@pubnub/chat";
import type { MaterialIconNames } from "@talent-garden/react-components/icons/mui-types";
import { format, getTime } from "date-fns";
import type { AddressFromGoogle, NormalizedAddress } from "../types";

/* eslint-disable no-unsafe-optional-chaining */
export function removeAtIndex<T>(arr: T[], index: number): T[] {
  const copy = [...arr];
  copy.splice(index, 1);
  return copy;
}

export function removeDouplicatesObjFromArr<T>(arr: T[]): T[] {
  return arr?.filter((value, index) => {
    const actualValue = JSON.stringify(value);
    return (
      index ===
      arr?.findIndex((obj) => {
        return JSON.stringify(obj) === actualValue;
      })
    );
  });
}

export function toggle<T>(arr: T[], item: any, getValue = (i: any) => i): T[] {
  const index = arr.findIndex((i) => getValue(i) === getValue(item));
  if (index === -1) return [...arr, item];
  return removeAtIndex(arr, index);
}

export const getDifference = (
  arr1: any[],
  arr2: any[],
  getValue = (i: any) => i,
) => {
  return arr1
    ?.filter((x) => !arr2?.some((v) => getValue(v) === getValue(x)))
    ?.concat(
      arr2?.filter((x) => !arr1?.some((v) => getValue(v) === getValue(x))),
    );
};

export const getDateTime = (
  local: string,
  dateTime: string,
  types: "full" | "day" | "month" | "year" | "time" = "full",
) => {
  const thisDateTime = new Date(dateTime);
  let formatOptions;

  switch (types) {
    case "day":
      formatOptions = { day: "numeric" };
      break;

    case "month":
      formatOptions = { month: "short" };
      break;

    case "year":
      formatOptions = { year: "numeric" };
      break;

    case "time":
      formatOptions = {
        hour: "2-digit",
        minute: "2-digit",
      };
      break;

    default:
      formatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
  }

  return new Intl.DateTimeFormat(local, formatOptions as object).format(
    thisDateTime,
  );
};

export const getDurationTime = (startDateTime: string, endDateTime: string) => {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);

  return (end.getTime() - start.getTime()) / 1000 / (60 * 60);
};

export const goBack = (path: string, levels = 1): string => {
  // remove query params
  let editedPath = path?.split("?")?.[0];

  editedPath =
    editedPath?.[editedPath?.length - 1] === "/"
      ? editedPath.slice(0, -1)
      : editedPath;
  // eslint-disable-next-line  no-unsafe-optional-chaining
  const maxLevels = editedPath?.split("/")?.length - 1;

  const editedLevels = levels > maxLevels ? maxLevels : levels;

  const finalSlashIndex = editedPath?.lastIndexOf("/");
  const finalPath = editedPath?.slice(0, finalSlashIndex);

  if (editedLevels <= 0) {
    return path;
  }

  if (editedLevels > 1) {
    return goBack(finalPath, editedLevels - 1);
  }

  return finalPath;
};

export const handlePrice = (price: number) => {
  return price?.toFixed(2).replace(".", ",");
};

export const replaceAllCustom = (
  str: string,
  target: string,
  replacement: string,
) => {
  return str?.split(target)?.join(replacement);
};

export const isBrowser = () => typeof window !== "undefined"; // The approach recommended by Next.js

export const ANIMATIONS = {
  STAGGER: (
    index: number,
    from: "top" | "bottom" | "left" | "right",
    itemToShow = 9,
  ) => {
    const actualIndex = index % itemToShow;

    return {
      initial: {
        opacity: 0,
        translateY: from === "bottom" ? -50 : from === "top" ? 50 : 0,
        translateX: from === "left" ? -50 : from === "right" ? 50 : 0,
      },
      animate: {
        opacity: 1,
        translateY: 0,
        translateX: 0,
        transition: { duration: 0.3, delay: actualIndex * 0.1 },
      },
      exit: {
        opacity: 0,
        scale: 0,
        transition: { duration: 0.3 },
      },
    };
  },
};
export const elementIsVisibleInViewport = (
  el: Element,
  partiallyVisible = false,
) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

// meglio questa che quella sotto (se usata nello stesso componente) crea valori uguali
export const getUniqueId = () => {
  return getTime(new Date()) * Math.random();
};

export const uniqueId = Math.floor(
  Math.random() * Math.floor(Math.random() * Date.now()),
);
export const areObjectsEqual = (obj1?: object, obj2?: object) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const capitalizeFirstLetter = (text: string) => {
  if (!text) {
    return undefined;
  }
  if (typeof text !== "string") {
    return undefined;
  }
  return (text?.[0] || "")?.toUpperCase() + text?.slice(1);
};

/** Breakpoints 18
 *  extra-small = 30em > 480px
 *  small = 48em > 768px
 *  medium = 60em > 960px
 *  large = 80em > 1280px
 *  extra-large = 100em > 1600px
 */
export const BREAKPOINT = {
  PHONE: 768,
  TABLET: 960,
  DESKTOP: 1280,
  WIDE: 1600,
};

export const normalizeAddress = (
  address: AddressFromGoogle,
): NormalizedAddress => {
  const addressComponent: NormalizedAddress = {
    route: "",
    street_number: "",
    state: "",
    city: "",
    country: "",
    postal_code: "",
  };

  const originalAddressComponent = address?.address_components;

  originalAddressComponent?.forEach((elem) => {
    if (elem?.types.includes("route")) {
      addressComponent.route = elem.long_name;
    }
    if (elem?.types.includes("street_number")) {
      addressComponent.street_number = elem.long_name;
    }
    if (elem?.types.includes("locality")) {
      addressComponent.city = elem.long_name;
    }
    if (elem?.types.includes("administrative_area_level_2")) {
      addressComponent.state = elem.short_name;
    }
    if (elem?.types.includes("country")) {
      addressComponent.country = elem.short_name;
    }
    if (elem?.types.includes("postal_code")) {
      addressComponent.postal_code = elem.long_name;
    }
  });

  if (!addressComponent.state) {
    const administrative1 = originalAddressComponent?.filter((el) => {
      return el?.types.includes("administrative_area_level_1");
    })?.[0];

    addressComponent.state = administrative1?.short_name as string;
  }

  return {
    route: addressComponent.route as string,
    street_number: addressComponent.street_number as string,
    postal_code: addressComponent.postal_code as string,
    city: addressComponent.city as string,
    state: addressComponent.state as string,
    country: addressComponent.country as string,
  };
};

export const MONTH_OPTIONS: { id: number; month_label: string }[] = [
  {
    id: 1,
    month_label: "January",
  },
  {
    id: 2,
    month_label: "February",
  },
  {
    id: 3,
    month_label: "March",
  },
  {
    id: 4,
    month_label: "April",
  },
  {
    id: 5,
    month_label: "May",
  },
  {
    id: 6,
    month_label: "June",
  },
  {
    id: 7,
    month_label: "July",
  },
  {
    id: 8,
    month_label: "August",
  },
  {
    id: 9,
    month_label: "September",
  },
  {
    id: 10,
    month_label: "October",
  },
  {
    id: 11,
    month_label: "November",
  },
  {
    id: 12,
    month_label: "December",
  },
];

export const YEAR_OPTIONS: number[] = Array.from(
  { length: +format(new Date(), "yyyy") - 1970 + 1 },
  (_, index) => 1970 + index,
).reverse();

// const STARTING_YEAR = 1970;

// export const YEAR_OPTIONS = [
//   ...Array(new Date().getFullYear() - STARTING_YEAR + 1).keys(),
// ].map((year) => STARTING_YEAR + year).reverse();

export interface ILanguage {
  code: string;
  name: string;
  nativeName: string;
}

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#aab7c4",
      },
      lineHeight: "30px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const sortChannels = (channels: Channel[]) => {
  return channels?.sort((a, b) => {
    const aDate = a?.custom?.lastMessageTimetoken
      ? new Date(
          Math.trunc(+Number(a?.custom?.lastMessageTimetoken) / 10000),
        ).getTime()
      : null;
    const bDate = b?.custom?.lastMessageTimetoken
      ? new Date(
          Math.trunc(+Number(b?.custom?.lastMessageTimetoken) / 10000),
        ).getTime()
      : null;

    if (!aDate || !bDate) {
      return (
        (new Date(b?.updated as string).getTime() || 0) -
        (new Date(a?.updated as string).getTime() || 0)
      );
    }

    return bDate - aDate;
  });
};

export const generateString = (length: number) =>
  Array(length)
    .fill("")
    .map(() => Math.random().toString(36).charAt(2))
    .join("");

export const generateChannelId = (users: string[]) => {
  return users
    .sort()
    .map((u) => u.slice(0, u?.length / 2))
    .join("");
};

export const createGoogleCalendarLink = ({
  eventName,
  start,
  end,
  location = "",
  details,
}: {
  eventName: string;
  start: string;
  end: string;
  location: string;
  details: string;
}) => {
  const from = start;
  const to = end;
  let bookingStartTm = replaceAllCustom(from?.toString(), "-", "");

  bookingStartTm = replaceAllCustom(bookingStartTm, ":", "");
  bookingStartTm = replaceAllCustom(bookingStartTm, '"', "");
  bookingStartTm = replaceAllCustom(bookingStartTm, "000Z", "Z");
  bookingStartTm = replaceAllCustom(bookingStartTm, ".", ""); // "20210323T143300Z"

  let bookingEndTm = replaceAllCustom(to?.toString(), "-", "");
  bookingEndTm = replaceAllCustom(bookingEndTm, ":", "");
  bookingEndTm = replaceAllCustom(bookingEndTm, '"', "");
  bookingEndTm = replaceAllCustom(bookingEndTm, "000Z", "Z");
  bookingEndTm = replaceAllCustom(bookingEndTm, ".", "");

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${bookingStartTm}/${bookingEndTm}&text=${eventName}&location=${location}&details=${details}&sprop=http://www.tuordenador.com/`
    ?.split(" ")
    ?.join("+");
};

export const formatDuration = (totalMinutes: number, t: any) => {
  // Calcola i minuti e i secondi residui
  const totalSeconds = totalMinutes * 60;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  if (hours <= 0 && minutes <= 0) {
    return t("common:secs", {
      seconds,
    });
  }

  if (hours <= 0) {
    return t("common:mins-secs", {
      minutes,
      seconds,
    });
  }

  if (minutes === 0 && seconds === 0) {
    return t("common:hours", {
      hours,
    });
  }

  if (seconds === 0) {
    return t("common:hours-mins", {
      hours,
      minutes,
    });
  }

  return t("common:hours-mins-secs", {
    hours,
    minutes,
    seconds,
  });
};

export const UNIT_TYPE_ICON: { [key: string]: MaterialIconNames } = {
  classroom_lecture: "person", // lecture , workshop, late-night
  classroom_workshop: "group", // lecture , workshop, late-night
  webinar_lecture: "account_circle", // lecture , workshop (lezione-video, workshop-video)
  webinar_workshop: "supervised_user_circle", // lecture , workshop (lezione-video, workshop-video)
  slide: "description", // upload-materiale
  video: "play_circle",
  text: "notes",
  embedded: "web_asset",

  event: "confirmation_number",
  upload_material: "cloud_upload", // se è questa è slide
  assessment: "checklist",
};

// Funzione per rimuovere i duplicati
export const removeDuplicates = (
  array: any[],
  keyExtractor = (v: any) => v,
) => {
  const uniqueArray = array.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => keyExtractor(t) === keyExtractor(obj)),
  );
  return uniqueArray;
};

export const timeSlotValidator = (slotTime: Date) => {
  const eveningTime = new Date(
    slotTime.getFullYear(),
    slotTime.getMonth(),
    slotTime.getDate(),
    23,
    0,
    0,
  );
  const startTime = new Date(
    slotTime.getFullYear(),
    slotTime.getMonth(),
    slotTime.getDate(),
    6,
    0,
    0,
  );

  const isValid =
    slotTime.getTime() <= eveningTime.getTime() &&
    slotTime.getTime() >= startTime.getTime();
  return isValid;
};

export const sanitizeString = (s: string): string => {
  /*
  Sanitize the string by:
  - Removing special characters.
  - Replacing double white spaces with single ones.
  - Converting white spaces to underscores.
  - Converting to lowercase.
  
  Parameters:
  - s (string): The input string to sanitize.
  
  Returns:
  string: Sanitized string.
  */

  if (!s) {
    return "none";
  }

  let sanitized = s;
  sanitized = sanitized.toLowerCase();
  sanitized = sanitized.replace(/[^a-z0-9 ]/g, " "); // Remove special characters, keep alphanumerics and spaces.
  sanitized = sanitized.replace(/\s+/g, " ").trim(); // Replace multiple spaces with a single space.
  sanitized = sanitized.replace(/ /g, "_"); // Replace spaces with underscores.
  return sanitized;
};

export const fromSpotifyUrlToUri = (url: string) => {
  const parsedUrl = new URL(url);
  const pathnameFormatted = parsedUrl.pathname
    .replaceAll("/", ":")
    .split(":embed")
    .pop();
  const formattedUri = `spotify${pathnameFormatted}`;
  return formattedUri;
};

export const addDoubleDigitToMonth = (date: string) => {
  return date.replace(/-(\d)-/, "-0$1-");
};

export const clearUrlFromAnchor = (url: string) => {
  return url.split("#")[0];
};

export const delQuery = (asPath: string) => {
  return asPath.split("?")[0];
};
