import {
  Button,
  Flex,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./refusedBox.module.scss";

interface RefusedBoxProps {
  joinChannel: () => void;
}

export const RefusedBox: FC<RefusedBoxProps> = ({ joinChannel }) => {
  const { t } = useTranslation("messages");

  // TODO manca host object to write the name
  return (
    <Flex
      direction="row"
      className={styles.container}
      // verticalAlign="center"
      // horizontalAlign="space-between"
      horizontalPadding={16}
      verticalPadding={16}
      rowGap={8}
      columnGap={16}
    >
      <MaterialIcon size={24} name="speaker_notes_off" variant="outlined" />

      <Flex direction="column" rowGap={8} columnGap={16}>
        <Text variant="s" weight="semibold">
          {t("messages:chat-refused-message-title")}
        </Text>
        <Text variant="s">
          {t("messages:chat-refused-message-description")}
        </Text>
        <Button
          contentAlign="start"
          capitalize={false}
          dimension="small"
          kind="link"
          type="button"
          fontWeight="semibold"
          onClick={joinChannel}
        >
          {t("messages:chat-refused-accept-cta")}
        </Button>
      </Flex>
    </Flex>
  );
};
