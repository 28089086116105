import { goBack } from "@/lib/utils";
import { Flex } from "@talent-garden/react-components";
import { useRouter } from "next/router";
import { BillingProfile } from "./billingProfile";
import { PaymentMethods } from "./paymentMethods";
import { PaymentsList } from "./paymentsList";
import styles from "./paymentsTab.module.css";

const PaymentsTab = () => {
  const { asPath, push } = useRouter();

  return (
    <Flex rowGap={16} columnGap={16} className={styles.tab}>
      <BillingProfile
        goToLearn={() => {
          push(`${goBack(asPath, 1)}/learn`);
        }}
      />
      <PaymentMethods
        goToLearn={() => {
          push(`${goBack(asPath, 1)}/learn`);
        }}
      />
      <PaymentsList />
    </Flex>
  );
};

export default PaymentsTab;
