import { isBrowser } from '@/lib/utils';
import { RefObject, useEffect, useMemo, useState } from 'react'

const useOnScreen = (ref: RefObject<HTMLElement>) => {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => {
        if (!isBrowser()) return null;

        return new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting)
        )
    }, [ref])

    useEffect(() => {
        observer?.observe(ref?.current as Element)
        return () => observer?.disconnect()
    }, [])

    return isIntersecting
}

export default useOnScreen;
