import type { CustomObject, PubnubContextType } from "@/context/pubnub";
import { PubnubContext } from "@/context/pubnub";
import { useAnalytics } from "@/hooks/useAnalytics";
import { formatTimetoken } from "@/lib/chatUtils";
import type { Message } from "@pubnub/chat";
import {
  Button,
  Flex,
  SkeletonLoader,
  Text,
} from "@talent-garden/react-components";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./chat.module.scss";
import { ChatFooterComponent } from "./chatFooterComponent";
import { ChatUserHeader } from "./chatUserHeader";
import { MessageItem } from "./messageItem";

export const Chat = () => {
  const messageDateRef = useRef<string>("");
  const messageListRef = useRef<HTMLUListElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const { setPageView } = useAnalytics();
  const { query } = useRouter();
  const { channelId } = query;

  const {
    isLoadingPubnub,
    chatPubnub,
    channelSelected,
    channelMembers,
    currentUser,
    historyMessagesPubnub,
    refreshHistory,
  } = useContext(PubnubContext) as PubnubContextType;

  useEffect(() => {
    if (!channelSelected?.id || !channelId) {
      return;
    }
    setIsLoading(channelSelected?.id !== channelId);
  }, [channelSelected?.id, channelId]);

  useEffect(() => {
    if (loadedFirstTime) {
      return;
    }
    if (!messageListRef?.current?.scrollHeight) return;

    messageListRef.current.scrollTo({
      top: messageListRef.current.scrollHeight,
      behavior: "auto",
    });

    setTimeout(() => {
      setLoadedFirstTime(true);
    }, 1000);
  }, [
    channelSelected?.id,
    messageListRef.current?.scrollHeight,
    loadedFirstTime,
  ]);

  useEffect(() => {
    if (!loadedFirstTime) {
      return;
    }
    if (!messageListRef?.current?.scrollHeight) return;

    messageListRef.current.scrollTo({
      top: messageListRef.current.scrollHeight,
      behavior: "auto",
    });
  }, [
    historyMessagesPubnub,
    messageListRef.current?.scrollHeight,
    loadedFirstTime,
  ]);

  useEffect(() => {
    setLoadedFirstTime(false);
  }, [channelSelected?.id]);

  const channelStatus = useMemo(
    () => channelSelected?.custom?.status as CustomObject["status"],
    [channelSelected?.custom?.status],
  );

  const { sender, receiver } = useMemo(() => {
    return {
      sender: channelMembers?.find(
        (member) => member?.user?.id === currentUser?.id,
      )?.user,
      receiver: channelMembers?.find(
        (member) => member?.user?.id !== currentUser?.id,
      )?.user,
    };
  }, [channelMembers, currentUser?.id]);

  useEffect(() => {
    if (!setPageView || !receiver?.id) {
      return;
    }

    setPageView({
      event_category: "community",
      event_label: "chat",
      resource: receiver?.id,
    });
  }, [setPageView, receiver]);

  if (isLoading || isLoadingPubnub || !receiver) {
    return (
      <Flex
        className={styles.skeleton}
        direction="column"
        rowGap={12}
        columnGap={12}
        verticalAlign="space-between"
      >
        <div>
          <SkeletonLoader height={100} width="100%" radius={12} />
          <SkeletonLoader height={100} width="100%" radius={12} />
          <SkeletonLoader height={100} width="100%" radius={12} />
        </div>
        <SkeletonLoader height={60} width="100%" radius={12} />
      </Flex>
    );
  }

  return (
    <Flex className={styles.container} direction="column">
      {receiver ? <ChatUserHeader receiver={receiver} /> : null}
      <Flex verticalAlign="space-between" className={styles.content}>
        {historyMessagesPubnub?.isMore ? (
          <Button
            capitalize={false}
            dimension="small"
            kind="secondary-neutral"
            onClick={async () => {
              await refreshHistory();

              messageListRef.current?.scrollTo({
                top: -100,
                behavior: "smooth",
              });
            }}
            className={styles.showMore}
          >
            Show more
          </Button>
        ) : null}
        <Flex className={styles.messagesSendFieldWrapper} verticalAlign="end">
          <Flex
            as="ul"
            className={styles.messages}
            direction="column"
            rowGap={24}
            ref={messageListRef}
            /* TODO not working */
            // verticalAlign='end'
          >
            {!!chatPubnub && historyMessagesPubnub?.messages?.length
              ? historyMessagesPubnub?.messages?.map((item: Message) => {
                  const itemDayMonthYear = item?.timetoken
                    ? formatTimetoken(item?.timetoken, "date")
                    : "";
                  const showDaySeparator =
                    messageDateRef.current !== itemDayMonthYear;
                  if (showDaySeparator) {
                    messageDateRef.current = itemDayMonthYear;
                  }
                  return (
                    <>
                      {showDaySeparator ? (
                        <Flex
                          className={styles.dateSeparator}
                          horizontalAlign="center"
                          verticalAlign="center"
                        >
                          <Text variant="xs">
                            {formatTimetoken(item.timetoken, "extended")}
                          </Text>
                        </Flex>
                      ) : null}
                      <MessageItem
                        chatPubnub={chatPubnub}
                        item={item}
                        channelStatus={channelStatus}
                        sender={sender}
                        receiver={receiver}
                      />
                    </>
                  );
                })
              : null}
          </Flex>
          <ChatFooterComponent
            channelStatus={channelStatus}
            users={{ sender, receiver }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
