import { Button, Flex, MaterialIcon } from "@talent-garden/react-components";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../../../../common/image";
import styles from "./fileItem.module.scss";
import { FILE_TYPE_ICONS_MAP } from "../../sendMessageBox/uploadFileModalContent/uploadFileModalContent";

export interface FileItemProps {
  file: {
    name: string;
    id: string;
    url: string;
    type?: string;
  };
  isSender: boolean;
}

export const imgFileTypes = ["jpeg", "jpg", "png", "tiff", "gif", "svg+xml"];
export const videoFileTypes = ["mpeg", "mp4", "quicktime", "x-msvideo", "smil"];

export const FileItem: FC<FileItemProps> = ({ file, isSender }) => {
  const { t } = useTranslation(["common"]);

  return (
    <Flex
      key={file.id}
      className={styles.container}
      direction="row"
      horizontalAlign="start"
      columnGap={8}
      data-sender={isSender}
    >
      {imgFileTypes.some(
        (imgFileType) => file?.type && file?.type.indexOf(imgFileType) >= 0,
      ) ? (
        <Image
          src={file.url}
          alt={file.name}
          width={64}
          height={64}
          className={styles.fileImage}
        />
      ) : (
        <Flex
          data-sender={isSender}
          className={styles.fileImage}
          horizontalAlign="center"
          verticalAlign="center"
        >
          <MaterialIcon
            name={
              file.type && FILE_TYPE_ICONS_MAP[file.type]
                ? FILE_TYPE_ICONS_MAP[file.type]
                : "attach_file"
            }
            size={24}
            mode={isSender ? "gray" : "light"}
          />
        </Flex>
      )}
      <Flex direction="column">
        <Flex direction="row" verticalAlign="center">
          <MaterialIcon name="attach_file" size={24} mode="gray" />
          <a href={file.url} download={file.name}>
            <Button dimension="small" kind="link" capitalize={false}>
              {file?.name?.length > 15
                ? `${file?.name.substring(0, 15)}...`
                : file?.name}
            </Button>
          </a>
        </Flex>
        <Flex direction="row" columnGap={8}>
          <a href={file.url} download={file.name}>
            <Button dimension="small" kind="link" capitalize={false}>
              {t("common:show-file")}
            </Button>
          </a>
        </Flex>
      </Flex>
    </Flex>
  );
};
