import type { MaterialIconProps } from "@talent-garden/react-components";
import {
  Flex,
  Heading,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import type { MaterialIconNames } from "@talent-garden/react-components/icons/mui-types";
import type { FC } from "react";

import style from "./dialogHeader.module.scss";

const DialogHeader: FC<{
  title: string;
  subtitle?: string;
  icon: MaterialIconNames;
  iconVariant?: MaterialIconProps["variant"];
}> = ({ title, subtitle, icon, iconVariant }) => {
  return (
    <Flex
      direction="row"
      rowGap={12}
      columnGap={12}
      className={style.container}
      verticalAlign="center"
    >
      <Flex className={style.icon}>
        <MaterialIcon name={icon} variant={iconVariant} size={24} />
      </Flex>
      <Flex rowGap={4} columnGap={4}>
        <Heading variant="xs" as="h3" weight="bold">
          {title}
        </Heading>
        {subtitle ? (
          <Text variant="s" className={style.text}>
            {subtitle}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default DialogHeader;
