/**
 * SendMessageBox component.
 *
 * This component is responsible for rendering the send message box in the chat interface.
 * It allows users to enter text messages, attach files, and send messages to the selected channel.
 *
 * @component
 * @param {object} props - The component props.
 * @param {Function} props.sendMessageToChannel - The function to send a message to the channel.
 * @param {object} props.users - The users involved in the chat.
 * @param {object} props.channelSelected - The selected channel.
 * @param {number} props.messagesLength - The length of the messages in the channel.
 * @returns {JSX.Element} - The rendered SendMessageBox component.
 */

import type { ChatMember } from "@/context/pubnub";
import type { SendTextOptionParams } from "@pubnub/chat";
import {
  Button,
  Dialog,
  Flex,
  Heading,
  MaterialIcon,
  Text,
  TextField,
} from "@talent-garden/react-components";
import { useRouter } from "next/router";
import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeetings } from "@/hooks/useMeetings";
import { useAnalytics } from "@/hooks/useAnalytics";
import { PendingBox } from "../pendingBox";
import { ScheduleMeetingBox } from "./scheduleMeetingBox";
import { UploadFileModalContent } from "./uploadFileModalContent";
import styles from "./sendMessageBox.module.scss";

interface SendMessageBoxProps {
  sendMessageToChannel: (
    text: string,
    { files, meta }: { files?: SendTextOptionParams["files"]; meta: any },
  ) => void;
  users: {
    sender: ChatMember["user"] | undefined;
    receiver: ChatMember["user"] | undefined;
  };
  channelStatus?: string;
  messagesLength: number;
  isHost: boolean;
}

export const SendMessageBox: FC<SendMessageBoxProps> = ({
  channelStatus,
  sendMessageToChannel,
  users: { sender, receiver },
  isHost,
  messagesLength = 0,
}) => {
  const { query } = useRouter();
  const { t } = useTranslation("messages");
  const [text, setText] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { captureInteraction } = useAnalytics();
  const [showSchedule, setShowSchedule] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const { meetings } = useMeetings();

  useEffect(() => {
    if (!firstLoad) {
      return;
    }
    if (!meetings?.length) {
      return;
    }

    if (!receiver || !sender) {
      return;
    }

    const checkMeetings = () => {
      const existMeeting = meetings.some((m) => {
        return (
          (m.sender_user_id === sender?.id ||
            m.sender_user_id === receiver?.id) &&
          (m.invitees.includes(sender?.id as string) ||
            m.invitees.includes(receiver?.id as string))
        );
      });
      setShowSchedule(!existMeeting);
      setFirstLoad(false);
    };

    checkMeetings();
  }, [firstLoad, receiver, sender, meetings]);

  const submitSendMessageToChannel = useCallback(
    (incomingText: string, files?: File[]) => {
      const trimmedText = incomingText?.trim();
      if (!trimmedText && !files?.length) {
        return;
      }
      sendMessageToChannel(trimmedText || "", {
        files,
        meta: {
          receiver_name: receiver?.name,
          receiver_id: receiver?.id,
          sender_id: sender?.id,
          sender_name: sender?.name,
          sender_profile_image: sender?.profileUrl,
          workspace_id: query?.id,
          type: files?.length ? "files" : "messages",
        },
      });

      setText("");
    },
    [sender, receiver],
  );

  return (
    <Flex direction="column" rowGap={16} columnGap={16} className={styles.main}>
      {showSchedule && channelStatus === "accepted" ? (
        <ScheduleMeetingBox
          sender={sender}
          receiver={receiver}
          callback={() => {
            setShowSchedule(!showSchedule);
          }}
        />
      ) : null}

      {(channelStatus === "pending" || channelStatus === "refused") &&
      isHost ? (
        <PendingBox receiver={receiver} />
      ) : null}

      <Flex
        direction="row"
        verticalAlign="center"
        className={styles.container}
        rowGap={8}
        columnGap={8}
      >
        <Button
          icon="event"
          kind="secondary-neutral"
          onClick={() => {
            setShowSchedule(!showSchedule);
          }}
          disabled={channelStatus !== "accepted"}
          style={{ borderRadius: "50%" }}
          className={styles.scheduleButton}
        />
        <Flex
          className={styles.fieldset}
          as="form"
          direction="row"
          onSubmit={(event) => {
            event.preventDefault();
            submitSendMessageToChannel(text);
          }}
        >
          <Dialog
            activator={
              <Button
                disabled={
                  channelStatus === "pending" || channelStatus === "refused"
                }
                className={styles.attachButton}
                icon="attach_file"
                iconVariant="outlined"
                iconMode="gray"
                kind="link-neutral"
                type="button"
                onClick={() => setModalOpen(true)}
              />
            }
            open={modalOpen}
            onOpenChange={() => setModalOpen(!modalOpen)}
            title={
              <Flex columnGap={16} direction="row" verticalAlign="center">
                <Flex
                  horizontalAlign="center"
                  verticalAlign="center"
                  className={styles.attachModaltitleIconWrapper}
                >
                  <MaterialIcon
                    mode="primary"
                    name="attach_file"
                    variant="round"
                    size={24}
                  />
                </Flex>
                <Flex verticalAlign="space-between" direction="column">
                  <Heading variant="xs" weight="bold">
                    {t("messages:chat-upload-modal-title")}
                  </Heading>
                  <Text variant="m">
                    {t("messages:chat-upload-modal-subtitle")}
                  </Text>
                </Flex>
              </Flex>
            }
          >
            <Flex className={styles.attachModalContent}>
              <UploadFileModalContent
                onSendFiles={(event, files) => {
                  event.preventDefault();
                  submitSendMessageToChannel(text, files);
                  captureInteraction({
                    event_category: "community",
                    event_label: "send_message",
                    resource: receiver?.id,
                    section: "messages",
                  });
                }}
                onClose={() => setModalOpen(false)}
              />
            </Flex>
          </Dialog>

          <TextField
            disabled={
              (channelStatus === "pending" || channelStatus === "refused") &&
              messagesLength >= 1
            }
            inputClassName={styles.textInput}
            className={styles.input}
            dimension="medium"
            placeholder={t("messages:send-message-placeholder") as string}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Button
            disabled={
              (channelStatus === "pending" || channelStatus === "refused") &&
              messagesLength === 1
            }
            className={styles.sendButton}
            icon="send"
            iconVariant="outlined"
            iconMode="gray"
            kind="link-neutral"
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              submitSendMessageToChannel(text);
              captureInteraction({
                event_category: "community",
                event_label: "send_message",
                resource: receiver?.id,
                section: "messages",
              });
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
