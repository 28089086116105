import * as yup from "yup";
// import { InferInterfaceFromShape, InferShape } from '../yupTypes';
import type { CompanySchema } from "./company";
import { companySchema } from "./company";
import type { CustomerSchema } from "./customer";
import { customerSchema } from "./customer";
import type { IndividualSchema } from "./individual";
import { individualSchema } from "./individual";

// type Shape = InferShape<typeof billingProfileSchema>;
// export type billingProfileSchema = InferInterfaceFromShape<Shape>;

export const billingProfileSchema = yup
  .object()
  .shape({
    isIndividual: yup.boolean().required(),
    // privacyPolicy: yup.boolean().required(),
    // TODO: add test to enable this rule only if we have coworking products in the cart
    // coworking: yup.boolean().required(),
    // saveData: yup.boolean().default(false),
    // receiveUpdates: yup.boolean().default(false),
    customer: yup
      .mixed<CustomerSchema & (IndividualSchema | CompanySchema)>()
      .when("isIndividual", ([isIndividual]) => {
        if (isIndividual) {
          return individualSchema.concat(customerSchema);
        }
        return companySchema.concat(customerSchema);
      })
      .required(),
  })
  .required();

export type BillingProfileSchema = yup.InferType<typeof billingProfileSchema>;

// type ItemType = IndividualSchema | CompanySchema;
// export function isBusinessType(
//   item: ItemType,
//   isIndividual: boolean,
// ): item is CompanySchema {
//   return !isIndividual;
// }

// export function isIndividualType(
//   item: ItemType,
//   isIndividual: boolean,
// ): item is IndividualSchema {
//   return isIndividual;
// }
