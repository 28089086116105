import { normalizeCard } from '@/lib/normalizer'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { IPaymentCard, TPaymentCard } from '../types'
import { useActualWorkspace } from './useActualWorkspace'

export const usePaymentMethods = () => {
    const queryClient = useQueryClient();
    const { workspaceId } = useActualWorkspace();

    const { data: paymentMethods, isLoading: isLoadingPaymentMethods, error: paymentMethodsError } = useQuery(['paymentMethods', workspaceId], async () => {
        return axios.get('/api/payments/payment-methods/',
            {
                params: { workspaceId }
            })
    }, { enabled: Boolean(workspaceId) })

    const isLoading = !paymentMethods?.data || isLoadingPaymentMethods

    const addPaymentMethod = useCallback(async (paymentId: string) => {
        if (!workspaceId) {
            return;
        }
        try {
            await axios.post(`/api/payments/payment-methods?workspaceId=${workspaceId}`, { "payment_method_id": paymentId })
            await queryClient.invalidateQueries(['paymentMethods', workspaceId], { exact: true });
        } catch (error: any) {
            console.error('Error: ', error?.response?.data);
            throw new Error((error?.response?.data?.details?.message) || 'Error adding payment method');
        }
    }, [workspaceId])

    const editPaymentMethod = useCallback(async (paymentId: TPaymentCard['id'], data: any) => {
        if (!workspaceId) {
            return;
        }
        try {
            await axios.patch(`/api/payments/payment-methods?workspaceId=${workspaceId}&paymentMethodId=${paymentId}`, data)
            queryClient.invalidateQueries(['paymentMethods', workspaceId], { exact: true });
        } catch (error) {
            console.error('Error: ', error);
            throw new Error('Error updating payment method');
        }
    }, [workspaceId])

    const deletePaymentMethod = useCallback(async (paymentId: string) => {
        if (!workspaceId) {
            return;
        }

        try {
            await axios.delete(`/api/payments/payment-methods?workspaceId=${workspaceId}&paymentMethodId=${paymentId}`)
            queryClient.invalidateQueries(['paymentMethods', workspaceId], { exact: true });
        } catch (error) {
            console.error('Error: ', error);
            throw new Error('Error deleting payment method');
        }
    }, [workspaceId])

    return {
        isLoading,
        error: paymentMethodsError,
        paymentsMethods: paymentMethods?.data?.data.map((card: IPaymentCard) => normalizeCard(card)),
        addPaymentMethod,
        editPaymentMethod,
        deletePaymentMethod
    }
}
