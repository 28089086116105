import { Alert } from '@talent-garden/react-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import type { FC, ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { UserContext } from './user';

export type SavedContentsType = {
    savedContents: any,
    isLoading: boolean,
    setFavourite: (data: { contentId: string }) => void,
    deleteFavourite: (data: { contentId: string }) => void,
};

interface SavedContentsProps {
    children: ReactNode;
}

export const SavedContentsContext = createContext<SavedContentsType>(
    {} as SavedContentsType,
);

export const SavedContentsProvider: FC<SavedContentsProps> = ({ children }) => {
    const { user } = useContext(UserContext)
    const queryClient = useQueryClient();

    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [successRemoveAlertOpen, setSuccessRemoveAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const { t } = useTranslation('common');

    const onAddOpenChange = useCallback(
        (open: boolean) => setSuccessAlertOpen(open),
        [],
    );
    const onRemoveOpenChange = useCallback(
        (open: boolean) => setSuccessRemoveAlertOpen(open),
        [],
    );
    const onErrorOpenChange = useCallback(
        (open: boolean) => setErrorAlertOpen(open),
        [],
    );

    const { data: savedContents, isLoading: isLoadingSavedContents } = useQuery(['contents-favourites'], async () => {
        return axios.get("/api/contents-favourites/")
    })

    const isLoading = !savedContents?.data || isLoadingSavedContents

    const setFavourite = useCallback(async (data: { contentId: string }) => {
        if (!user?._id) {
            return;
        }
        try {
            await axios.patch(`/api/contents-favourites/?userId=${user?._id}`, { _id: data?.contentId, type: "content", action: "add" })

            onAddOpenChange(true);

            queryClient.invalidateQueries(['contents-favourites'], { exact: true });
        } catch (error) {
            onErrorOpenChange(true);
            throw new Error('Error setting favorite content');
        }
    }, [user?._id])

    const deleteFavourite = useCallback(async (data: { contentId: string }) => {
        if (!user?._id) {
            return;
        }
        try {
            await axios.patch(`/api/contents-favourites/?userId=${user?._id}`, { _id: data?.contentId, type: "content", action: "remove" })
            onRemoveOpenChange(true)
            queryClient.invalidateQueries(['contents-favourites'], { exact: true });
        } catch (error) {
            console.log('Error: ', error);
            onErrorOpenChange(true);
            throw new Error('Error deleting favorite content',);
        }
    }, [user?._id])

    const savedContentsValue: SavedContentsType = useMemo(() => {
        return ({
            savedContents: savedContents?.data,
            isLoading,
            setFavourite,
            deleteFavourite,
        });
    }, [
        savedContents?.data,
        isLoading,
        setFavourite,
        deleteFavourite,
    ]);

    return (
        <SavedContentsContext.Provider value={savedContentsValue}>
            {children}
            <Alert
                isOpen={successAlertOpen}
                title={t("content-saved-success-title")}
                description={t("content-saved-success-description")}
                duration={3000}
                handleOpenChange={onAddOpenChange}
                icon="bookmark_added"
                type="success"
                withDismiss
                style={{ zIndex: 9999 }}
            />
            <Alert
                title={t("content-delete-success-title")}
                description={t("content-delete-success-description")}
                isOpen={successRemoveAlertOpen}
                handleOpenChange={onRemoveOpenChange}
                icon="bookmark_remove"
                withDismiss
                type="info"
                duration={4000}
                iconVariant="outlined"
                style={{ zIndex: 9999 }}
            />
            <Alert
                title={t("content-alert-error-title")}
                description={t("content-alert-error-description")}
                isOpen={errorAlertOpen}
                handleOpenChange={onErrorOpenChange}
                icon="error"
                withDismiss
                type="error"
                duration={4000}
                iconVariant="outlined"
                style={{ zIndex: 9999 }}
            />
        </SavedContentsContext.Provider >
    );
};
