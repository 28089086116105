import type {
  MaterialIconProps,
  TextProps,
} from "@talent-garden/react-components";
import { Flex, MaterialIcon, Text } from "@talent-garden/react-components";
import type { MaterialIconNames } from "@talent-garden/react-components/icons/mui-types";
import clsx from "clsx";
import type { FC, ReactNode } from "react";
import styles from "./iconWithText.module.scss";

interface IconWithTextProps {
  iconName: MaterialIconNames;
  iconVariant?: MaterialIconProps["variant"];
  text: string;
  textWeight?: TextProps["weight"];
  withBorder?: boolean;
  children?: ReactNode;
}

export const IconWithText: FC<IconWithTextProps> = ({
  iconName,
  text,
  iconVariant,
  textWeight,
  withBorder = false,
  children,
}) => {
  return (
    <Flex
      verticalAlign="center"
      direction="row"
      columnGap={4}
      rowGap={4}
      className={clsx(
        styles.iconWithText,
        withBorder ? styles.withBorder : null,
      )}
    >
      <MaterialIcon variant={iconVariant} name={iconName} mode="primary" />
      <Text variant="s" weight={textWeight ?? "semibold"}>
        {text}
      </Text>
      {children}
    </Flex>
  );
};
