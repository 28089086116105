import type { ChatMember } from "@/context/pubnub";
import { Flex, MaterialIcon, Text } from "@talent-garden/react-components";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./pendingBox.module.scss";

interface PendingBoxProps {
  receiver: ChatMember["user"] | undefined;
}

export const PendingBox: FC<PendingBoxProps> = ({ receiver }) => {
  const { t } = useTranslation("messages");

  return (
    <Flex
      className={styles.container}
      horizontalPadding={16}
      verticalPadding={16}
      direction="row"
      verticalAlign="center"
      columnGap={16}
    >
      <MaterialIcon size={24} name="error" />
      <Text variant="s" weight="semibold">
        {t("messages:chat-pending-box-message", {
          name: receiver?.name,
        })}
      </Text>
    </Flex>
  );
};
