import type { FC } from "react";
import React from "react";
import type { ImageProps as NextImageProps } from "next/image";
import Img from "next/image";
import type { FlexProps } from "@talent-garden/react-components";
import { Flex } from "@talent-garden/react-components";

interface ImageProps extends NextImageProps {
  verticalAlign?: FlexProps["verticalAlign"];
  horizontalAlign?: FlexProps["horizontalAlign"];
  className?: string;
}

export const Image: FC<ImageProps> = ({
  verticalAlign,
  horizontalAlign,
  className,
  ...props
}) => {
  const { src } = props;
  if (!src) return null;

  return (
    <Flex
      verticalAlign={verticalAlign}
      horizontalAlign={horizontalAlign}
      className={className}
      {...props} // need this to pass data-attributes (like data-vertical)
    >
      <Img {...props} />
    </Flex>
  );
};
