import { CustomLink } from "@/components/common";
import { useActualWorkspace } from "@/hooks/useActualWorkspace";
import { useAnalytics } from "@/hooks/useAnalytics";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import type { User } from "@pubnub/chat";
import {
  Avatar,
  Button,
  ClampText,
  Flex,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./chatUserHeader.module.scss";

interface ChatUserHeaderProps {
  receiver: User;
}

export const ChatUserHeader: FC<ChatUserHeaderProps> = ({ receiver }) => {
  const { t } = useTranslation(["common"]);
  const { workspaceId } = useActualWorkspace();
  const { isTablet } = useScreenWidth();
  const { captureInteraction } = useAnalytics();

  return (
    <Flex
      className={styles.container}
      direction="row"
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <Flex columnGap={8} verticalAlign="center" direction="row">
        {isTablet ? (
          <CustomLink href="/messages">
            <MaterialIcon name="arrow_back_ios" />
          </CustomLink>
        ) : null}
        <Avatar size="extra_small" src={receiver.profileUrl} />

        <Flex direction="column" verticalAlign="space-between">
          <ClampText rows={1}>
            <Text variant="m" weight="semibold">
              {receiver.name}
            </Text>
          </ClampText>

          {!receiver.custom?.job_role && !receiver.custom?.company ? null : (
            <ClampText rows={1}>
              <Text variant="xs" weight="regular">{`${
                receiver.custom?.job_role ? `${receiver.custom?.job_role} ` : ""
              }${
                receiver.custom?.company ? `@ ${receiver.custom?.company}` : ""
              }`}</Text>
            </ClampText>
          )}
        </Flex>
      </Flex>
      <Flex direction="row" columnGap={8} verticalAlign="center">
        <CustomLink href={`/profile/${receiver.id}?forceBack=true`}>
          <Button
            capitalize={false}
            dimension="small"
            kind="link"
            onClick={() => {
              captureInteraction({
                event_category: "community",
                event_label: "open",
                resource: receiver?.id,
                section: "messages",
              });
            }}
          >
            {t("common:view-profile")}
          </Button>
        </CustomLink>
        {/* <Dropdown
          activator={
            <Button
              icon="more_horiz"
              dimension="regular"
              kind="link-neutral"
              iconMode="gray"
            />
          }
          align="end"
          dimension="mini"
        >
          <Flex className={styles.dropdownContent}>
            <Tooltip
              kind="dark"
              activator={
                <Button
                  disabled
                  contentAlign="start"
                  icon="archive"
                  iconVariant="outlined"
                  capitalize={false}
                  dimension="small"
                  kind="link-neutral"
                  onClick={() => console.log("archive")}
                >
                  {t("messages:chat-header-archive-cta")}
                </Button>
              }
              side="top"
            >
              <Text variant="s">Coming soon!</Text>
            </Tooltip>
            <Tooltip
              kind="dark"
              activator={
                <Button
                  disabled
                  contentAlign="start"
                  icon="warning_amber"
                  iconVariant="outlined"
                  capitalize={false}
                  dimension="small"
                  kind="link"
                  onClick={() => console.log("block")}
                >
                  {t("messages:chat-header-block-cta")}
                </Button>
              }
              side="top"
            >
              <Text variant="s">Coming soon!</Text>
            </Tooltip>
          </Flex>
        </Dropdown> */}
      </Flex>
    </Flex>
  );
};
