import type { Channel } from "@pubnub/chat";
import { format, isToday } from "date-fns";

export const isTodayToken = (timetoken: string) => {
  const date = new Date(Math.trunc(+timetoken / 10000));
  return isToday(date);
};

export const formatTimetoken = (
  timetoken: string,
  type: "time" | "date" | "extended" = "time",
) => {
  const date = new Date(Math.trunc(+timetoken / 10000));
  const today = format(new Date(), "eee, dd/MM");
  const yesterday = format(
    new Date().setDate(new Date().getDate() - 1),
    "eee, dd/MM",
  );
  switch (type) {
    case "time":
      return format(date, "HH:mm");
    case "date":
      return format(date, "dd/MM/yy");
    case "extended":
      return format(date, "eee, dd/MM") === today
        ? "Today"
        : format(date, "eee, dd/MM") === yesterday
        ? "Yesterday"
        : format(date, "eee, dd/MM");
    default:
      return format(date, "HH:mm");
  }
};

export const formatDate = (
  timetoken: string,
  type: "time" | "date" | "extended" = "time",
) => {
  const date = new Date(timetoken);
  switch (type) {
    case "time":
      return format(date, "HH:mm");
    case "date":
      return format(date, "dd/MM/yy");
    case "extended":
      return format(date, "eee, dd/MM");
    default:
      return format(date, "HH:mm");
  }
};

/**
 * @name getCustomUserObj
 * @param channel the channel object you want to get the custom object from
 * @param userId the id of the user that is logged in ("owner")
 * @param type "owner" | "other" if you want to get the custom object of the owner or the other user
 * @returns the parsed custom object of the user you want to get
 */
export const getCustomUserObj = (
  channel: Channel,
  userId: string,
  type: "owner" | "other",
) => {
  const key = Object.keys(channel?.custom || {})
    .filter((k) => k?.includes("user."))
    .find((k) =>
      type === "owner" ? k?.includes(userId) : !k?.includes(userId),
    );
  return !key || !channel?.custom?.[key]
    ? {}
    : JSON.parse(String(channel?.custom?.[key]));
};
