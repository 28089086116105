import { CardDetails } from "@/components/common/cardDetails";
import type { TPaymentCard } from "@/types/types";
import {
  Button,
  Dropdown,
  Flex,
  Separator,
} from "@talent-garden/react-components";
import { useState, type FC } from "react";

import { useTranslation } from "next-i18next";
import { DeleteCardDialog } from "./deleteCardDialog";
import styles from "./editCardDetails.module.scss";
import { SetDefaultCardDialog } from "./setDefaultCardDialog";

const EditCardDetails: FC<{
  isEditEnabled: boolean;
  card: TPaymentCard;
  showSuccessAlert: () => void;
  showErrorAlert: () => void;
  disabled?: boolean;
}> = ({
  isEditEnabled,
  card,
  showSuccessAlert,
  showErrorAlert,
  disabled = false,
}) => {
  const { t } = useTranslation("payments");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Flex
      verticalAlign="center"
      horizontalAlign="space-between"
      direction="row"
      className={styles.container}
    >
      <CardDetails paymentCard={card} key={card?.id} />
      <Dropdown
        onOpenChange={(open: boolean) => {
          setDropdownOpen(open);
        }}
        open={dropdownOpen}
        activator={
          <Button
            capitalize={false}
            dimension="small"
            icon="more_horiz"
            iconRight
            kind="link-neutral"
            disabled={disabled}
          >
            {t("options-btn")}
          </Button>
        }
        dimension="mini"
        className={styles.dropdown}
        sideOffset={0}
      >
        <Flex
          rowGap={12}
          columnGap={12}
          horizontalAlign="start"
          className={styles.content}
        >
          <SetDefaultCardDialog
            style={styles}
            card={card}
            isEditEnabled={isEditEnabled}
            closeDropdown={() => setDropdownOpen(false)}
            showSuccessAlert={showSuccessAlert}
            showErrorAlert={showErrorAlert}
          />
          <Separator />
          <DeleteCardDialog
            card={card}
            isEditEnabled={isEditEnabled}
            callback={() => setDropdownOpen(false)}
          />
        </Flex>
      </Dropdown>
    </Flex>
  );
};

export default EditCardDetails;
