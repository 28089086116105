import { DefaultSeoProps } from 'next-seo';

// const defaultKeywords = [
//     "talent garden",
//     "platform",
//     "sphere",
//     "education",
//     "tag",
//     "talent",
//     "garden",
//     "master",
//     "community"
// ]

const config: DefaultSeoProps = {
    dangerouslySetAllPagesToNoIndex: process.env.ENVIRONMENT !== "production",
    dangerouslySetAllPagesToNoFollow: process.env.ENVIRONMENT !== "production",
    titleTemplate: '%s | Sphere by Talent Garden and Hyper Island',
    defaultTitle: 'The all-in-one hub platform for supercharging your professional growth | Sphere by Talent Garden and Hyper Island',
    description: 'Sphere is the all-in-one hub platform by Talent Garden and Hyper Island that combines all you need to thrive as a professional: a vibrant community, on-demand courses, mentorship, events, discounts, and job opportunities',
    openGraph: {
        url: "https://sphere.talentgarden.com/authorize",
        title: "The all-in-one hub platform for supercharging your professional growth | Sphere by Talent Garden and Hyper Island",
        description: "Sphere is the all-in-one hub platform by Talent Garden and Hyper Island that combines all you need to thrive as a professional: a vibrant community, on-demand courses, mentorship, events, discounts, and job opportunities",
        type: 'website',
        images: [
            {
                url: process.env.ENVIRONMENT === "production" ? "https://sphere.talentgarden.com/images/social_image.jpg" : "https://sphere.talentgarden.dev/images/social_image.jpg",
                width: 1500,
                height: 1000,
                alt: "The all-in-one hub platform for supercharging your professional growth | Sphere by Talent Garden and Hyper Island",
                type: "image/jpg",
            },
        ],
        siteName: "Sphere by Talent Garden and Hyper Island",
    }
};

export default config;
