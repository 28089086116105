import { PubnubContext, type ChatMember } from "@/context/pubnub";
import { useAnalytics } from "@/hooks/useAnalytics";
import { useMeetings } from "@/hooks/useMeetings";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import {
  Avatar,
  Button,
  Flex,
  Heading,
  Separator,
  Text,
  TimeSlot,
} from "@talent-garden/react-components";
import { addMinutes, format } from "date-fns";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import loaderAnimation from "../../../../../public/lotties/spinner_loader.json";

import styles from "./scheduleMeetingBox.module.scss";

const LOTTIE_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface ScheduleMeetingBoxProps {
  sender: ChatMember["user"] | undefined;
  receiver: ChatMember["user"] | undefined;
  callback?: () => void;
}

export const ScheduleMeetingBox: FC<ScheduleMeetingBoxProps> = ({
  sender,
  receiver,
  callback,
}) => {
  const { query } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(["common", "messages"]);
  const [requestedDate, setRequestedDate] = useState<Date | null>(null);
  const [timeSlotOpen, setTimeSlotOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNowSelected, setIsNowSelected] = useState(false);
  const { isMobile } = useScreenWidth();
  const { captureInteraction } = useAnalytics();

  const { createMeeting } = useMeetings();

  const onSendRequest = useCallback(async () => {
    if (!query?.channelId || !requestedDate || !sender || !receiver) {
      return;
    }

    let requestDate = new Date(requestedDate);

    if (isNowSelected) {
      requestDate = addMinutes(new Date(), 1);
    }

    setIsLoading(true);

    const data = {
      invitees: [receiver?.id],
      channel_id: query?.channelId,
      from: requestDate.toISOString(),
      to: addMinutes(requestDate, 30).toISOString(),
    };
    try {
      const response = await createMeeting(data);
      captureInteraction({
        event_category: "community",
        event_label: "send_meeting_request",
        section: "messages",
        resource: receiver?.id,
        meeting_id: response?.insertedId,
      });
    } catch (err) {
      console.log("Err: ", err);
    } finally {
      setIsOpen(false);
      setIsLoading(false);
      callback?.();
    }
  }, [sender, receiver, requestedDate, query?.channelId, isNowSelected]);

  return (
    <Flex
      direction="column"
      className={styles.container}
      horizontalPadding={12}
      verticalPadding={12}
      rowGap={8}
      columnGap={8}
      verticalAlign="center"
    >
      <Flex
        direction="column"
        verticalAlign="center"
        horizontalAlign="space-between"
        className={styles.titleBtnWrapper}
        rowGap={16}
        columnGap={16}
      >
        <Flex
          direction="row"
          verticalAlign="center"
          columnGap={16}
          rowGap={16}
          className={styles.title}
          data-padding={isOpen}
        >
          <Flex direction="row" className={styles.avatarsWrapper}>
            {[receiver?.profileUrl, sender?.profileUrl]
              .filter(Boolean)
              ?.map((url) => <Avatar key={url} src={url} size="extra_small" />)}
          </Flex>
          <Text variant="s" weight="bold">
            {t("messages:chat-schedule-text", {
              name: receiver?.custom?.first_name,
            })}
          </Text>
        </Flex>
        {isOpen ? (
          <Button
            capitalize={false}
            dimension="small"
            type="button"
            fontWeight="semibold"
            onClick={() => {
              setIsOpen(false);
              callback?.();
            }}
            disabled={isLoading}
            icon="close"
            iconRight
            kind="secondary"
            className={styles.close}
          >
            {!isMobile ? t("common:close") : null}
          </Button>
        ) : (
          <Button
            capitalize={false}
            dimension="small"
            kind="primary"
            type="button"
            fontWeight="semibold"
            onClick={() => setIsOpen(true)}
          >
            {t("messages:chat-schedule-btn-label")}
          </Button>
        )}
      </Flex>
      {isOpen ? (
        <>
          <Separator />
          <Heading variant="xs" weight="bold">
            {t("messages:chat-schedule-title")}
          </Heading>
          <Text variant="s" weight="regular">
            {t("messages:chat-schedule-description")}
          </Text>
          {!isLoading ? (
            <Flex
              className={styles.datePickerBtnsWrapper}
              direction="column"
              columnGap={16}
              rowGap={16}
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Flex direction="row" verticalAlign="center">
                <Button
                  dimension="regular"
                  fontWeight="semibold"
                  kind={isNowSelected ? "secondary" : "secondary-neutral"}
                  capitalize={false}
                  onClick={() => {
                    setIsNowSelected(true);
                    setRequestedDate(new Date());
                  }}
                >
                  {t("messages:chat-schedule-now")}
                </Button>
                <TimeSlot
                  open={timeSlotOpen}
                  activator={
                    <Button
                      dimension="regular"
                      fontWeight="semibold"
                      kind="secondary-neutral"
                      capitalize={false}
                      icon="calendar_today"
                      iconMode="primary"
                    >
                      {requestedDate && !isNowSelected
                        ? format(requestedDate, "dd MMMM yyyy, HH:mm")
                        : "Later, on a date..."}
                    </Button>
                  }
                  onConfirm={(date: Date) => {
                    setRequestedDate(date);
                    setTimeSlotOpen(false);
                  }}
                  onOpenChange={(open: boolean) => {
                    setRequestedDate(null);
                    setIsNowSelected(false);
                    setTimeSlotOpen(open);
                  }}
                  timeSlotValidator={() => {
                    return true;
                  }}
                />
              </Flex>
              <Button
                dimension="small"
                fontWeight="semibold"
                kind="primary"
                capitalize={false}
                onClick={onSendRequest}
                disabled={!requestedDate}
              >
                {t("messages:chat-schedule-send-request")}
              </Button>
            </Flex>
          ) : (
            <Lottie options={LOTTIE_OPTIONS} height={80} width={80} />
          )}
        </>
      ) : null}
    </Flex>
  );
};
