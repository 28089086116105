import * as yup from "yup";
import { EuropeCountries } from "@talent-garden/react-components";
import { validateVat } from "../vat";

export const companySchema = yup
  .object()
  .shape(
    {
      country: yup
        .string()
        .required("missing-country")
        .typeError("select-country"),
      companyName: yup.string().required("missing-company-name"),
      vat: yup
        .string()
        .test("country-vat-match", "vat-country-match", function (value) {
          if (!this.parent) {
            return true;
          }
          const { country = null, vat = null } = this.parent;

          if (!!country && !!vat) {
            return vat?.toLowerCase()?.slice(0, 2) === country?.toLowerCase();
          }
          return true;
        })
        .when("country", {
          is: (country: string) => country === "IT",
          then: (
            schema, // <---- notice here return function
          ) =>
            schema
              .required("missing-vat")
              .typeError("invalid-vat")
              .test("validVat", "invalid-vat", async (value) => {
                try {
                  if (!value) return true;
                  const { data: validatedData } = await validateVat(value);
                  return validatedData?.format_valid;
                } catch (err) {
                  return false;
                }
              }),
          otherwise: () =>
            yup.string().when("country", {
              is: (country: string) => EuropeCountries?.includes(country),
              then: (
                schema, // <---- notice here return function
              ) =>
                schema
                  .required("missing-vat")
                  .typeError("invalid-vat")
                  .test("validVat", "invalid-vat", async (value) => {
                    try {
                      if (!value) return true;
                      const { data: validatedData } = await validateVat(value);
                      return validatedData?.valid;
                    } catch (err) {
                      return false;
                    }
                  }),
            }),
        }),
      sdi: yup
        .string()
        .test("validSdi", "sdi-min-characters", async (value) => {
          try {
            if (!value) return true;
            return value?.length >= 6;
          } catch (err) {
            return false;
          }
        })
        .test("validSdi", "sdi-max-characters", async (value) => {
          try {
            if (!value) return true;
            return value?.length <= 10;
          } catch (err) {
            return false;
          }
        }),
      pecEmail: yup
        .string()
        .email("valid-email")
        .when(["sdi", "country"], {
          is: (sdi: string, country: string) => !sdi && country === "IT",
          then: (schema) =>
            schema.required("missing-pec").typeError("invalid-pec"),
        }),
      formattedAddress: yup.string().required("missing-address"),
      firstName: yup.string().required("required-first"),
      lastName: yup.string().required("required-last"),
    },
    [["pec", "sdi"]],
  )
  .required();
export type CompanySchema = yup.InferType<typeof companySchema>;
