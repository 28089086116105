import type { CustomChannel } from "@/context/pubnub";
import {
  formatDate,
  formatTimetoken,
  getCustomUserObj,
  isTodayToken,
} from "@/lib/chatUtils";
import { goBack } from "@/lib/utils";
import {
  Avatar,
  ClampText,
  Flex,
  Separator,
  Text,
} from "@talent-garden/react-components";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo, type FC } from "react";
import styles from "./channelItem.module.scss";

interface ChannelItemProps {
  channelInfo: CustomChannel;
  isLast: boolean;
  userId: string;
}

export const ChannelItem: FC<ChannelItemProps> = ({
  channelInfo,
  isLast,
  userId,
}) => {
  const { asPath, pathname } = useRouter();

  const customs = useMemo(
    () => getCustomUserObj(channelInfo, userId, "other"),
    [channelInfo, userId],
  );

  const lastMessageTime = channelInfo?.custom?.lastMessageTimetoken
    ? isTodayToken(String(channelInfo?.custom?.lastMessageTimetoken))
      ? formatTimetoken(
          String(channelInfo?.custom?.lastMessageTimetoken),
          "time",
        )
      : formatTimetoken(
          String(channelInfo?.custom?.lastMessageTimetoken),
          "date",
        )
    : formatDate(String(new Date(channelInfo?.updated || 0)), "date");

  return (
    <>
      <Link
        key={channelInfo?.id}
        href={
          !pathname?.includes("channelId")
            ? `${goBack(asPath, 1)}/messages/${channelInfo?.id}`
            : `${goBack(asPath, 1)}/${channelInfo?.id}`
        }
      >
        <Flex direction="column">
          <Flex
            className={styles.container}
            columnGap={12}
            direction="row"
            horizontalPadding={8}
            verticalPadding={8}
            horizontalAlign="center"
            verticalAlign="center"
            data-active={asPath?.includes(channelInfo?.id)}
            data-status={channelInfo?.custom}
          >
            <Avatar
              isPressable
              size="extra_small"
              className={styles.avatar}
              src={customs?.channelPicture}
            />
            <Flex direction="column" className={styles.rightCol} rowGap={8}>
              <Flex direction="row" grow horizontalAlign="space-between">
                {customs?.channelName ? (
                  // TODO: allow clamp text with long monostring text
                  <ClampText rows={1}>
                    <Text variant="s" weight="semibold">
                      {customs?.channelName}
                    </Text>
                  </ClampText>
                ) : null}
                <Text textAlign="end" variant="xs" className={styles.time}>
                  {lastMessageTime ?? ""}
                </Text>
              </Flex>
              {channelInfo?.custom?.lastMessage ||
              channelInfo?.unreadMessages ? (
                <Flex
                  direction="row"
                  horizontalAlign="space-between"
                  verticalAlign="center"
                >
                  <ClampText rows={1}>
                    <Text variant="xs">
                      {channelInfo?.custom?.lastMessage ?? ""}
                    </Text>
                  </ClampText>
                  {channelInfo?.unreadMessages ? (
                    <Flex
                      className={styles.unreadMessages}
                      verticalAlign="center"
                      horizontalAlign="center"
                    >
                      <Text variant="xs" weight="semibold">
                        {channelInfo?.unreadMessages}
                      </Text>
                    </Flex>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </Link>
      {isLast ? null : <Separator />}
    </>
  );
};
