import { fetchClient } from "./fetchClient";

type VatLayerData = {
  valid: false;
  database: string;
  format_valid: boolean;
  query: string;
  country_code: string;
  vat_number: string;
  company_name: string;
  company_address: string;
};

export const validateVat = async (vat: string) => {
  const { data } = await fetchClient.get<{ data: VatLayerData }>(
    `/api/validation/vat?value=${vat}`,
  );

  return data;
};
