import type { PubnubContextType } from "@/context/pubnub";
import { PubnubContext } from "@/context/pubnub";
import { UserContext } from "@/context/user";
import { useActualWorkspace } from "@/hooks/useActualWorkspace";
import { getCustomUserObj } from "@/lib/chatUtils";
import { removeDuplicates, sortChannels } from "@/lib/utils";
import type { Channel } from "@pubnub/chat";
import {
  Badge,
  Button,
  Flex,
  Heading,
  MaterialIcon,
  Separator,
  SkeletonLoader,
  Text,
  TextField,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useContext, useMemo } from "react";
import { ChannelItem } from "./channelItem";
import styles from "./channels.module.scss";

export const Channels = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation(["common", "messages"]);
  const { workspaceId } = useActualWorkspace();

  const { userChannelsPubnub, isLoadingPubnub, isHost } = useContext(
    PubnubContext,
  ) as PubnubContextType;
  const [searchText, setSearchText] = React.useState("");

  const { pendingChannels, acceptedChannels, refusedChannels } = useMemo((): {
    pendingChannels: Channel[];
    acceptedChannels: Channel[];
    refusedChannels: Channel[];
  } => {
    if (!userChannelsPubnub?.length || !user)
      return {
        pendingChannels: [],
        acceptedChannels: [],
        refusedChannels: [],
      };
    const filteredChannels = !searchText?.length
      ? userChannelsPubnub
      : userChannelsPubnub.filter((channel) => {
          const customs = getCustomUserObj(channel, user._id, "other");
          return customs?.channelName
            ?.toLowerCase()
            .includes(searchText.toLowerCase());
        });

    return {
      pendingChannels: removeDuplicates(
        sortChannels(
          filteredChannels.filter(
            (channel) =>
              channel.custom?.status === "pending" && !isHost(channel),
          ),
        ),
        (channel) => channel,
      ),
      acceptedChannels: removeDuplicates(
        sortChannels(
          filteredChannels.filter(
            (channel) =>
              channel.custom?.status === "accepted" ||
              (channel.custom?.status === "refused" && isHost(channel)) ||
              (channel.custom?.status === "pending" && isHost(channel)),
          ),
        ),
        (channel) => channel,
      ),
      refusedChannels: removeDuplicates(
        sortChannels(
          filteredChannels.filter(
            (channel) =>
              channel.custom?.status === "refused" && !isHost(channel),
          ),
        ),
        (channel) => channel,
      ),
    };
  }, [userChannelsPubnub, searchText, user]);

  return (
    <Flex
      verticalAlign="start"
      horizontalPadding={24}
      verticalPadding={24}
      rowGap={16}
      className={styles.container}
      direction="column"
    >
      {isLoadingPubnub ? (
        <>
          <SkeletonLoader height={44} width="100%" radius={12} />
          <div />
          <SkeletonLoader height={64} width="100%" radius={12} />
          <Separator />
          <SkeletonLoader height={64} width="100%" radius={12} />
          <Separator />
          <SkeletonLoader height={64} width="100%" radius={12} />
          <Separator />
          <SkeletonLoader height={64} width="100%" radius={12} />
        </>
      ) : !!user && userChannelsPubnub?.length ? (
        <>
          <TextField
            icon="search"
            dimension="medium"
            placeholder={t("common:search") as string}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {pendingChannels?.length > 0 ? (
            <Flex
              className={styles.pendingChannelsWrapper}
              horizontalPadding={8}
              verticalPadding={8}
              rowGap={16}
            >
              <Flex
                direction="row"
                horizontalAlign="space-between"
                verticalAlign="center"
                className={styles.channelsRequestTitle}
              >
                <Text variant="s" weight="semibold">
                  {t("messages:channel-request-label")}
                </Text>
                <Badge kind="tertiary" outline dimension="small">
                  {
                    t("messages:channel-request-count", {
                      number: pendingChannels?.length,
                    }) as string
                  }
                </Badge>
              </Flex>
              <Flex rowGap={12} columnGap={12}>
                {pendingChannels.map((item, i) => {
                  const isLast = (pendingChannels?.length || 0) - 1 === i;
                  return (
                    <ChannelItem
                      key={item?.id}
                      channelInfo={item}
                      isLast={isLast}
                      userId={user._id}
                    />
                  );
                })}
              </Flex>
            </Flex>
          ) : null}

          <Flex
            direction="row"
            horizontalAlign="space-between"
            verticalAlign="center"
            className={styles.totFilteredChannels}
            horizontalPadding={12}
            verticalPadding={12}
          >
            <Text variant="s" weight="bold">
              {t("messages:tot-channels-label")}
            </Text>
            <Text variant="s" weight="regular">
              {t("messages:tot-channels-count", {
                number: acceptedChannels?.length || 0,
              })}
            </Text>
          </Flex>

          <Flex rowGap={12} columnGap={12}>
            {acceptedChannels.map((item, i) => {
              const isLast = (acceptedChannels?.length || 0) - 1 === i;
              return (
                <ChannelItem
                  key={item?.id}
                  channelInfo={item}
                  isLast={isLast}
                  userId={user._id}
                />
              );
            })}
          </Flex>
          {refusedChannels?.length > 0 ? (
            <Flex
              className={styles.refusedChannelsWrapper}
              horizontalPadding={8}
              verticalPadding={8}
              rowGap={16}
            >
              <Flex
                direction="row"
                horizontalAlign="space-between"
                verticalAlign="center"
                className={styles.channelsRefusedTitle}
              >
                <Text variant="s" weight="semibold">
                  {t("messages:channel-refused-label")}
                </Text>
                <Badge kind="neutral" outline dimension="small">
                  {
                    t("messages:channel-refused-count", {
                      number: refusedChannels?.length,
                    }) as string
                  }
                </Badge>
              </Flex>
              <Flex rowGap={12} columnGap={12}>
                {refusedChannels.map((item, i) => {
                  const isLast = (refusedChannels?.length || 0) - 1 === i;
                  return (
                    <ChannelItem
                      key={item?.id}
                      channelInfo={item}
                      isLast={isLast}
                      userId={user._id}
                    />
                  );
                })}
              </Flex>
            </Flex>
          ) : null}
        </>
      ) : (
        <Flex
          verticalAlign="center"
          horizontalAlign="center"
          direction="column"
          rowGap={12}
          className={styles.noChannelsWrapper}
        >
          <Flex
            horizontalAlign="center"
            verticalAlign="center"
            horizontalPadding={24}
            verticalPadding={24}
            className={styles.inboxIconWrapper}
          >
            <MaterialIcon name="inbox" size={24} />
          </Flex>
          <Heading variant="xs" weight="bold">
            {t("messages:no-channels-placeholder-title")}
          </Heading>
          <Text textAlign="center" variant="m" weight="regular">
            {t("messages:no-channels-placeholder-text")}
          </Text>

          <Link href="/community">
            <Button
              capitalize={false}
              kind="secondary"
              dimension="regular"
              fontWeight="semibold"
            >
              {t("messages:no-channels-btn-label")}
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  );
};
