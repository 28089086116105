import { Header } from "@/components/header";
import { Sidebar } from "@/components/sidebar";
import { SavedContentsProvider } from "@/context/savedContents";
import { SidebarContext } from "@/context/sidebar";
import { Flex } from "@talent-garden/react-components";
import type { CSSProperties, FC, ReactNode } from "react";
import { useContext } from "react";
import styles from "./layout.module.scss";

interface LayoutProps {
  children: ReactNode;
  headerTitle: string | ReactNode;
  bgColor?: "white" | "grey";
  footer?: ReactNode;
  noPadding?: boolean;
  fullWidth?: boolean;
  overflow?: "hidden" | "auto";
  showSidebar?: boolean;
  showHeader?: boolean;
}

export const Layout: FC<LayoutProps> = ({
  children,
  headerTitle,
  footer,
  bgColor,
  noPadding,
  fullWidth,
  overflow,
  showSidebar = true,
  showHeader = true,
}) => {
  const { sidebarOpen } = useContext(SidebarContext);

  // necessary to fix automatically (where possible) all the layout changes with new sidebar pushing content
  const setSidebarWidth: CSSProperties | null = sidebarOpen
    ? ({ "--nav-width": "var(--open-nav-width)" } as CSSProperties)
    : null;

  const resetHeaderHeight: CSSProperties | null = !showSidebar
    ? ({ "--header-height": 0 } as CSSProperties)
    : null;
  const resetSidebarWidth: CSSProperties | null = !showSidebar
    ? ({ "--nav-width": 0 } as CSSProperties)
    : null;

  return (
    <Flex
      direction="row"
      horizontalAlign="start"
      className={styles.container}
      style={{
        ...setSidebarWidth,
        ...resetHeaderHeight,
        ...resetSidebarWidth,
      }}
    >
      <SavedContentsProvider>
        {showSidebar ? <Sidebar /> : null}
        {showHeader ? <Header headerTitle={headerTitle} /> : null}
        <Flex
          direction="column"
          horizontalAlign="start"
          className={styles.page}
          data-bg-color={bgColor}
          data-sidebar-open={sidebarOpen}
        >
          <Flex
            id="main"
            as="main"
            direction="column"
            className={styles.content}
            data-no-padding={noPadding}
            data-full-width={fullWidth}
            data-overflow={overflow}
            data-sidebar-open={sidebarOpen}
          >
            {children}
          </Flex>

          {footer ? (
            <Flex as="footer" className={styles.footer}>
              {footer}
            </Flex>
          ) : null}
        </Flex>
      </SavedContentsProvider>
    </Flex>
  );
};
