export const tagApiRoutes = {
  user: () => "v2/users/me",
  patchCommunications: (userId: string) => `v2/users/${userId}/communications`,
  people: (query: string) => `v2/community?${query}`,
  searchUsers: (query: string) => `v2/community/search?${query}`,
  favourites: (userId: string) => `v2/users/${userId}/preferences`,
  eventsFavourites: () => `v2/events/preferences`,
  userRoles: () => "v1/users/me/roles",
  userById: (id: string) => `v2/users/${id}`,
  changePassword: (id: string) => `v2/users/${id}/change-password `,
  confirmPassword: (id: string) => `v2/users/${id}/confirm-password `,
  profileByUserId: (id: string) => `v2/users/${id}/profile`,
  workspaces: () => "v2/workspaces",
  workspace: (id: string) => `v2/workspaces/${id}`,
  subscription: (id: string) => `v2/workspaces/${id}/subscriptions`,
  billingProfile: (id: string) => `v2/workspaces/${id}/billing_profiles`,
  paymentMethods: (id: string) => `v2/workspaces/${id}/payment_methods`,
  paymentsList: (id: string) => `v2/workspaces/${id}/payments`,
  invoicesList: (id: string) => `v2/workspaces/${id}/invoices`,
  paymentMethodById: (id: string, paymentMethodId: string) =>
    `v2/workspaces/${id}/payment_methods/${paymentMethodId}`,
  plan: (id: string) => `v2/workspaces/${id}/plan`,
  members: (id: string, subId: string, page: string, pageSize: string) =>
    `v2/workspaces/${id}/subscriptions/${subId}/members?page=${page}&per_page=${pageSize}`,
  editMembers: (id: string, subId: string) =>
    `v2/workspaces/${id}/subscriptions/${subId}/members`,
  patchSingleMember: (id: string, subId: string, userId: string) =>
    `v2/workspaces/${id}/subscriptions/${subId}/members/${userId}`,
  upcomingInvoice: (id: string) => `v2/workspaces/${id}/upcoming_invoice`,
  invoicePreview: (id: string) => `v2/workspaces/${id}/preview`,
  roles: () => "v1/users/me/roles",
  skillsCategories: () => "v2/categories",
  skills: () => "v2/skills",
  availabilities: () => "v2/availabilities",
  interests: () => "v2/interests",
  needs: () => "v2/needs",
  campuses: () => "v2/campuses",
  courses: (query: string) => `v2/courses?${query}`,
  toolboxes: () => "v2/toolboxes",
  toolboxesCategories: () => "v2/toolboxes/categories",
  meetings: () => "v2/meetings",
  editMeeting: (id: string) => `v2/meetings/${id}`,
  events: (query: string) => `v2/events?${query}`,
  termsOfService: () => "v2/agreements/tos",
  termsAndConditions: () => "v2/agreements/tac",
  usersTermsOfService: (id: string) => `v2/users/${id}/agreements`,
  usersTermsAndConditions: (id: string) => `v2/users/${id}/agreements`,
  jobs: (page: string, perPage: string) =>
    `v2/offers?status=publish&page=${page}&per_page=${perPage}`,
  stripeProducts: (page: string, perPage: string) =>
    `v2/products?page=${page}&per_page=${perPage}`,
  learningPaths: () => "v2/paths",
  pathDetails: (pathId: string) => `v2/paths/${pathId}`,
  pathResources: (pathId: string) => `v2/paths/${pathId}/resources`,
  pathFaculty: (pathId: string) => `v2/paths/${pathId}/faculty`,
  pathStudents: (pathId: string) => `v2/paths/${pathId}/students`,
  moduleDetails: (pathId: string, moduleId: string) =>
    `v2/paths/${pathId}/modules/${moduleId}`,
  unitsJourney: (pathId: string) => `v2/paths/${pathId}/journey`,
  invoicePaid: (id: string, invoiceId: string) =>
    `v2/workspaces/${id}/invoices/${invoiceId}/paid`,
  invoiceFinalize: (id: string, invoiceId: string) =>
    `v2/workspaces/${id}/invoices/${invoiceId}/finalize`,
  contents: (query?: string) => `v2/contents?${query}`,
  contentDetails: (contentId: string) => `v2/contents/${contentId}`,
  playlists: (query?: string) => `v2/playlists?${query}`,
  playlistDetails: (contentId: string) => `v2/playlists/${contentId}`,
  contentsFavourites: () => `v2/contents/preferences`,
};
