import { BREAKPOINT } from "@/lib/utils";
import { useState, useEffect } from "react";

export const useScreenWidth = () => {
  const [windowWidth, setWindowWidth] = useState<number>(0);

  const isWindow = typeof window !== "undefined";

  const getWidth = () => (isWindow ? window.innerWidth : windowWidth);

  const resize = () => setWindowWidth(getWidth());

  useEffect(() => {
    if (isWindow) {
      setWindowWidth(getWidth());

      window.addEventListener("resize", resize);

      return () => window.removeEventListener("resize", resize);
    }
  }, [isWindow]);
  
  const isMobile = windowWidth <= BREAKPOINT.PHONE;
  const isTablet = windowWidth <= BREAKPOINT.TABLET;
  const isDesktop = windowWidth <= BREAKPOINT.DESKTOP;

  return {
    windowWidth,
    isMobile,
    isTablet,
    isDesktop
  };
}
