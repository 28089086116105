import type { SelectProps } from "@talent-garden/react-components";
import { Countries, Select } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useUIDSeed } from "react-uid";

export interface BillingTextFieldProps extends SelectProps {
  name: string;
  uppercase?: boolean;
  fullWidth?: boolean;
  customInputArea?: string;
}

const SelectCountries: FC<BillingTextFieldProps> = ({
  label,
  placeholder,
  name,
  ...props
}) => {
  const { t } = useTranslation("inputs");

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const seed = useUIDSeed();

  return (
    <Select
      label={label}
      placeholder={placeholder}
      errorMessage={
        (errors?.customer as any)?.[name] // TODO: @matte tipizzare
          ? (t(
              `inputs:${(errors?.customer as any)?.[name]?.message}`, // TODO: @matte tipizzare
            ) as string)
          : undefined
      }
      {...register(`customer.${name}`)}
      {...props}
    >
      <option value="" defaultValue="">
        {t("select-country")}
      </option>
      {Countries.map((country) => (
        <option key={seed(country.code)} value={country.code}>
          {country.name}
        </option>
      ))}
    </Select>
  );
};

export default SelectCountries;
