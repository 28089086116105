/* eslint-disable no-restricted-syntax */
import Cookies from "cookies";
import { de, enGB, es, it } from "date-fns/locale";
import type { GetServerSideProps, GetServerSidePropsContext } from "next";

export const findClosestLocale = (
  aLocales: string[],
  aMatchLocales: string[],
) => {
  // aMatchLocales = aMatchLocales;

  // Holds the best matching localized resource
  let bestmatch = null;
  // The number of locale parts it matched with
  let bestmatchcount = 0;
  // The number of locale parts in the match
  let bestpartcount = 0;

  for (const locale of aMatchLocales) {
    const lparts = locale?.split("-");
    for (const localized of aLocales) {
      const found = localized.toLowerCase();
      // Exact match is returned immediately
      if (locale === found) return localized;

      const fparts = found.split("-");
      /* If we have found a possible match and this one isn't any longer
               then we dont need to check further. */
      // eslint-disable-next-line no-continue
      if (bestmatch && fparts?.length < bestmatchcount) continue;

      // Count the number of parts that match
      const maxmatchcount = Math.min(fparts?.length, lparts?.length);
      let matchcount = 0;
      while (
        matchcount < maxmatchcount &&
        fparts[matchcount] === lparts[matchcount]
      )
        // eslint-disable-next-line no-plusplus
        matchcount++;

      /* If we matched more than the last best match or matched the same and
               this locale is less specific than the last best match. */
      if (
        matchcount > bestmatchcount ||
        (matchcount === bestmatchcount && fparts?.length < bestpartcount)
      ) {
        bestmatch = localized;
        bestmatchcount = matchcount;
        bestpartcount = fparts?.length;
      }
    }
    // If we found a valid match for this locale return it
    if (bestmatch) return bestmatch;
  }
  return null;
};

export const getLocales = (str: string, defaultLocale: string) => {
  return (
    str
      ?.split(",")
      .map((type) => type.split(";")[0].trim().replace("*", defaultLocale)) ??
    ""
  );
};

export const handleLanguageDetection = (gssp: GetServerSideProps) => {
  return async (context: GetServerSidePropsContext) => {
    const {
      resolvedUrl,
      req,
      res,
      locale = "en",
      defaultLocale,
      locales = [],
    } = context;
    const cookies = new Cookies(req, res);

    const languageFromCookies = cookies.get("language");

    if (!languageFromCookies) {
      const closestLocale = findClosestLocale(locales, [
        ...new Set([
          ...getLocales(req.headers["accept-language"] as string, locale),
          locale,
          defaultLocale,
        ]),
      ] as string[]) as string;

      if (closestLocale !== defaultLocale && closestLocale !== locale) {
        cookies.set("language", closestLocale);

        return {
          redirect: {
            permanent: false,
            destination: `/${closestLocale}${resolvedUrl}`,
          },
        };
      }
    }

    // referer => url user refer from
    const url = context?.req?.headers?.referer;

    // if not exist it means that he/she clicked on outside sphere
    if (!url && languageFromCookies && languageFromCookies !== locale) {
      return {
        redirect: {
          permanent: false,
          destination: `/${languageFromCookies}${resolvedUrl}`,
        },
      };
    }

    cookies.set("language", locale);
    return gssp(context); // Pass `context` and continue on to call `getServerSideProps`
  };
};

export const redirectToWelcome = {
  redirect: {
    permanent: false,
    // back to talents
    destination: "/welcome",
  },
};

export const redirectTo404 = {
  redirect: {
    permanent: false,
    // back to talents
    destination: "/404",
  },
};

const LOCALES = { en: enGB, it, de, es };

export const getLocale = (locale: string) => {
  if (!locale) {
    return enGB;
  }
  return (
    Object.entries(LOCALES)?.find((l) => l[0].includes(locale))?.[1] || enGB
  );
};

const PAYPAL_LOCALES: { [key: string]: string } = { en: "en_GB", it: "it_IT" };

export const getPaypalLocale = (locale: string) => {
  if (!locale) {
    return PAYPAL_LOCALES.en;
  }
  return PAYPAL_LOCALES?.[locale];
};

export const getFormatDateByLocale = (locale: string) => {
  if (locale === "it") {
    return "dd MMMM, yyyy";
  }
  return "MMMM dd, yyyy";
};
