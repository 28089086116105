import type { FC } from "react";
import { Flex } from "@talent-garden/react-components";
import { Image } from "../../..";
import styles from "./chatPlaceholder.module.scss";

export const ChatPlaceholder: FC = () => {
  return (
    <Flex
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.container}
    >
      <Flex className={styles.imgWrapper}>
        <Image
          src="/images/no-chat-placeholder.png"
          alt="no chat placeholder"
          fill
        />
      </Flex>
    </Flex>
  );
};
