import { EmptyState } from "@/components/common/emptyState";
import { usePaymentMethods } from "@/hooks/usePaymentMethods";
import type { TPaymentCard } from "@/types/types";
import {
  Alert,
  Button,
  Flex,
  Heading,
  Separator,
  SkeletonLoader,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo, useState, type FC } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { EditCardDetails } from "./editCardDetails";
import { AddPaymentMethod } from "./editCardDetails/addPaymentMethod";
import styles from "./paymentMethods.module.css";

export const PaymentMethods: FC<{ goToLearn: (() => void) | undefined }> = ({
  goToLearn,
}) => {
  const { t } = useTranslation("payments");
  const { paymentsMethods, isLoading } = usePaymentMethods();
  const stripePromise = loadStripe(
    process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string,
  );
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const onAlertOpenChange = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );

  const onAlertErrorOpenChange = useCallback(
    (open: boolean) => setErrorAlertOpen(open),
    [],
  );

  const { defaultPayment, otherPayments } = useMemo(() => {
    return {
      defaultPayment:
        paymentsMethods?.length > 1
          ? paymentsMethods?.filter((pm: TPaymentCard) => pm?.default)
          : paymentsMethods,
      otherPayments:
        paymentsMethods?.length > 1
          ? paymentsMethods?.filter((pm: TPaymentCard) => !pm?.default)
          : null,
    };
  }, [paymentsMethods]);

  return (
    <div>
      <Elements stripe={stripePromise}>
        <Flex>
          <Heading variant="s" weight="bold">
            {t("payment-methods")}
          </Heading>
          {isLoading ? (
            <SkeletonLoader
              height={320}
              width="100%"
              radius={12}
              className={styles.container}
            />
          ) : null}
          {!isLoading ? (
            paymentsMethods?.length ? (
              <Flex
                className={styles.container}
                rowGap={24}
                columnGap={24}
                horizontalAlign="start"
              >
                <>
                  {defaultPayment?.length ? (
                    <>
                      <Text variant="m" weight="medium">
                        {t("default-method")}
                      </Text>
                      {defaultPayment?.map((card: TPaymentCard) => {
                        return (
                          <EditCardDetails
                            card={card}
                            isEditEnabled={paymentsMethods?.length > 1}
                            key={card.id}
                            showSuccessAlert={() => setSuccessAlertOpen(true)}
                            showErrorAlert={() => setErrorAlertOpen(true)}
                          />
                        );
                      })}
                      <Separator />
                    </>
                  ) : null}
                  {otherPayments?.length ? (
                    <>
                      <Text variant="m" weight="medium">
                        {t("other-payment-methods")}
                      </Text>
                      {otherPayments?.map((card: TPaymentCard) => {
                        return (
                          <EditCardDetails
                            card={card}
                            isEditEnabled={paymentsMethods?.length > 1}
                            key={card.id}
                            showSuccessAlert={() => setSuccessAlertOpen(true)}
                            showErrorAlert={() => setErrorAlertOpen(true)}
                          />
                        );
                      })}
                    </>
                  ) : null}
                </>
                <AddPaymentMethod
                  activator={
                    <Button
                      kind="link"
                      capitalize={false}
                      dimension="small"
                      icon="add_card"
                      fontWeight="semibold"
                    >
                      {t("payments:add-new-payment-method")}
                    </Button>
                  }
                />
              </Flex>
            ) : (
              <EmptyState
                action={goToLearn}
                title={t("cards-empty-state-title")}
                description={t("cards-empty-state-subtitle")}
                icon="credit_card"
                buttonComponent={
                  <AddPaymentMethod
                    activator={
                      <Button
                        kind="link-neutral"
                        capitalize={false}
                        dimension="small"
                        icon="add_card"
                        fontWeight="semibold"
                        iconRight
                      >
                        {t("payments:add-new-payment-method")}
                      </Button>
                    }
                  />
                }
                className={styles.emptyState}
              />
            )
          ) : null}
        </Flex>
        <Alert
          title="Update completed"
          description="Your payment methods have been successfully updated."
          isOpen={successAlertOpen}
          handleOpenChange={onAlertOpenChange}
          icon="check_circle"
          withDismiss
          type="success"
          duration={4000}
          iconVariant="outlined"
        />
        {/* Error */}
        <Alert
          title="An error occurred"
          description="Please try again later or contact our support for assistance. We apologize for the inconvenience."
          isOpen={errorAlertOpen}
          handleOpenChange={onAlertErrorOpenChange}
          icon="error"
          withDismiss
          type="error"
          duration={4000}
          iconVariant="outlined"
        />
      </Elements>
    </div>
  );
};
