import { usePaymentMethods } from "@/hooks/usePaymentMethods";
import type { TPaymentCard } from "@/types/types";
import {
  Alert,
  Button,
  Dialog,
  Flex,
  Heading,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC, ReactElement } from "react";
import { useCallback, useState } from "react";
import style from "./deleteCardDialog.module.scss";

const DeleteCardDialog: FC<{
  card: TPaymentCard;
  isEditEnabled?: boolean;
  callback?: () => void;
  activator?: ReactElement;
}> = ({ card, isEditEnabled, callback, activator }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation("payments");
  const { deletePaymentMethod } = usePaymentMethods();

  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const onSuccessOpenAlert = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );
  const onErrorOpenAlert = useCallback(
    (open: boolean) => setErrorAlertOpen(open),
    [],
  );

  const submitRequest = useCallback(async () => {
    try {
      await deletePaymentMethod(card?.id);
      setSuccessAlertOpen(true);
    } catch (err: any) {
      // missing error toast
      setErrorAlertOpen(true);
    } finally {
      setModalOpen(false);
      setTimeout(() => {
        callback?.();
      }, 20);
    }
  }, [card?.id]);

  return (
    <div>
      <Dialog
        activator={
          activator ?? (
            <Button
              capitalize={false}
              dimension="small"
              icon="delete"
              kind="link-neutral"
              fontWeight="medium"
              className={style.deleteBtn}
              disabled={!isEditEnabled}
            >
              {t("delete-card")}
            </Button>
          )
        }
        className={style.dialog}
        onOpenChange={(open: boolean) => setModalOpen(open)}
        title={
          <Flex className={style.iconTitle} data-danger="true">
            <MaterialIcon name="delete" variant="outlined" size={24} />
          </Flex>
        }
        open={modalOpen}
        withSeparator={false}
        data-dimension="small"
        overlayZIndex={10}
      >
        <Flex className={style.content}>
          <Heading variant="s" as="h3" weight="bold">
            {t("delete-card-title")}
          </Heading>
          <Text variant="m" className={style.text}>
            {t("delete-card-description", { card: card?.formattedText })}
          </Text>
          <Button onClick={() => submitRequest()} className={style.button}>
            {t("delete-card")}
          </Button>
        </Flex>
      </Dialog>
      <Alert
        title={t("payment-method-deleted-title")}
        description={t("payment-method-deleted-subtitle")}
        isOpen={successAlertOpen}
        handleOpenChange={onSuccessOpenAlert}
        icon="check_circle"
        withDismiss
        type="success"
        duration={4000}
        iconVariant="outlined"
      />
      <Alert
        title={t("payment-method-deleted-error-title")}
        description={t("payment-method-deleted-error-subtitle")}
        isOpen={errorAlertOpen}
        handleOpenChange={onErrorOpenAlert}
        icon="error"
        withDismiss
        type="error"
        duration={4000}
        iconVariant="outlined"
      />
    </div>
  );
};

export default DeleteCardDialog;
