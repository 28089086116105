export const CognitoConfig = {
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.COGNITO_USER_POOL_ID!,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.COGNITO_CLIENT_ID!,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      // // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      // allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: "code",
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: process.env.COGNITO_CUSTOM_URL,
          scopes: [
            // 'phone',
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [
            "http://localhost:3000/authorize/callback",
            "https://sphere.talentgarden.dev/authorize/callback",
            "https://sphere.talentgarden.com/authorize/callback",
          ],
          redirectSignOut: [
            "http://localhost:3000/authorize/callback",
            "https://sphere.talentgarden.dev/authorize/callback",
            "https://sphere.talentgarden.com/authorize/callback",
          ],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
};
