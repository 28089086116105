import { UserContext } from "@/context/user";
import { useBillingProfile } from "@/hooks/useBillingProfile";
import { denormalizeBillingProfile } from "@/lib/normalizer";
import type { BillingProfileSchema } from "@/lib/schemas/billing-profile";
import { billingProfileSchema } from "@/lib/schemas/billing-profile";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Dialog,
  Flex,
  RadioGroup,
  RadioItem,
  Separator,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC, ReactNode } from "react";
import { useContext, useEffect, useState } from "react";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CompanyForm, CustomerForm, IndividualForm } from "../forms";

import { DialogFooter, DialogHeader, Spinner } from "../..";
import styles from "./billingFormDialog.module.scss";

export const dirtyValues = (newObj: any, oldObj: any) => {
  return Object.keys(newObj).reduce((diff, key) => {
    if (newObj[key] === oldObj[key]) return diff;
    return {
      ...diff,
      [key]: newObj[key],
    };
  }, {});
};

const BillingFormDialog: FC<{ activator: ReactNode }> = ({ activator }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation(["buy", "settings"]);
  const { ownerProfile, user } = useContext(UserContext);
  const { billingProfile, editBillingProfile } = useBillingProfile();
  const [submitting, setSubmitting] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  // const { editSubscription } = useSubscription();
  // const { products } = useStripeProducts();
  // const { user } = useContext(UserContext);

  const initialState: BillingProfileSchema = {
    isIndividual:
      typeof billingProfile?.is_individual === "boolean"
        ? billingProfile?.is_individual
        : true,
    customer: {
      firstName: billingProfile?.first_name || ownerProfile?.first_name,
      lastName: billingProfile?.last_name || ownerProfile?.last_name,
      country: billingProfile?.country || "",
      formattedAddress: billingProfile?.address
        ? `${billingProfile?.address}, ${billingProfile?.city}, ${billingProfile?.postal_code}, ${billingProfile?.country}`
        : "",
      fiscalCode: billingProfile?.fiscal_code,
      billingEmail: billingProfile?.billing_email || user?.email,
      phoneNumber: billingProfile?.phone_number || user?.phone?.number,
      phonePrefix: billingProfile?.phone_prefix || user?.phone?.prefix,
      companyName: billingProfile?.company_name,
      pecEmail: billingProfile?.pec_email,
      vat: billingProfile?.vat,
      sdi: billingProfile?.sdi_code,
    },
  };

  const formMethods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(billingProfileSchema),
    defaultValues: initialState,
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = formMethods;

  // watchers
  const [isIndividual] = watch(["isIndividual"]);

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    const values = dirtyValues(data, billingProfile);
    setSubmitting(true);
    try {
      // PATCH
      const normalizedData = denormalizeBillingProfile(values);

      await editBillingProfile({
        ...normalizedData,
        is_individual: data?.isIndividual,
      });
      setSuccessAlertOpen(true);
      // onNext?.();
    } catch (err) {
      console.error(err);
      setErrorAlertOpen(true);
    } finally {
      setSubmitting(false);
      setModalOpen(false);
    }
  };

  const onError: SubmitErrorHandler<any> = (err, e) => {
    console.error("Errors: ", err, e);
  };

  useEffect(() => {
    const resetVat = isIndividual
      ? {
          customer: {
            ...initialState?.customer,
            vat: "" /* country: undefined */,
          },
        }
      : {
          customer: {
            ...initialState?.customer,
            // vat: "",
            fiscalCode: "",
          },
        };
    reset({
      ...initialState,
      isIndividual,
      ...resetVat,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndividual]);

  useEffect(() => {
    reset({ ...initialState });
  }, [billingProfile, ownerProfile, user, modalOpen]);

  return (
    <div>
      <Dialog
        activator={activator}
        onOpenChange={(open) => setModalOpen(open)}
        withOverlay
        withHeader
        open={modalOpen}
        overlayZIndex={100}
        className={styles.dialog}
        title={
          <DialogHeader
            title={t("settings:billing-profile-dialog-title")}
            subtitle={t("settings:billing-profile-dialog-subtitle") as string}
            icon="library_books"
          />
        }
      >
        <FormProvider {...formMethods}>
          <Flex
            data-form={isIndividual ? "individual" : "company"}
            rowGap={36}
            columnGap={36}
            className={styles.form}
          >
            <Controller
              control={control}
              name="isIndividual"
              render={({ field: { onChange } }) => (
                <RadioGroup
                  value={isIndividual ? "individual" : "company"}
                  onValueChange={(value) => onChange(value === "individual")}
                >
                  <RadioItem
                    value="individual"
                    label={t("buy:billing-profile-individual")}
                    id="individual"
                    fill
                  />
                  <RadioItem
                    value="company"
                    label={t("buy:billing-profile-company")}
                    id="company"
                    fill
                  />
                </RadioGroup>
              )}
            />
            <Separator />
            <Flex rowGap={16} columnGap={16}>
              {isIndividual ? <IndividualForm /> : <CompanyForm />}
              <CustomerForm
                defaultValues={{
                  phoneNumber: initialState?.customer?.phoneNumber,
                  phonePrefix: initialState?.customer?.phonePrefix,
                }}
              />
            </Flex>
            <DialogFooter
              submitText={submitting ? <Spinner /> : t("settings:save-changes")}
              onSubmit={() => handleSubmit(onSubmit, onError)()}
              onCancel={() => {
                setModalOpen(false);
              }}
              cancelText={t("settings:cancel")}
              submitDisabled={submitting || !!Object.keys(errors)?.length}
            />
          </Flex>
        </FormProvider>
      </Dialog>
      <Alert
        isOpen={successAlertOpen}
        title={t("settings:billling-profile-edit-success-title")}
        description={t("settings:billling-profile-edit-success-subtitle")}
        duration={3000}
        handleOpenChange={(value: boolean) => {
          setSuccessAlertOpen(value);
        }}
        icon="check_circle"
        type="success"
      />

      <Alert
        isOpen={errorAlertOpen}
        title={t("settings:billling-profile-edit-error-title")}
        description={t("settings:billling-profile-edit-error-subtitle")}
        duration={3000}
        handleOpenChange={(value: boolean) => {
          setErrorAlertOpen(value);
        }}
        icon="report"
        type="error"
      />
    </div>
  );
};

export default BillingFormDialog;
