import { SidebarContext } from "@/context/sidebar";
import { UserContext } from "@/context/user";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import { useSession } from "@/hooks/useSession";
import {
  Avatar,
  Button,
  ClampText,
  Dropdown,
  DropdownItem,
  Flex,
  Heading,
  Separator,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import type { FC, ReactNode } from "react";
import { useCallback, useContext, useMemo } from "react";
import { CustomLink } from "../common";
import styles from "./header.module.scss";

interface HeaderProps {
  headerTitle: string | ReactNode;
}

export const Header: FC<HeaderProps> = ({ headerTitle }) => {
  const { t } = useTranslation(["common"]);
  const router = useRouter();
  const currentRoute = router.pathname;
  const { user, ownerProfile } = useContext(UserContext);
  const { status, handleSignOut } = useSession();
  const { isTablet, isMobile } = useScreenWidth();
  const { sidebarOpen, setSidebarOpen } = useContext(SidebarContext);
  const { pathname, query, locale } = router;

  const changeLanguage = useCallback(() => {
    const language = locale === "en" ? "it" : "en";
    // router.push({ pathname, query }, router.asPath, { locale: language,  shallow: true, });
    router.replace(
      {
        pathname,
        query,
      },
      router.asPath,
      { locale: language },
    );
  }, [router, locale]);

  const otherLocaleTranslation = useMemo(() => {
    return locale === "en" ? t("italian") : t("english");
  }, [locale]);

  return (
    <Flex
      as="header"
      direction="row"
      horizontalAlign="space-between"
      verticalAlign="center"
      className={styles.header}
      data-open-sidebar={sidebarOpen}
    >
      <Flex
        direction="row"
        verticalAlign="center"
        columnGap={8}
        className={styles.heading}
        data-open-sidebar={sidebarOpen}
      >
        {sidebarOpen ? null : (
          <Button
            className={styles.btn}
            kind="link-neutral"
            icon="menu"
            dimension="large"
            onClick={(): void => setSidebarOpen(!sidebarOpen)}
          />
        )}

        {typeof headerTitle === "string" ? (
          <Heading variant="m" weight="bold">
            {headerTitle}
          </Heading>
        ) : (
          headerTitle
        )}
      </Flex>

      <Flex
        direction="row"
        verticalAlign="center"
        horizontalAlign="center"
        rowGap={12}
        columnGap={12}
      >
        <Link href="/messages">
          <Button
            kind={
              currentRoute.includes("messages")
                ? "primary-light"
                : "secondary-neutral"
            }
            icon="mark_chat_unread"
            iconVariant="outlined"
            capitalize={false}
            className={styles.messagesBtn}
          >
            {!isMobile ? t("messages") : null}
          </Button>
        </Link>

        {isTablet && sidebarOpen ? (
          <Button
            className={styles.btn}
            kind="link-neutral"
            icon="close"
            dimension="large"
            onClick={(): void => setSidebarOpen(!sidebarOpen)}
          />
        ) : (
          <Dropdown
            data-no-padding
            activator={
              <Avatar
                isPressable
                size="extra_small"
                src={ownerProfile?.profile_image_url}
                className={styles.avatar}
              />
            }
            title={
              <Flex className={styles.headerBlock}>
                <ClampText rows={1}>
                  <Text variant="m" weight="semibold">
                    {`${ownerProfile?.first_name} ${ownerProfile?.last_name}`}
                  </Text>
                </ClampText>
                <ClampText rows={1}>
                  <Text
                    as="span"
                    variant="xs"
                    weight="medium"
                    className={styles.email}
                  >
                    {user?.email}
                  </Text>
                </ClampText>
              </Flex>
            }
            footer={
              <Flex
                verticalAlign="center"
                horizontalAlign="center"
                rowGap={4}
                columnGap={4}
                className={styles.footer}
              >
                <Flex
                  direction="row"
                  verticalAlign="center"
                  horizontalAlign="center"
                  rowGap={4}
                  columnGap={4}
                >
                  <ClampText rows={1}>
                    <CustomLink external href={t("term-of-service.link")}>
                      <Text variant="xs" className={styles.link}>
                        {t("term-of-service.label")}
                      </Text>
                    </CustomLink>
                  </ClampText>
                  ·
                  <ClampText rows={1}>
                    <CustomLink external href={t("privacy-policy.link")}>
                      <Text variant="xs" className={styles.link}>
                        {t("privacy-policy.label")}
                      </Text>
                    </CustomLink>
                  </ClampText>
                </Flex>
                <ClampText rows={1}>
                  <CustomLink external href={t("cookie-policy.link")}>
                    <Text variant="xs" className={styles.link}>
                      {t("cookie-policy.label")}
                    </Text>
                  </CustomLink>
                </ClampText>
              </Flex>
            }
            align="end"
            dimension="small"
            className={styles.dropdown}
          >
            <Flex
              rowGap={24}
              columnGap={16}
              className={styles.headerBlock}
              data-with-p-bottom="true"
              data-with-p-top="false"
            >
              <DropdownItem
                iconMode="gray"
                iconName="account_circle"
                iconSize={24}
                iconVariant="outlined"
                uppercase={false}
              >
                <Link passHref href="/profile/me">
                  <Text variant="m" weight="semibold">
                    {t("common:view-profile")}
                  </Text>
                </Link>
              </DropdownItem>
              <DropdownItem
                iconMode="gray"
                iconName="settings"
                iconSize={24}
                iconVariant="outlined"
                uppercase={false}
              >
                <Link passHref href="/settings">
                  <Text variant="m" weight="semibold">
                    {t("common:settings")}
                  </Text>
                </Link>
              </DropdownItem>
              <DropdownItem
                iconMode="gray"
                iconName="translate"
                iconSize={24}
                iconVariant="outlined"
                uppercase={false}
                onClick={changeLanguage}
              >
                <Text variant="m" weight="semibold">
                  {t("language", {
                    lang: otherLocaleTranslation,
                  })}
                </Text>
              </DropdownItem>
            </Flex>

            <Separator />

            <Flex
              rowGap={24}
              columnGap={16}
              className={styles.headerBlock}
              data-with-p-bottom="true"
            >
              <DropdownItem
                iconMode="gray"
                iconName="contact_support"
                iconSize={24}
                iconVariant="outlined"
                uppercase={false}
              >
                <CustomLink
                  external
                  href="mailto:support.sphere@talentgarden.com"
                >
                  <Text variant="m" weight="semibold">
                    {t("ask-for-support")}
                  </Text>
                </CustomLink>
              </DropdownItem>
              <DropdownItem
                iconMode="gray"
                iconName="rate_review"
                iconSize={24}
                iconVariant="outlined"
                uppercase={false}
              >
                <CustomLink external href={t("feedback-url")}>
                  <Text variant="m" weight="semibold">
                    {t("give-feedback")}
                  </Text>
                </CustomLink>
              </DropdownItem>
            </Flex>

            <Separator />

            <Flex rowGap={24} columnGap={16} className={styles.headerBlock}>
              <DropdownItem
                iconMode="gray"
                iconName="logout"
                iconSize={24}
                iconVariant="outlined"
                uppercase={false}
                onClick={() => {
                  if (status !== "authenticated") {
                    return;
                  }

                  handleSignOut({ callbackUrl: "/authorize" });
                }}
              >
                <Text variant="m" weight="semibold">
                  {t("logout")}
                </Text>
              </DropdownItem>
            </Flex>
          </Dropdown>
        )}
      </Flex>
    </Flex>
  );
};
