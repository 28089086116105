// import type { ISession } from "@/lib/auth";
import { fetchClient } from "@/lib/fetchClient";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import type { FC, ReactNode } from "react";
import { createContext, useCallback, useMemo } from "react";
import type { IProfile } from '../types';
import type { IUserById, IUserCommunications, IUserMe } from '@/types/userMe';
import { useSession } from '@/hooks/useSession';


export type UserContextType = {
  user: IUserMe | null;
  editUser: (userData: IUserById | Partial<IUserById>) => void;
  editUserCommunications: (data: IUserCommunications) => void;
  sendOtpForPassword: () => void;
  editUserPassword: (data: { proposed_password: string, code: string }) => void;
  isLoadingUserInfo: boolean;
  isLoadingOwnerUserProfile: boolean,
  ownerProfile: IProfile,
  isOwnerProfileCompleted: boolean,
  ownerProfileCompletePercentage: number,
  editProfile: (data: Partial<IProfile> | IProfile) => Promise<void>
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserContextType>(
  {} as UserContextType,
);

export const UserContextProvider: FC<UserProviderProps> = ({ children }) => {
  const { data: session } = useSession();

  const queryClient = useQueryClient();

  const { data: user, isLoading: isLoadingMe, fetchStatus: meFetchStatus } = useQuery(
    ["user-data"],
    async () => {
      try {
        return await fetchClient("/api/me");
      } catch (error) {
        console.log("Error: ", error);
        return null;
      }
    },
    {
      enabled: Boolean(session?.accessToken),
    },
  );

  const isLoadingUserMe = isLoadingMe && meFetchStatus !== 'idle';

  const {
    data: ownerData,
    isLoading: isLoadingUserProfile,
    fetchStatus: profileFetchStatus
  } = useQuery(
    ["owner-profile"],
    async () => {
      try {
        return axios.get("/api/profile/", {
          params: { userId: user?.data?._id },
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    },
    { enabled: Boolean(user?.data?._id) },
  );

  const isLoadingOwnerUserProfile = isLoadingUserProfile && profileFetchStatus !== 'idle';

  const editUser = useCallback(async (editUserData: IUserById | Partial<IUserById>) => {
    if (!user?.data?._id) {
      return;
    }

    try {
      await axios.patch(`/api/me?userId=${user?.data?._id}`, editUserData);
      queryClient.invalidateQueries(["user-data"], { exact: true });
    } catch (error) {
      throw new Error("Error editing user");
    }
  }, [user?.data]);

  const sendOtpForPassword = useCallback(
    async () => {
      if (!user?.data?._id) {
        return;
      }
      try {
        await axios.get(
          `/api/otp-change-password?user=${user?.data?._id}`
        );
      } catch (error: unknown | AxiosError) {
        if (error instanceof AxiosError && error?.response?.data) {
          throw error?.response?.data;
        }
        throw new Error("Error changing password");
      }
    },
    [user?.data?._id, session],
  );

  const editUserPassword = useCallback(
    async (data: { proposed_password: string, code: string }) => {
      if (!user?.data?._id) {
        return;
      }
      try {
        await axios.patch(
          `/api/confirm-password?user=${user?.data?._id}`,
          data,
        );
      } catch (error) {
        throw new Error("Error changing password");
      }
    },
    [user?.data?._id, session],
  );


  const editUserCommunications = useCallback(
    async (data: IUserCommunications) => {
      if (!user?.data?._id) {
        return;
      }
      try {
        await axios.patch(
          `/api/communications?user=${user?.data?._id}`,
          data,
        );
        queryClient.invalidateQueries(["user-data"], { exact: true });
      } catch (error) {
        throw new Error("Error changing password");
      }
    },
    [user?.data?._id, session],
  );

  const editOwnerProfile = useCallback(
    async (data: Partial<IProfile> | IProfile) => {
      if (!user?.data?._id) {
        throw new Error("Error editing profile, no query userId");
      }
      try {
        await axios.patch(`/api/profile?userId=${user?.data?._id}`, data);
        queryClient.invalidateQueries(["owner-profile"]);
      } catch (error) {
        throw new Error("Error editing profile");
      }
    },
    [user?.data?._id],
  );

  const { isOwnerProfileCompleted, ownerProfileCompletePercentage } = useMemo(() => {
    if (!ownerData?.data) {
      return {
        isOwnerProfileCompleted: true,
        ownerProfileCompletePercentage: 0
      }
    }

    const ownerProfile = ownerData?.data;

    const completeNeededFieldsBooleanValues = [
      !!ownerProfile?.profile_image_url,
      !!ownerProfile?.job_type,
      !!ownerProfile?.languages?.length,
      !!ownerProfile?.bio,
      !!ownerProfile?.skills?.length,
      !!ownerProfile?.needs?.length,
      !!ownerProfile?.interests?.length,
      !!ownerProfile?.availabilities?.length,
    ];

    const completedValuesPercentage = Math.round(
      completeNeededFieldsBooleanValues.filter(
        (v) => v
      ).length / completeNeededFieldsBooleanValues?.length * 100);

    return {
      ownerProfileCompletePercentage: completedValuesPercentage,
      isOwnerProfileCompleted: completedValuesPercentage === 100
    }
  }, [ownerData?.data]);

  const userContextValue = useMemo(() => {
    return {
      user: user?.data as IUserMe,
      editUser,
      editUserCommunications,
      editUserPassword,
      sendOtpForPassword,
      isLoadingUserInfo: isLoadingUserMe,
      isLoadingOwnerUserProfile,
      ownerProfile: ownerData?.data,
      isOwnerProfileCompleted,
      ownerProfileCompletePercentage,
      editProfile: editOwnerProfile
    };
  }, [
    editUser,
    editUserCommunications,
    editUserPassword,
    isLoadingUserMe,
    user?.data,
    isLoadingOwnerUserProfile,
    ownerData?.data,
    isOwnerProfileCompleted,
    ownerProfileCompletePercentage,
    editOwnerProfile,
  ]);

  return (
    <UserContext.Provider value={userContextValue} >
      {children}
    </UserContext.Provider >
  );
};
