import { Channels, Chat } from "@/components";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import { Flex } from "@talent-garden/react-components";
import { useRouter } from "next/router";
import type { FC } from "react";
import clsx from "clsx";
import { ChatPlaceholder } from "../chat/chatPlaceholder";
import style from "./messagesWrapper.module.scss";

export const MessagesWrapper: FC = () => {
  const { query } = useRouter();
  const { channelId } = query;
  const { isTablet } = useScreenWidth();

  return (
    <Flex direction="column" className={clsx(style.container, "ph-no-capture")}>
      {channelId && isTablet ? null : <Channels />}
      {channelId ? <Chat /> : <ChatPlaceholder />}
    </Flex>
  );
};
