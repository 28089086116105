import { AnalyticsContext } from "@/context/analytics";
import { useContext } from "react";

export const useAnalytics = () => {
    const { captureInteraction, setPageView } = useContext(AnalyticsContext);

    return {
        captureInteraction,
        setPageView,
    };
};
