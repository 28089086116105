import {
  Alert,
  Button,
  Flex,
  MaterialIcon,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import React, { useCallback, useState } from "react";
import axios from "axios";
import { tagApiRoutes } from "@/lib/tagApiRoutes";
import type { TWorkspace } from "@/types/types";
import { useQueryClient } from "@tanstack/react-query";
import { Image } from "@/components/common";
import { useSession } from "@/hooks/useSession";
import style from "./workspacePicture.module.scss";

export const WorkspacePicture: FC<{
  accountStyles: any;
  workspace: TWorkspace;
  editWorkspace: (data: any) => void;
}> = ({ accountStyles, workspace, editWorkspace }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("settings");
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  // const [cropped, setCropped] = useState<string | null>(null);
  const { data: session } = useSession();

  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const onOpenChange = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );
  const onErrorOpenChange = useCallback(
    (open: boolean) => setErrorAlertOpen(open),
    [],
  );

  const handleSelectImg = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files[0]) {
      const i = event.target.files[0];
      setSelectedImg(URL.createObjectURL(i));
    }
  };

  const getImage = ({ image }: { image: any }) => {
    return new Promise((resolve) => {
      try {
        if (image) {
          const canvas = document.createElement("canvas");
          let sx = 0;
          let sy = 0;

          let { naturalWidth, naturalHeight, width, height } = image;

          const crop = {
            width: naturalWidth,
            height: naturalHeight,
          };

          if (naturalWidth > naturalHeight) {
            sx = (naturalWidth - naturalHeight) / 2;
            crop.width = naturalHeight;
          } else if (naturalHeight > naturalWidth) {
            sy = (naturalHeight - naturalWidth) / 2;
            crop.height = naturalWidth;
          }

          if (naturalWidth < 1800 && naturalWidth < naturalHeight) {
            naturalHeight = naturalWidth;
            height = width;
          } else if (naturalHeight < 1800 && naturalHeight < naturalWidth) {
            naturalWidth = naturalHeight;
            width = height;
          }

          canvas.width = naturalWidth > 1800 ? 1800 : naturalWidth;
          canvas.height = naturalHeight > 1800 ? 1800 : naturalHeight;

          const ctx = canvas.getContext("2d");

          if (!ctx) {
            return;
          }

          ctx.drawImage(
            image /*  as CanvasImageSource */,
            sx as number,
            sy as number,
            crop.width,
            crop.height,
            0,
            0,
            canvas.width,
            canvas.height,
          );

          canvas.toBlob(resolve, "image/jpeg", 1);
        }
      } catch (e) {
        console.log("Error crop the image: ", e);
      }
    });
  };

  const postImage = useCallback(
    async (image: HTMLImageElement) => {
      const photo = (await getImage({ image })) as Blob;
      // setCropped(URL.createObjectURL(photo));

      const formData = new FormData();
      formData.append("file", photo, "image.jpg");

      try {
        const res = await axios.patch(
          `${process.env.TAG_API_BASE_URL}${tagApiRoutes.workspace(
            String(workspace?._id),
          )}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${session?.accessToken}`,
              "Content-Type": "multipart/form-data;",
            },
          },
        );

        if (res.status === 200) {
          queryClient.invalidateQueries(["workspace", String(workspace?._id)], {
            exact: true,
          });
        }
        setSuccessAlertOpen(true);
      } catch (err) {
        console.log("Error: ", err);
        setErrorAlertOpen(true);
      } finally {
        // finalCallback();
      }
    },
    [queryClient, session, workspace?._id],
  );

  const deleteImage = useCallback(async () => {
    editWorkspace({ image_url: "" });
  }, []);

  return (
    <div>
      <Flex rowGap={16} columnGap={16} className={style.container}>
        <Text variant="m" weight="medium" className={accountStyles.title}>
          {t("workspace-image")}
        </Text>
        <Flex direction="row" rowGap={8} columnGap={8} verticalAlign="center">
          <Flex
            className={style.image}
            verticalAlign="center"
            horizontalAlign="center"
          >
            {workspace?.image_url ? (
              <Image src={workspace?.image_url} alt="" fill />
            ) : null}
            <MaterialIcon
              name="workspaces"
              variant="outlined"
              size={32}
              className={style.icon}
            />
          </Flex>
          <Flex
            rowGap={8}
            columnGap={8}
            className={style.upload}
            horizontalAlign="start"
          >
            <input
              type="file"
              id="actual-btn"
              hidden
              accept="image/png, image/jpeg, image/jpg, image/gif"
              onChange={handleSelectImg}
            />
            {selectedImg ? (
              <img
                hidden
                src={selectedImg as string}
                className={style.hiddenImage}
                onLoad={({ target: img }) => postImage(img as HTMLImageElement)}
                alt=""
              />
            ) : null}
            {/* {cropped ? <img
                    hidden
                    src={cropped as string}
                    className={style.hiddenImage}
                    alt=""
                /> : null} */}
            <Button
              capitalize={false}
              dimension="regular"
              icon="upload"
              kind="secondary-neutral"
              htmlFor="actual-btn"
              as="label"
            >
              Upload photo
            </Button>
            <Button
              capitalize={false}
              icon="delete"
              iconVariant="outlined"
              kind="link-neutral"
              onClick={deleteImage}
              className={style.deleteButton}
              dimension="small"
              disabled={!workspace?.image_url}
            >
              Delete
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Alert
        title={t("alert-workspace-picture-success-title")}
        description={t("alert-workspace-picture-success-subtitle")}
        isOpen={successAlertOpen}
        handleOpenChange={onOpenChange}
        icon="check_circle"
        withDismiss
        type="success"
        duration={4000}
        iconVariant="outlined"
      />
      {/* Error */}
      <Alert
        title={t("alert-workspace-picture-error-title")}
        description={t("alert-workspace-picture-error-subtitle")}
        isOpen={errorAlertOpen}
        handleOpenChange={onErrorOpenChange}
        icon="error"
        withDismiss
        type="error"
        duration={4000}
        iconVariant="outlined"
      />
    </div>
  );
};
