import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useActualWorkspace } from './useActualWorkspace'

export const useBillingProfile = () => {
    const queryClient = useQueryClient();
    const { workspaceId } = useActualWorkspace();

    const { data: billingData, isLoading: isLoadingBillingProfile, error: billingProfileError, fetchStatus } = useQuery(['billingProfile', workspaceId], async () => {
        return axios.get('/api/payments/billing-profile/',
            {
                params: { workspaceId }
            })
    }, { enabled: Boolean(workspaceId) })
    // TODO: serve isLoadingBillingProfile veritiero in useAnalytics per capire se si può creare il setObject
    // N.B. useQuery return always isLoading with true when enabled is false, so we need to crosscheck fetchStatus
    const isLoading = isLoadingBillingProfile && fetchStatus !== 'idle'; // TODO: update ALL queries with this pattern https://github.com/TanStack/query/issues/3975

    const editBillingProfile = useCallback(async (data: any) => {
        if (!workspaceId) {
            return;
        }
        try {
            await axios.patch(`/api/payments/billing-profile?workspaceId=${workspaceId}`, data)
            queryClient.invalidateQueries(['billingProfile', workspaceId], { exact: true });
        } catch (error) {
            console.error('Error: ', error);
            throw new Error(`Error PATCH billing profile: ${error}`);
        }
    }, [workspaceId])

    return {
        isLoading,
        error: billingProfileError,
        billingProfile: billingData?.data,
        editBillingProfile,
    }
}
