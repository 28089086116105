import { useWindowSize } from 'usehooks-ts';

const MOBILE_PIXEL_WIDTH = 768;
const TABLET_PIXEL_WIDTH = 960;

export const useDimensionsHandler = () => {
    const { width } = useWindowSize();

    return {
        isMobile: width < MOBILE_PIXEL_WIDTH,
        isTablet: width < TABLET_PIXEL_WIDTH,
    }
}
