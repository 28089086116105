import { Image } from "@/components/common";
import { goBack, handlePrice } from "@/lib/utils";
import type { TSubscription, TWorkspace } from "@/types/types";
import {
  Button,
  Flex,
  Heading,
  MaterialIcon,
  Separator,
  Text,
} from "@talent-garden/react-components";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import type { FC } from "react";
import styles from "../planTab.module.css";

const PaidMembership: FC<{
  membership: any;
  totalAmount: number;
  items: any[];
  workspace: TWorkspace;
  subscription: TSubscription;
}> = ({ membership, totalAmount, items, workspace, subscription }) => {
  const { asPath } = useRouter();
  const { t } = useTranslation("settings");

  const notMembershipItems = items?.filter(
    (item) => item?.type !== "membership",
  );

  return (
    <Flex
      rowGap={24}
      columnGap={24}
      direction="column"
      className={styles.container}
    >
      <Flex
        verticalAlign="space-between"
        horizontalAlign="start"
        rowGap={24}
        columnGap={24}
        className={styles.freePlan}
      >
        <Flex rowGap={4} columnGap={4}>
          <Text variant="m" weight="bold">
            Plan
          </Text>
          <Heading variant="demi" weight="bold">
            {membership?.name}
          </Heading>
        </Flex>
        <Flex rowGap={4} columnGap={4}>
          <Text variant="m" weight="bold">
            Description
          </Text>
          <Text variant="m" weight="regular">
            The membership of Real Taggers. You have access to all the common
            spaces within our campuses and the possibility of always meeting new
            people thanks to the features of our Curated Network and obtain a
            lot of deals on services and apps.
          </Text>
        </Flex>
        <Flex rowGap={4} columnGap={4}>
          <Flex direction="row" rowGap={8} columnGap={8} verticalAlign="center">
            <MaterialIcon name="today" size={18} />
            <Text variant="m" weight="regular">
              {t("renewal-on-date", {
                date: format(
                  new Date(subscription?.current_period_start),
                  "dd MMM, yyyy" /* {
                  locale: getLocale(),
                } */,
                ),
              })}
            </Text>
          </Flex>

          <Flex direction="row" rowGap={8} columnGap={8} verticalAlign="center">
            <MaterialIcon name="event_repeat" size={18} />
            <Text variant="m" weight="regular">
              {t("renewal-on-date", {
                date: format(
                  new Date(subscription?.current_period_end),
                  "dd MMM, yyyy" /* {
                  locale: getLocale(),
                } */,
                ),
              })}
            </Text>
          </Flex>
        </Flex>
        <Separator />

        <Button
          dimension="large"
          kind="link-neutral"
          capitalize={false}
          icon="sentiment_dissatisfied"
          onClick={() => {}}
          className={styles.downgradeButton}
        >
          Downgrade to Free
        </Button>
      </Flex>
      <Flex
        className={styles.plan}
        rowGap={24}
        columnGap={24}
        verticalAlign="space-between"
        horizontalAlign="start"
      >
        <Flex rowGap={4} columnGap={4}>
          <Flex
            direction="row"
            horizontalAlign="space-between"
            verticalAlign="space-between"
          >
            <Image
              src="/images/free-plan.svg"
              alt="Free plan image"
              width={115}
              height={92}
              className={styles.image}
            />
            <Flex
              direction="row"
              rowGap={4}
              columnGap={4}
              verticalAlign="center"
            >
              <MaterialIcon name="person" mode="primary" size={24} />
              <Text variant="m" weight="semibold">
                {/* TODO translation con plurals */}
                {`${membership?.quantity} members`}
              </Text>
            </Flex>
          </Flex>
          <Text variant="m" weight="bold">
            UPGRADE TO
          </Text>
          <Heading variant="demi" weight="bold">
            {`${workspace?.name}'s workspace`}
          </Heading>
        </Flex>
        <Flex rowGap={8} columnGap={8}>
          <Text variant="m" weight="bold">
            Features
          </Text>

          {notMembershipItems?.length ? (
            <>
              <Text variant="m" weight="bold">
                Features
              </Text>
              {notMembershipItems?.map((item) => {
                return (
                  <Text variant="m" weight="bold" key={item?.id}>
                    {item?.name}
                  </Text>
                );
              })}
            </>
          ) : (
            <Text variant="l" className={styles.priceLabel}>
              No additional features added.
            </Text>
          )}
        </Flex>

        <Flex rowGap={8} columnGap={8}>
          <Text variant="m" weight="bold">
            Subscription
          </Text>
          <Flex direction="row" verticalAlign="end" rowGap={8} columnGap={8}>
            <Heading variant="demi" weight="bold">
              {`€ ${handlePrice(totalAmount)}`}
            </Heading>
            <Text variant="l" className={styles.priceLabel}>
              monthly
            </Text>
          </Flex>
          <Link
            href={`${goBack(asPath, 1)}/plan/upgrade`}
            className={styles.link}
          >
            <Button kind="primary" dimension="large" fullWidth>
              Upgrade plan
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PaidMembership;
