import { getCookie, setCookie } from 'cookies-next';
import type { FC, ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from './user';


export type WorkspaceContextType = {
  workspaceId: string | null
};

interface WorkspaceProviderProps {
  children: ReactNode;
}

export const WorkspaceContext = createContext<WorkspaceContextType>(
  {} as WorkspaceContextType,
);

export const WorkspaceContextProvider: FC<WorkspaceProviderProps> = ({ children }) => {
  const { user } = useContext(UserContext);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>("");

  // //  const selectedWorkspaceId = useMemo(() => {
  // //    if (!user?.workspaces) {
  // //      return null;
  // //    }

  // //    let workspaceIdFromCookie = getCookie("selectedWorkspaceId");

  // //    if (!workspaceIdFromCookie || !user?.workspaces?.map(w => w.workspace_id).includes(workspaceIdFromCookie!)) {
  // //      const newSelectedWorkspace = user?.workspaces?.[0];

  // //      setCookie("selectedWorkspaceId", newSelectedWorkspace?.workspace_id)
  // //      workspaceIdFromCookie = newSelectedWorkspace?.workspace_id
  // //    }

  // //    return workspaceIdFromCookie;
  // //  }, [user?.workspaces]);

  useEffect(() => {
    if (!user?.workspaces) {
      return;
    }

    let workspaceIdFromCookie = getCookie("selectedWorkspaceId");

    if (!workspaceIdFromCookie || !user?.workspaces?.map(w => w.workspace_id).includes(workspaceIdFromCookie!)) {
      const newSelectedWorkspace = user?.workspaces?.[0];

      setCookie("selectedWorkspaceId", newSelectedWorkspace?.workspace_id)
      workspaceIdFromCookie = newSelectedWorkspace?.workspace_id
    }

    setSelectedWorkspaceId(workspaceIdFromCookie)
  }, [user?.workspaces])

  const setActualWorkspace = useCallback((workspaceId: string) => {
    if (selectedWorkspaceId === workspaceId) {
      return;
    }
    setCookie("selectedWorkspaceId", workspaceId);
    setSelectedWorkspaceId(workspaceId)
  }, [selectedWorkspaceId])

  const workspaceContextValue = useMemo(() => {
    return {
      workspaceId: selectedWorkspaceId!,
      setActualWorkspace
    };
  }, [
    selectedWorkspaceId,
    setActualWorkspace
  ]);

  return (
    <WorkspaceContext.Provider value={workspaceContextValue}>
      {children}
    </WorkspaceContext.Provider>
  );
};
