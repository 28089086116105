import { UserContext } from '@/context/user'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback, useContext, useMemo } from 'react'
import { TWorkspace } from '../types'
import { useActualWorkspace } from './useActualWorkspace'

export const useWorkspace = () => {
    const queryClient = useQueryClient();
    const { workspaceId } = useActualWorkspace();

    const { data: workspace, isLoading: isLoadingWorkspace, error: workspaceError, fetchStatus } = useQuery(['workspace', workspaceId], async () => {
        return axios.get('/api/workspace/',
            {
                params: { workspaceId }
            })
    }, { enabled: Boolean(workspaceId) })

    const isLoading = isLoadingWorkspace && fetchStatus !== 'idle'; // TODO: update ALL queries with this pattern https://github.com/TanStack/query/issues/3975

    const editWorkspace = useCallback(async (data: any) => {
        if (!workspaceId) {
            throw new Error("Error editing workspace, no query id");
        }
        try {
            await axios.patch(`/api/workspace?workspaceId=${workspaceId}`, data)
            queryClient.invalidateQueries(['workspace', workspaceId], { exact: true });
        } catch (error) {
            throw ("Error editing workspace")
        }
    }, [workspaceId])

    return {
        isLoading,
        error: workspaceError,
        workspace: workspace?.data as TWorkspace,
        editWorkspace
    }
}
