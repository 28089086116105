import { type FC } from "react";

import { Flex, Heading, Separator } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import styles from "./accountTab.module.scss";
import { ChangeEmail } from "./changeEmail";
import { ChangePassword } from "./changePassword";
import { ChangePhone } from "./changePhone";
import { Notifications } from "./notifications";

const AccountTab: FC = () => {
  const { t } = useTranslation("settings");

  return (
    <Flex className={styles.container} rowGap={40} columnGap={40}>
      <Flex rowGap={12} columnGap={12}>
        <Heading variant="s" weight="bold">
          {t("account-information")}
        </Heading>
        <Flex rowGap={20} columnGap={20} className={styles.item}>
          <ChangeEmail />
          <Separator />
          <ChangePassword />
          <Separator />
          <ChangePhone />
        </Flex>
      </Flex>
      <Notifications />
      {/* <DeleteAccount className={styles.item} /> */}
    </Flex>
  );
};

export default AccountTab;
